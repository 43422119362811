import React, { useCallback, useEffect, createRef } from 'react'
import PropTypes from 'prop-types'
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react'
import { TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { translate } from '_helpers/translate'

const INITIAL_ZOOM = 13
const INITIAL_POSITION = {
  LAT: 50.0520941,
  LNG: 19.929464,
}

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: 40,
  },
  label: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    marginBottom: 10,
  },
  hint: {
    marginTop: 8,
  },
  map: {
    marginTop: 16,
    position: 'relative',
    height: 500,
  },
}))

const MapContainer = ({
  name,
  label,
  hint = null,
  initialValue,
  value,
  compareValue = null,
  compare = false,
  disabled = false,
  validators,
  setValue,
  google,
}) => {
  const textFieldRef = createRef()

  const handleAutocomplete = useCallback(
    autocomplete => () => {
      const place = autocomplete.getPlace()

      setValue(name, {
        zoom: INITIAL_ZOOM,
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      })
    },
    [name, setValue]
  )

  useEffect(() => {
    if (!textFieldRef.current) {
      return
    }

    const autocomplete = new google.maps.places.Autocomplete(
      textFieldRef.current.querySelector('input')
    )

    autocomplete.addListener('place_changed', handleAutocomplete(autocomplete))
  }, [google.maps.places.Autocomplete, handleAutocomplete, textFieldRef])

  const handleChange = e => {
    const address = e.target.value
    const geocoder = new google.maps.Geocoder()

    geocoder.geocode({ address }, (results, status) => {
      if (status !== google.maps.GeocoderStatus.OK) {
        return
      }

      setValue(name, {
        zoom: INITIAL_ZOOM,
        lat: results[0].geometry.location.lat(),
        lng: results[0].geometry.location.lng(),
      })
    })
  }

  const zoom = value?.zoom || INITIAL_ZOOM
  const lat = value?.lat || INITIAL_POSITION.LAT
  const lng = value?.lng || INITIAL_POSITION.LNG

  const handleClick = (props, map, coord) => {
    const { latLng } = coord

    setValue(name, {
      zoom: INITIAL_ZOOM,
      lat: latLng.lat(),
      lng: latLng.lng(),
    })
  }

  useEffect(() => {
    if (!initialValue && validators?.includes('required')) {
      setValue(name, {
        zoom: INITIAL_ZOOM,
        lat: INITIAL_POSITION.LAT,
        lng: INITIAL_POSITION.LNG,
      })
    }
  }, [setValue, name, initialValue, validators, value])

  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div
        className={classes.label}
        style={label.color && { color: label.color }}
      >
        {translate(label.text || label)}
      </div>
      <TextField
        type="text"
        onChange={handleChange}
        variant="standard"
        fullWidth={true}
        placeholder={translate('T_GENERAL_MAP_INPUT')}
        className={classes.input}
        disabled={disabled}
        ref={textFieldRef}
      />
      <div className={classes.hint}>{translate(hint)}</div>
      <div className={classes.map}>
        <Map
          onClick={handleClick}
          google={google}
          initialCenter={{ lat, lng }}
          zoom={zoom}
          center={value}
        >
          <Marker position={{ lat, lng }} />
        </Map>
      </div>
      {compare && compareValue && (
        <div className={classes.map}>
          <Map
            google={google}
            initialCenter={{
              lat: compareValue.lat,
              lng: compareValue.lng,
            }}
            zoom={compareValue.zoom}
          >
            <Marker
              position={{
                lat: compareValue.lat,
                lng: compareValue.lng,
              }}
            />
          </Map>
        </div>
      )}
    </div>
  )
}

export const MapType = GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
})(MapContainer)

MapType.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ]).isRequired,
  hint: PropTypes.string,
  initialValue: PropTypes.shape({
    zoom: PropTypes.number,
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  value: PropTypes.shape({
    zoom: PropTypes.number,
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  compareValue: PropTypes.shape({
    zoom: PropTypes.number,
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  compare: PropTypes.bool,
  disabled: PropTypes.bool.isRequired,
  validators: PropTypes.arrayOf(PropTypes.string),
  setValue: PropTypes.func.isRequired,
}
