import { BLOCK_TYPES_IRI } from '_lib/blockTypes'

export const contact = blockContactEndpoint => ({
  name: BLOCK_TYPES_IRI.CONTACT,
  label: 'T_GENERAL_BLOCK_TYPE_CONTACT',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {},
      },
    },
    contacts: {
      type: 'collection',
      label: 'T_GENERAL_BLOCK_TYPE_CONTACT_CONTACTS',
      titleAccessor: `fullName`,
      definitionRef: '#NewsBlockContact',
      endpoint: blockContactEndpoint,
      properties: {
        translations: {
          type: 'translation',
          properties: {
            department: {
              description: 'Department',
              type: 'string',
            },
            position: {
              description: 'Person position',
              type: 'string',
            },
          },
        },
        fullName: {
          description: 'T_GENERAL_FULL_NAME',
        },
        email: {
          description: 'T_FORM_FIELD_EMAIL',
        },
        phone1: {
          description: 'T_GENERAL_PHONE_NUMBER',
        },
        phone2: {
          description: 'T_GENERAL_PHONE_NUMBER',
        },
        address: {
          description: 'T_GENERAL_ADDRESS',
          type: 'textarea',
        },
      },
    },
  },
})
