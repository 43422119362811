import React from 'react'
import { IconButton } from '@material-ui/core'
import { EmojiEvents } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { translate } from '_helpers/translate'
import { LANGS_IRI } from '_lib/langs'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { prop } from '../../../../_helpers/prop'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  listItem: {
    minWidth: 32,
  },
  menuTrigger: {
    borderRadius: 4,
    padding: 7,
  },
  menuTriggerOpen: {
    border: '1px solid #d3d4d5',
    borderBottomColor: '#fff',
    backgroundColor: '#fff',

    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
  sendButton: {},
})

export const winners = (
  accessor = `translations.${
    LANGS_IRI[process.env.REACT_APP_LOCALE.toUpperCase()]
  }.title`,
  header,
  url = null,
  urlTitle = '<Unknown>'
) => ({
  header: translate(header),
  accessor,
  sortable: false,
  filterable: false,
  Cell: ({ resource, accessor }) => {
    const classes = useStyles()

    const submissionsUrl = url.replace(':id', prop(resource, 'uuid'))

    return (
      <div className={classes.root}>
        {url && (
          <>
            {prop(resource, 'winnersCount', 0)}
            <IconButton
              component={Link}
              to={submissionsUrl}
              color="primary"
              className={clsx(classes.sendButton)}
              variant="contained"
            >
              <EmojiEvents />
            </IconButton>
          </>
        )}
      </div>
    )
  },
})
