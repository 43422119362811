const schema = {
  endpoint: '/api/contact_peoples',
  access: 'contactPeople',
  resource: {
    definition: '#ContactPeople',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          department: {},
          position: {},
        },
      },
      fullName: {
        description: 'T_GENERAL_FULL_NAME',
      },
      email: {
        description: 'T_FORM_FIELD_EMAIL',
      },
      phone1: {
        description: 'T_GENERAL_PHONE_NUMBER',
      },
      phone2: {
        description: 'T_GENERAL_PHONE_NUMBER',
      },
      phone3: {
        description: 'T_GENERAL_PHONE_NUMBER',
      },
      highlighted: {},
      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
}

export default schema
