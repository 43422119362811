import React from 'react'
import { Form } from 'pages'
import { translate } from '_helpers/translate'
import { EmbeddedCollection } from 'components/embedded'
import schema from '_schema/survey'
import routes from './routes'

export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  return (
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      customResourceSchema={schema.resource}
      iri={iri}
      method="PUT"
      url={iri}
      collectionPath={routes().index.path}
      storeCollectionId={schema.endpoint}
      sidebar={true}
      width={600}
    >
      <EmbeddedCollection
        endpoint={`${schema.subresources.item.endpoint}?iri[parent]=${iri}`}
        pid={match.params.id}
        parentIri={iri}
        properties={schema.subresources.item.properties}
        definitionSchema={definitions[schema.subresources.item.definition]}
        headerTitle={translate('T_MODULE_SURVEY_ENTRIES_HEADER_TITLE')}
        expandTitle={translate('T_MODULE_SURVEY_ITEMS_EXPAND_TITLE')}
        panelTitle={translate('T_MODULE_SURVEY_ENTRY')}
        titleAccessor={'title'}
        statable={true}
        additionalTitleVarTitle={translate('T_MODULE_SURVEY_ENTRIES_COUNT_VAR')}
        additionalTitleVar={'votesCount'}
      />
    </Form>
  )
}
