import _block from './_block'
import photoSchema from './photo'
import mediaSchema from './media'

const schema = {
  endpoint: '/api/contest_pages',
  clone_endpoint: '/api/contest_pages/clone',
  access: 'contestPage',
  resource: {
    definition: '#ContestPage',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
          description: {
            description: 'Opis',
            label: 'Description',
            type: 'tinymce',
          },
          metaTitle: {},
          metaDescription: {
            type: 'textarea',
          },
          metaKeywords: {
            type: 'textarea',
          },
          alt: {
            description: 'T_GENERAL_ALT',
          },
          slug: {},
        },
      },
      media: {
        type: 'image',
        description: 'T_GENERAL_PHOTO',
        endpoint: photoSchema.endpoint.single,
        validate: ['maxSize'],
        thumbs: {
          endpoint: '/api/contest_page_thumbs',
          property: 'media',
        },
      },
      mediaThumbType: {
        type: 'resource',
        endpoint: `/api/thumb_types?pagination=false`,
        titleAccessor: `trans`,
        transTitle: true,
        label: 'T_GENERAL_MEDIA_PHOTO_TYPE',
        initialValue: { value: '/api/thumb_types/CROPPED', title: 'Kadr' },
        validate: ['required'],
      },
      hideMediaInDetail: {
        description: 'T_GENERAL_MEDIA_HIDE_IN_DETAIL',
      },
      mediaDetail: {
        type: 'image',
        description: 'T_GENERAL_PHOTO_DETAIL',
        endpoint: photoSchema.endpoint.single,
        validate: ['maxSize'],
        thumbs: {
          endpoint: '/api/contest_page_thumbs',
          property: 'mediaDetail',
        },
      },
      linkFacebook: {
        description: 'T_LINKS_FACEBOOK',
      },
      linkYoutube: {
        description: 'T_LINKS_YOUTUBE',
      },
      linkInstagram: {
        description: 'T_LINKS_INSTAGRAM',
      },
      events: {
        type: 'multipleResource',
        endpoint: `/api/events_select?pagination=false`,
        titleAccessor: `nativeTitle`,
        label: 'T_GENERAL_LINKED_EVENTS',
      },
      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
  subresources: {
    block: {
      endpoint: '/api/contest_page_blocks',
      definition: '#ContestPageBlock',
      types: _block(
        mediaSchema.endpoint,
        '/api/contest_page_block_thumbs',
        '/api/contest_page_block_multi_sections',
        '/api/contest_page_block_multi_section_thumbs',
        '/api/contest_page_block_branches',
        '/api/contest_page_block_didascalias',
        '/api/contest_page_block_contacts'
      ).types,
    },
  },
}

export default schema
