import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { Switch, Button } from '@material-ui/core'
import { translate } from '_helpers/translate'

const useStyles = makeStyles(theme => ({
  root: {
    marginLeft: 15,
    marginRight: 15,
    textAlign: 'center',
  },
  container: {
    display: 'inline-block',
    color: theme.palette.text.secondary,
    fontSize: '0.75rem',
    margin: 0,
    marginTop: 3,
    marginLeft: 6,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    lineHeight: 1.66,
    letterSpacing: '0.03333em',
    textAlign: 'left',
  },
  button: {
    minWidth: 35,
    padding: 0,
    paddingLeft: 5,
    paddingRight: 5,
    marginLeft: 4,
  },
}))

export const BooleanFilter = ({
  name,
  title,
  value,
  disabled,
  setFilter,
  handleFilter,
  classes = {},
}) => {
  const handleAllChoice = () => {
    setFilter(name, value !== null ? null : false)
    handleFilter(name, value !== null ? null : false)
  }

  const handleSwitchChange = e => {
    const value = e.target.checked
    setFilter(name, value)
    handleFilter(name, value)
  }

  const defaultClasses = useStyles()

  return (
    <div className={clsx(defaultClasses.root, classes.root)}>
      {title}
      <div className={clsx(defaultClasses.container, classes.container)}>
        {value === null ? (
          <Button
            disabled={disabled}
            onClick={handleAllChoice}
            color={value === null ? 'secondary' : 'default'}
            variant="contained"
            size="small"
            className={clsx(defaultClasses.button, classes.button)}
          >
            {translate('T_GENERAL_ALL')}
          </Button>
        ) : (
          <Switch
            name={`filter-switch-${name}`}
            checked={value === null ? false : value}
            disabled={disabled}
            onChange={handleSwitchChange}
            color="primary"
          />
        )}
      </div>
    </div>
  )
}

BooleanFilter.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.bool,
  disabled: PropTypes.bool,
  setFilter: PropTypes.func.isRequired,
  handleFilter: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    root: PropTypes.string,
    container: PropTypes.string,
    button: PropTypes.string,
  }),
}
