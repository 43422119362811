import { EditableCell } from 'components/table/cells/editable/EditableCell'

export const editable = (
  accessor,
  sortable = true,
  filterable = true,
  inputOnLoad = false
) => ({
  accessor,
  sortable,
  filterable,
  inputOnLoad,
  Cell: EditableCell,
})
