const schema = {
  endpoint: '/api/mobile_app_contact_forms',
  access: 'mobileAppContactForm',
  resource: {
    definition: '#MobileAppContactForm',
    properties: {
      email: {
        description: 'Email (formularz)',
      },
      content: {
        type: 'textarea',
        description: 'Zawartość (formularz)',
      },
      userEmail: {
        description: 'T_GENERAL_EMAIL',
        hint: 'Email użytkownika (SOWA)',
        disabled: true,
      },
      userUuid: {
        description: 'Identyfikator użytkownika (SOWA)',
        disabled: true,
      },
    },
  },
}

export default schema
