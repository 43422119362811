import React, { useState } from 'react'
import { Form } from 'pages'
import schema from '_schema/news'
import routes from './routes'
import { BlockCollection } from '../../components/block'
import { Alert, AlertTitle } from '@material-ui/lab'

export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  const [parentResource, setParentResource] = useState(null)

  const link = `http://${window.location.host.replace(
    'admin.',
    ''
  )}/aktualnosci/podglad/${parentResource?.uuid}/${parentResource?.accessKey}`

  return (
    <div>
      <Alert
        severity="info"
        style={{
          marginTop: '24px',
          marginLeft: '32px',
          marginRight: '32px',
          border: '1px solid #474e59',
        }}
      >
        <AlertTitle>
          <b>Link poglądowy</b>
        </AlertTitle>
        <a href={link}>{link}</a>
      </Alert>
      <Form
        definitionSchema={definitions[schema.resource.definition]}
        customResourceSchema={schema.resource}
        iri={iri}
        method="PUT"
        url={iri}
        collectionPath={routes().index.path}
        storeCollectionId={schema.endpoint}
        sidebar={true}
        width={600}
        setParentResource={setParentResource}
      >
        <BlockCollection
          endpoint={schema.subresources.block.endpoint}
          pid={match.params.id}
          parentIri={iri}
          definitionSchema={definitions[schema.subresources.block.definition]}
          types={schema.subresources.block.types}
          contextualHint={schema.subresources.block.contextualHint}
          statable={true}
          key={iri}
        />
      </Form>
    </div>
  )
}
