import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Switch, Route } from 'react-router-dom'
import { CssBaseline } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Loader } from 'components/Loader'
import { NotFound } from 'pages/NotFound'
import { Bars } from 'components/bar'
import { generateRoutes } from '_helpers/generateRoutes'
import { hasResourceAccess } from '_helpers/hasResourceAccess'
import { isObjectEmpty } from '_helpers/isObjectEmpty'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
  },
  spacer: theme.mixins.toolbar,
}))

export const Page = ({ location, menu, routes }) => {
  const profile = useSelector(state => state.profile)

  const accessableRoutes = useMemo(
    () =>
      !isObjectEmpty(profile)
        ? [
            ...routes(),
            {
              type: Route,
              render: props => <NotFound {...props} />,
              path: '/*',
            },
          ].filter(route => hasResourceAccess(route.resourceAccess, profile))
        : [],
    [profile, routes]
  )

  const accessableMenu = useMemo(
    () =>
      !isObjectEmpty(profile)
        ? menu()
            .map(element =>
              !element.items
                ? element
                : {
                    ...element,
                    items: element.items.filter(item =>
                      hasResourceAccess(item?.resourceAccess, profile)
                    ),
                  }
            )
            .filter(element =>
              !element.items
                ? hasResourceAccess(element?.resourceAccess, profile)
                : element.items.length
            )
        : [],
    [profile, menu]
  )

  const classes = useStyles()

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Bars
        location={location}
        menu={accessableMenu}
        routes={accessableRoutes}
        loading={isObjectEmpty(profile)}
      />
      <main className={classes.content}>
        <div className={classes.spacer} />
        {!isObjectEmpty(profile) ? (
          <Switch>{generateRoutes(accessableRoutes)}</Switch>
        ) : (
          <Loader align="center" marginTop={15} />
        )}
      </main>
    </div>
  )
}

Page.propTypes = {
  location: PropTypes.object.isRequired,
  menu: PropTypes.func.isRequired,
  routes: PropTypes.func.isRequired,
}
