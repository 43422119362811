import React, { useState, useEffect, useMemo } from 'react'
import AccountBalanceOutlined from '@material-ui/icons/AccountBalanceOutlined'
import {
  TextField,
  Chip,
  Grid,
  Typography,
  FormControl,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { debounce } from '@material-ui/core/utils'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'
import PropTypes from 'prop-types'
import { translate } from '_helpers/translate'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import { prop } from '_helpers/prop'

const useStyles = makeStyles({
  autocomplete_root: {
    minWidth: 800,
  },
  input_root: {
    paddingLeft: 10,
    height: 56,
    borderRadius: 4,
    '&:before': {
      content: 'none',
    },
    '&:hover:before': {
      content: 'none',
    },
  },
  input: {
    paddingTop: '3px !important',
    paddingBottom: '3px !important',
  },
})

export const AutoComplete = ({
  endpoint = null,
  titleAccessor = null,
  label,
  name,
  value,
  setValue,
  placeholder = null,
  multiple = false,
  classes = {},
  icon = false,
  arrayTitleAccessor = [],
  additionalSubtitle = false,
}) => {
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState([])

  const fetchResults = useMemo(
    () =>
      debounce((searchTerm, callback) => {
        const controller = new AbortController()
        const { signal } = controller
        fetchDataHandleAuthError(
          `${endpoint}?${titleAccessor}=${searchTerm}&perPage=50`,
          'GET',
          { signal },
          response => {
            const results = response['hydra:member']
            callback(results)
          },
          error => {
            if (error.response.title === 'AbortError') {
              return
            }
            callback([])
            notification('error', error.response.detail, error.response.title)
          }
        )
      }, 400),
    [endpoint, titleAccessor]
  )

  useEffect(() => {
    let active = true

    if (inputValue === '') {
      setOptions(value ? [value] : [])
      return undefined
    }

    fetchResults(inputValue, results => {
      if (active) {
        setOptions(results)
      }
    })

    return () => {
      active = false
    }
  }, [value, inputValue, fetchResults])

  const defaultClasses = useStyles()

  return (
    <FormControl className={classes.root}>
      <Autocomplete
        multiple={multiple}
        id="autocomplete"
        getOptionLabel={option =>
          typeof option === 'string' ? option : option?.[titleAccessor]
        }
        filterOptions={x => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value}
        noOptionsText={translate('No results found')}
        onChange={(event, newValue) => {
          setOptions(newValue ? [newValue, ...options] : options)
          setValue(name, newValue ? newValue : null)
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue)
        }}
        renderInput={params => (
          <TextField
            variant="outlined"
            {...params}
            placeholder={translate(placeholder ? placeholder : 'Search')}
            label={translate(label.text || label)}
            fullWidth
          />
        )}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              key={index}
              variant="outlined"
              label={option?.[titleAccessor]}
              {...getTagProps({ index })}
            />
          ))
        }
        renderOption={option => (
          <Grid container alignItems="center">
            {icon && (
              <Grid item style={{ display: 'flex', width: 44 }}>
                <AccountBalanceOutlined />
              </Grid>
            )}
            <Grid
              item
              style={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}
            >
              <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                {arrayTitleAccessor.length > 0
                  ? `${prop(option, arrayTitleAccessor[0])} ${prop(
                      option,
                      arrayTitleAccessor[1]
                    )}`
                  : prop(option, titleAccessor)}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {additionalSubtitle
                  ? option.affiliations[0]?.nativeTitle
                  : translate(option['@type'])}
              </Typography>
            </Grid>
          </Grid>
        )}
        classes={{
          root: clsx(defaultClasses.autocomplete_root, classes.autocomplete),
          inputRoot: defaultClasses.input_root,
          input: defaultClasses.input,
        }}
      />
    </FormControl>
  )
}

AutoComplete.propTypes = {
  name: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ]),
  icon: PropTypes.bool,
  placeholder: PropTypes.string,
  additionalSubtitle: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      '@id': PropTypes.string.isRequired,
    }),
  ]),
  endpoint: PropTypes.string,
  titleAccessor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  arrayTitleAccesssor: PropTypes.arrayOf(PropTypes.string),
  setValue: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    root: PropTypes.string,
    read_only: PropTypes.string,
    autocomplete: PropTypes.string,
  }),
}
