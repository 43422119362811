import React, { useMemo } from 'react'
import { Paper } from 'components/Paper'
import { Title } from 'components/Title'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { standard } from 'components/table/_columns/standard'
import { DatetimeCell } from 'components/table/cells/DatetimeCell'
import { ResultCell } from './table/cells/ResultCell'
import { translate } from '_helpers/translate'
import schema from '_schema/loginEntry'
import routes from './routes'

export const Collection = () => {
  const columns = useMemo(
    () => [
      {
        ...standard('username'),
        width: '25%',
      },
      {
        ...standard('createdAt'),
        width: '25%',
        header: translate('T_LOGIN_DATE'),
        Cell: DatetimeCell,
      },
      {
        ...standard('ip'),
        width: '20%',
      },
      {
        ...standard('result'),
        width: '23%',
        Cell: ResultCell,
      },
    ],
    []
  )

  return (
    <Paper>
      <Title>{translate(routes().index.title)}</Title>
      <SchemableCollection
        component={CollectionTable}
        definition={schema.resource.definition}
        endpoint={schema.endpoint}
        columns={columns}
        autoWidth={false}
        defaultSorters={{ createdAt: 'desc' }}
        parameters={[
          {
            name: 'username',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'ip',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'result',
            in: 'query',
            required: false,
            type: 'boolean',
          },
          {
            name: 'order[username]',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'order[ip]',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'order[result]',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'order[createdAt]',
            in: 'query',
            required: false,
            type: 'string',
          },
        ]}
      />
    </Paper>
  )
}
