import React from 'react'
import { Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Form } from 'pages'
import { Sidebar } from './sidebar'
import schema from '_schema/admin'
import routes from './routes'
import profileRoutes from 'pages/Profile/routes'

export const Edit = ({ schema: definitions, ...rest }) => {
  const profile = useSelector(state => state.profile)
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  return [profile.uuid, 'profile'].includes(match.params.id) ? (
    <Redirect to={profileRoutes().index.path} />
  ) : (
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      customResourceSchema={schema.resource}
      modifyProperties={properties => ({
        ...properties,
        rawPassword: {
          ...properties.rawPassword,
          validate: properties.rawPassword.validate?.filter(
            validator => validator !== 'required'
          ),
        },
      })}
      iri={iri}
      method="PUT"
      url={iri}
      collectionPath={routes().index.path}
      storeCollectionId={schema.endpoint}
      sidebar={true}
      sidebarTitleAccessor="fullName"
      SidebarComponent={Sidebar}
    />
  )
}
