import React from 'react'
import { Form } from 'pages'
import schema from '_schema/eLibraryBookRequest'
import routes from './routes'
import { EmbeddedCollection } from '../../../components/embedded'
import { translate } from '../../../_helpers/translate'

export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  return (
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      customResourceSchema={schema.resource}
      iri={iri}
      method="PUT"
      url={iri}
      collectionPath={routes().index.path}
      storeCollectionId={schema.endpoint}
      sidebar={true}
      width={600}
    >
      <div style={{ marginTop: 30, marginBottom: 30 }}>
        <EmbeddedCollection
          endpoint={`${schema.subresources.prolongation.endpoint}?pagination=false`}
          pid={match.params.id}
          parentIri={iri}
          properties={schema.subresources.prolongation.properties}
          definitionSchema={
            definitions[schema.subresources.prolongation.definition]
          }
          headerTitle={translate('Prolongata')}
          panelTitle={translate('Przedłużono do')}
          titleAccessor={`title`}
          statable={false}
          key={iri}
          paginable={false}
          orderable={false}
          hideAddButton={true}
        />
      </div>
    </Form>
  )
}
