import {
  text,
  textWithPhoto,
  textWithLink,
  gallery,
  files,
  faq,
  logotypes,
  audio,
  branches,
  etr,
  contact,
  citation,
  author,
  multiSection,
  practicalInformation,
  issuu,
} from '_schema/_blocks'

const schema = (
  photoEndpoint,
  blockThumbEndpoint,
  blockMultiSectionEndpoint,
  blockMultiSectionThumbEndpoint,
  blockBranchesEndpoint,
  blockDidascaliaEndpoint,
  blockContactEndpoint
) => ({
  types: [
    text(blockDidascaliaEndpoint),
    textWithPhoto(photoEndpoint, blockThumbEndpoint),
    textWithLink(),
    gallery(),
    files(),
    faq(),
    logotypes(),
    audio(photoEndpoint),
    branches(blockBranchesEndpoint),
    etr(photoEndpoint, blockThumbEndpoint),
    contact(blockContactEndpoint),
    citation(),
    author(photoEndpoint, blockThumbEndpoint),
    multiSection(
      photoEndpoint,
      blockMultiSectionEndpoint,
      blockMultiSectionThumbEndpoint
    ),
    practicalInformation(),
    issuu(),
  ],
})

export default schema
