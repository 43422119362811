import { authConstants, commonConstants } from '_constants'

const initialState = {
  reload: false,
  isSidebarOpen: true,
  customBreadcrumbs: {},
  currentTabs: {},
}

export const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case commonConstants.INIT_RELOAD:
      return {
        ...state,
        reload: !state.reload,
      }
    case commonConstants.INIT_PAGE_MENU_RELOAD:
      return {
        ...state,
        pageMenuReload: !state.pageMenuReload,
      }
    case commonConstants.SIDEBAR_TOGGLE:
      return {
        ...state,
        isSidebarOpen: !state.isSidebarOpen,
      }
    case commonConstants.ADD_CUSTOM_BREADCRUMBS:
      return {
        ...state,
        currentResource: null,
        currentParentResource: null,
        customBreadcrumbs: {
          ...state.customBreadcrumbs,
          [action.payload.path]: action.payload.breadcrumbs,
        },
      }
    case commonConstants.SET_CURRENT_TAB:
      return {
        ...state,
        currentTabs: {
          ...state.currentTabs,
          [action.payload.path]: action.payload.value,
        },
      }
    case commonConstants.SET_CURRENT_RESOURCE:
      return {
        ...state,
        currentResource: action.payload.resource,
      }
    case commonConstants.SET_CURRENT_PARENT_RESOURCE:
      return {
        ...state,
        currentParentResource: action.payload.resource,
      }
    case commonConstants.RESET_CURRENT_RESOURCES:
      return {
        ...state,
        currentResource: null,
        currentParentResource: null,
      }
    case authConstants.REFRESH_FAILURE:
    case authConstants.LOGOUT:
      return initialState
    default:
      return state
  }
}
