import React, { useCallback, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import { BlockCollection } from 'components/block'
import { Form } from 'pages'
import { Sidebar } from './sidebar'
import { addOpenformProperties } from './_helpers/addOpenformProperties'
import { modifyPropertiesByPage } from './_helpers/modifyPropertiesByPage'
import { commonConstants } from '_constants'
import { LANGS_IRI } from '_lib/langs'
import schema from '_schema/page'
import routes from './routes'

const useStyles = makeStyles({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 0,
  },
  form: {
    marginTop: 0,
  },
})

export const Edit = ({ schema: definitions, ...rest }) => {
  const dispatch = useDispatch()

  const profile = useSelector(state => state.profile)
  const { match } = rest

  const iri = useMemo(() => `${schema.endpoint}/${match.params.id}`, [
    match.params.id,
  ])

  const isNotEditable = useCallback(
    resource => !profile.group?.isOpenform && !resource.isEditable,
    [profile.group]
  )

  const history = useHistory()
  const handleSuccess = useCallback(() => {
    dispatch({ type: commonConstants.INIT_PAGE_MENU_RELOAD })

    history.push(routes().index.path)
  }, [history, dispatch])

  const handleSuccessAndStay = useCallback(() => {
    dispatch({ type: commonConstants.INIT_PAGE_MENU_RELOAD })
  }, [dispatch])

  const classes = useStyles()

  return (
    <Form
      iri={iri}
      isNotEditable={isNotEditable}
      collectionPath={routes().index.path}
      definitionSchema={definitions[schema.resource.definition]}
      customResourceSchema={schema.resource}
      modifyProperties={(properties, resource, schema) => {
        let modifiedProperties = modifyPropertiesByPage(
          properties,
          resource,
          schema
        )
        return addOpenformProperties(
          profile.group?.isOpenform,
          modifiedProperties,
          resource,
          schema
        )
      }}
      method="PUT"
      url={iri}
      storeCollectionId={schema.endpoint}
      handleSuccess={handleSuccess}
      handleSuccessAndStay={handleSuccessAndStay}
      sidebar={true}
      sidebarTitleAccessor={`translations.${
        LANGS_IRI[process.env.REACT_APP_LOCALE.toUpperCase()]
      }.title`}
      SidebarComponent={Sidebar}
      fieldsFullWidth={false}
      width="100%"
      key={iri}
      classes={{ root: classes.form }}
    >
      <BlockCollection
        endpoint={schema.subresources.block.endpoint}
        pid={match.params.id}
        parentIri={iri}
        definitionSchema={definitions[schema.subresources.block.definition]}
        types={schema.subresources.block.types}
        contextualHint={schema.subresources.block.contextualHint}
        statable={true}
        key={iri}
      />
    </Form>
  )
}
