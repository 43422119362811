const schema = {
  endpoint: '/api/contest_edition_submissions',
  access: 'contestEditionSubmission',
  resource: {
    definition: '#ContestEditionSubmission',
    properties: {
      fullName: {
        description: 'Name and surname',
        fullWidth: true,
        validate: ['required'],
      },
      email: {
        description: 'T_GENERAL_EMAIL',
        fullWidth: true,
        validate: ['required'],
      },
      nomineeFullName: {
        fullWidth: true,
        validate: ['required'],
      },
      bookTitle: {
        fullWidth: true,
        validate: ['required'],
        label: 'T_MODULE_CONTEST_EDITION_SUBMISSION_BOOK_TITLE',
      },
      publisher: {
        fullWidth: true,
        validate: ['required'],
        label: 'T_MODULE_CONTEST_EDITION_SUBMISSION_PUBLISHER',
      },
      notes: {
        type: 'textarea',
        fullWidth: true,
      },
    },
  },
}

export default schema
