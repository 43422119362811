const schema = {
  endpoint: '/api/mobile_app_delete_account_requests',
  access: 'mobileAppDeleteAccountRequest',
  resource: {
    definition: '#MobileAppDeleteAccountRequest',
    properties: {
      content: {
        type: 'textarea',
        description: 'Powód (formularz)',
      },
      userEmail: {
        description: 'T_GENERAL_EMAIL',
        hint: 'Email użytkownika (SOWA)',
        disabled: true,
      },
      userUuid: {
        description: 'Identyfikator użytkownika (SOWA)',
        disabled: true,
      },
    },
  },
}

export default schema
