const schema = {
  endpoint: '/api/customer_groups',
  access: 'customerGroup',
  resource: {
    definition: '#CustomerGroup',
    properties: {
      title: {},
      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
}

export default schema
