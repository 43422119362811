import _block from './_block'
import mediaSchema from './media'
import photoSchema from './photo'

const schema = {
  endpoint: '/api/pages',
  clone_endpoint: '/api/pages/clone',
  access: 'page',
  resource: {
    definition: '#Page',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
          shortDescription: {
            type: 'string',
            description: 'T_GENERAL_DESCRIPTION_TILE',
          },
          description: {
            description: 'Opis',
            label: 'Description',
            type: 'tinymce',
          },
          link: {
            description: 'T_GENERAL_EXTERNAL_LINK',
          },
          metaTitle: {},
          metaDescription: {
            type: 'textarea',
          },
          metaKeywords: {
            type: 'textarea',
          },
          alt: {
            description: 'T_GENERAL_ALT',
          },
          slug: {},
        },
      },
      media: {
        type: 'image',
        description: 'T_GENERAL_PHOTO',
        endpoint: photoSchema.endpoint.single,
        validate: ['maxSize'],
        thumbs: {
          endpoint: '/api/page_thumbs',
          property: 'media',
        },
      },
      mediaThumbType: {
        type: 'resource',
        endpoint: `/api/thumb_types?pagination=false`,
        titleAccessor: `trans`,
        transTitle: true,
        label: 'T_GENERAL_MEDIA_PHOTO_TYPE',
        initialValue: { value: '/api/thumb_types/CROPPED', title: 'Kadr' },
        validate: ['required'],
      },
      hideDetailImage: {
        description: 'T_GENERAL_MEDIA_HIDE_IN_DETAIL',
      },
      mediaDetail: {
        type: 'image',
        description: 'T_GENERAL_PHOTO_DETAIL',
        endpoint: photoSchema.endpoint.single,
        validate: ['maxSize'],
        thumbs: {
          endpoint: '/api/page_thumbs',
          property: 'mediaDetail',
        },
      },
      events: {
        type: 'multipleResource',
        endpoint: `/api/events_select?pagination=false`,
        titleAccessor: `nativeTitle`,
        label: 'T_GENERAL_LINKED_EVENTS',
      },
      news: {
        type: 'multipleResource',
        endpoint: `/api/news_select?pagination=false`,
        titleAccessor: `nativeTitle`,
        label: 'T_GENERAL_LINKED_NEWS',
      },
      bookCatalogButtonStatus: {
        type: 'resource',
        endpoint: `/api/book_catalog_button_statuses?pagination=false`,
        titleAccessor: `trans`,
        transTitle: true,
        label: 'Przycisk katalog książek',
        initialValue: {
          value: '/api/book_catalog_button_statuses/FIXED',
          title: 'Pływający',
        },
        validate: ['required'],
      },
      showFooterCenter: {},
      showFooterRight: {},
      mainMenu: {},
      canOpen: {},
      stat: {},
    },
  },
  subresources: {
    block: {
      endpoint: '/api/page_blocks',
      definition: '#PageBlock',
      types: _block(
        mediaSchema.endpoint,
        '/api/page_block_thumbs',
        '/api/page_block_multi_sections',
        '/api/page_block_multi_section_thumbs',
        '/api/page_block_branches',
        '/api/page_block_didascalias',
        '/api/apage_block_contacts'
      ).types,
    },
  },
}

export default schema
