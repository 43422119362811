import { LANGS_IRI } from '../_lib/langs'

const schema = {
  endpoint: '/api/survey_submissions',
  clone_endpoint: '/api/survey_submissions/clone',
  access: 'surveySubmission',
  resource: {
    definition: '#SurveySubmission',
    properties: {
      author: {},
      title: {},
      year: {},
      notes: {
        type: 'textarea',
      },
      location: {
        type: 'resource',
        endpoint: `/api/branches?pagination=false`,
        titleAccessor: `translations.${LANGS_IRI.PL}.title`,
        label: 'T_MODULE_SURVEY_SUBMISSION_LOCATION',
      },
      alternativeLocation: {
        type: 'resource',
        endpoint: `/api/branches?pagination=false`,
        titleAccessor: `translations.${LANGS_IRI.PL}.title`,
        label: 'T_MODULE_SURVEY_SUBMISSION_ALTERNATIVE_LOCATION',
      },
    },
  },
}

export default schema
