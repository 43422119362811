const schema = {
  endpoint: '/api/annual_sections',
  access: 'annualSection',
  resource: {
    definition: '#AnnualSection',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            type: 'string',
            description: 'T_GENERAL_TITLE',
          },
        },
      },
      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
}

export default schema
