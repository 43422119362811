import React, { useMemo } from 'react'
import { NotFound } from 'pages'
import { Paper } from 'components/Paper'
import { Loader } from 'components/Loader'
import { CollectionHeader } from 'components/CollectionHeader'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { operations } from 'components/table/_columns/operations'
import { useResourceState } from '_helpers/useResourceState'
import { useResourceFetch } from '_helpers/useResourceFetch'
import { translate } from '_helpers/translate'
import schema from '_schema/eLibraryBook'
import routes from './routes'
import itemSchema from '_schema/eLibraryBookRequest'
import itemRoutes from 'pages/ELibrary/BookFiles/routes'
import { EditHeaderWithCustomTitle } from '../../../components/EditHeaderWithCustomTitle'
import { RentDatetimeCell } from '../Request/_cells/RentDatetimeCell'
import {DatetimeCell} from "../../../components/table/cells/DatetimeCell";

export const Requests = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const parentIri = useMemo(() => `${schema.endpoint}/${match.params.id}`, [
    match.params.id,
  ])

  const [state, setState] = useResourceState()

  const { resource, isFetching, fetchError } = state

  useResourceFetch(
    parentIri,
    resource,
    setState.isFetching,
    setState.resource,
    setState.fetchError,
    false
  )

  const itemColumns = useMemo(
    () => [
      {
        header: 'Email',
        accessor: `customer.email`,
        filterable: true,
        sortable: true,
        width: '10%',
      },
      {
        header: 'Książka',
        accessor: `bookTitle`,
        filterName: 'parent.title',
        filterable: true,
        sortable: true,
        width: '20%',
      },
      {
        header: 'Typ',
        accessor: `fileType.trans`,
        width: '5%',
        translateAccessor: true,
      },
      {
        header: 'Liczba prolongat',
        accessor: 'prolongCount',
        width: '5%',
      },
      {
        header: 'Data od',
        accessor: `timeFrom`,
        filterable: true,
        sortable: true,
        width: '5%',
        Cell: RentDatetimeCell,
      },
      {
        header: 'Data do',
        accessor: `timeTo`,
        filterable: true,
        sortable: true,
        width: '5%',
        Cell: RentDatetimeCell,
      },
      {
        header: 'Utworzono',
        accessor: `createdAt`,
        filterable: true,
        sortable: true,
        width: '5%',
        Cell: DatetimeCell,
      },
      {
        ...operations(`translations`, true, true, true, true, false),
        width: '4%',
      },
    ],
    []
  )

  return isFetching ? (
    <Paper>
      <Loader />
    </Paper>
  ) : fetchError ? (
    <NotFound />
  ) : (
    <Paper>
      <CollectionHeader
        buttonTitle={translate('T_MODULE_ELIBRARY_BOOK_FILE_NEW_BUTTON')}
        buttonPath={`${itemRoutes().new.path}?pid=${match.params.id}`}
        hideButton={false}
      >
        <EditHeaderWithCustomTitle
          resource={resource}
          editPath={routes().edit.path}
          textBeforeTitle={`${resource?.title} - pliki`}
        />
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${itemSchema.endpointBook}.get`}
        endpoint={itemSchema.endpointBook}
        definition={itemSchema.resource.definition}
        storeCollectionId={`${itemSchema.endpointBook}?iri[parent]=${parentIri}`}
        defaultFilters={{
          'iri[parent]': parentIri,
        }}
        columns={itemColumns}
        customResourceSchema={itemSchema.resource}
        defaultSorters={{ createdAt: 'desc' }}
        statable={true}
        //selectable={[publish.on(), publish.off()]}
        isRowLinkable={true}
        editPath={itemRoutes().edit.path}
        autoWidth={false}
        numerable={false}
        orderable={false}
        horizontalFilterBar={true}
        parameters={[
          {
            name: 'iri[parent]',
            in: 'query',
            type: 'string',
            value: false,
          },
          {
            name: `customer.email`,
            in: 'query',
            type: 'string',
          },
          {
            name: `parent.title`,
            in: 'query',
            type: 'string',
          },
        ]}
        schemaParameters={[
          {
            name: 'order[createdAt]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[timeFrom]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[timeTo]',
            in: 'query',
            type: 'string',
          },
        ]}
      />
    </Paper>
  )
}
