import React from 'react'
import { Route } from 'react-router-dom'
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined'
import { Collection } from './Collection'
import { SchemableComponent } from 'components/SchemableComponent'
import { New } from './New'
import { Edit } from './Edit'
import { addRoutesConfig } from '_helpers/addRoutesConfig'
import schema from '_schema/event'
import { Submissions } from './Submissions'
import { Participants } from './Participants'

const basePath = '/events'

const routes = () =>
  addRoutesConfig(
    {
      index: {
        subtitle: 'T_GENERAL_RECORD_LIST',
        type: Route,
        render: props => <Collection {...props} />,
        path: basePath,
        exact: true,
        icon: AssignmentOutlinedIcon,
      },
      new: {
        subtitle: 'T_GENERAL_RECORD_NEW',
        type: Route,
        render: props => (
          <SchemableComponent path="definitions" component={New} {...props} />
        ),
        exact: true,
        path: `${basePath}/new`,
      },
      submissions: {
        subtitle: 'T_GENERAL_RECORD_SHOW',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={Submissions}
            {...props}
          />
        ),
        exact: true,
        path: `${basePath}/:id/submissions`,
      },
      participants: {
        subtitle: 'T_GENERAL_RECORD_SHOW',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={Participants}
            {...props}
          />
        ),
        exact: true,
        path: `${basePath}/:id/participants`,
      },
      edit: {
        subtitle: 'T_GENERAL_RECORD_EDIT',
        type: Route,
        render: props => (
          <SchemableComponent path="definitions" component={Edit} {...props} />
        ),
        path: `${basePath}/:id`,
        exact: true,
      },
    },
    {
      title: 'T_GENERAL_LIST',
      resourceAccess: schema.access,
      indexPath: basePath,
    }
  )

export default routes
