import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import randomHash from 'random-hash'
import clsx from 'clsx'
import { FormControl, InputLabel, FormHelperText } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/styles'
import { Editor } from '@tinymce/tinymce-react'
import { validate } from '_helpers/validate'
import { translate } from '_helpers/translate'
import { styles as translationTabStyles } from 'components/form/fields/translation/TranslationTab.styles'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 15,
  },
  label: {
    position: 'relative',
    marginBottom: 15,
    transform: 'none',
  },
  compare: {
    marginTop: 5,
  },
  compareNeq: {
    color: theme.palette.error.main,
  },
}))

export const TinymceTitleType = ({
  name,
  label,
  hint = null,
  initialValue,
  value,
  compareValue = null,
  compare = false,
  error = false,
  renderError = false,
  disabled = false,
  validators,
  setValue,
  setError,
  formWidth = 300,
  translationLang = null,
}) => {
  const [id] = useState(randomHash())
  const theme = useTheme()

  const handleChange = content => {
    setValue(name, content)
    validateField(content)
  }

  const validateField = useCallback(
    value => {
      if (!validators) {
        setError(name, false)

        return
      }

      const valid = validate(validators, value)

      setError(name, !valid.result && valid.message)
    },
    [validators, setError, name]
  )

  useEffect(() => {
    validateField(initialValue)
  }, [validateField, initialValue])

  const classes = useStyles()
  const langUrl = `${window.location.origin.replace('admin.', '')}/tinymce/${
    process.env.REACT_APP_LOCALE
  }.js`

  return (
    <FormControl
      error={renderError && !!error}
      required={validators && validators.includes('required')}
      disabled={disabled}
      className={classes.root}
    >
      <InputLabel htmlFor={id} className={classes.label}>
        <span style={label?.color && { color: label?.color }}>
          {translate(label?.text || label)}
        </span>
      </InputLabel>
      <Editor
        //apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
        tinymceScriptSrc={
          'https://cdnjs.cloudflare.com/ajax/libs/tinymce/5.10.9/tinymce.min.js'
        }
        disabled={disabled}
        id={id}
        init={{
          menubar: '',
          force_br_newlines: true,
          force_p_newlines: false,
          forced_root_block: '',
          entity_encoding: 'raw',
          language: process.env.REACT_APP_LOCALE,
          language_url: langUrl,
          paste_as_text: false,
          image_advtab: true,
          plugins: [
            'autolink lists link charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime paste',
          ],
          contextmenu: 'link | copy paste',
          toolbar: 'bold italic',
          extended_valid_elements:
            '' +
            'onmousedown|onmouseup|onmouseover|onmousemove|onmouseout|onkeypress|' +
            'onkeydown|onkeyup],script[charset|defer|language|src|type]',
          style_formats: [
            {
              title: 'Inline',
              items: [
                { title: 'Bold', icon: 'bold', inline: 'strong' },
                { title: 'Italic', icon: 'italic', inline: 'em' },
                {
                  title: 'Underline',
                  icon: 'underline',
                  inline: 'span',
                  styles: { 'text-decoration': 'underline' },
                },
                {
                  title: 'Strikethrough',
                  icon: 'strikethrough',
                  inline: 'span',
                  styles: { 'text-decoration': 'line-through' },
                },
                { title: 'Superscript', icon: 'superscript', inline: 'sup' },
                { title: 'Subscript', icon: 'subscript', inline: 'sub' },
                { title: 'Code', icon: 'code', inline: 'code' },
              ],
            },
            {
              title: 'Blocks',
              items: [
                { title: 'Paragraph', block: 'p' },
                { title: 'Blockquote', block: 'blockquote' },
                { title: 'Div', block: 'div' },
                { title: 'Pre', block: 'pre' },
              ],
            },
            {
              title: 'Alignment',
              items: [
                {
                  title: 'Left',
                  icon: 'alignleft',
                  block: 'div',
                  styles: { 'text-align': 'left' },
                },
                {
                  title: 'Center',
                  icon: 'aligncenter',
                  block: 'div',
                  styles: { 'text-align': 'center' },
                },
                {
                  title: 'Right',
                  icon: 'alignright',
                  block: 'div',
                  styles: { 'text-align': 'right' },
                },
                {
                  title: 'Justify',
                  icon: 'alignjustify',
                  block: 'div',
                  styles: { 'text-align': 'justify' },
                },
              ],
            },
          ],
          paste_text_sticky: true,
          paste_text_sticky_default: true,
          browser_spellcheck: true,
          width:
            formWidth -
            (translationLang &&
              translationTabStyles.root?.paddingLeft +
                translationTabStyles.root?.paddingRight),
          height: 130,
          skin: theme.palette.type === 'dark' ? 'oxide-dark' : '',
          content_css: theme.palette.type === 'dark' ? 'oxide-dark' : '',
        }}
        textareaName={name}
        value={value}
        onEditorChange={handleChange}
      />
      <FormHelperText>
        {translate(renderError && error ? error : hint)}
      </FormHelperText>
      {compare && (
        <div
          className={clsx(
            classes.compare,
            value !== compareValue && classes.compareNeq
          )}
        >
          {compareValue}
        </div>
      )}
    </FormControl>
  )
}

TinymceTitleType.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ]).isRequired,
  hint: PropTypes.string,
  initialValue: PropTypes.string,
  value: PropTypes.string,
  compareValue: PropTypes.string,
  compare: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  renderError: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  validators: PropTypes.arrayOf(PropTypes.string),
  setValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  formWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  translationLang: PropTypes.string,
}
