import { BLOCK_TYPES_IRI } from '_lib/blockTypes'

export const etr = (photoEndpoint, thumbEndpoint) => ({
  name: BLOCK_TYPES_IRI.ETR,
  label: 'T_GENERAL_BLOCK_TYPE_ETR',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {},
        text: {
          description: 'Tekst',
          type: 'tinymce',
        },
        alt: {
          description: 'Tag alt',
          type: 'string',
        },
        alt2: {
          description: 'Tag alt 2',
          type: 'string',
        },
      },
    },
    media: {
      type: 'image',
      description: 'T_GENERAL_PHOTO',
      endpoint: photoEndpoint,
      thumbs: thumbEndpoint && {
        endpoint: thumbEndpoint,
      },
      validate: ['maxSize'],
    },
    media2: {
      type: 'image',
      description: 'T_GENERAL_PHOTO2',
      endpoint: photoEndpoint,
      thumbs: thumbEndpoint && {
        endpoint: thumbEndpoint,
      },
      validate: ['maxSize'],
    },
  },
})
