import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { constants } from 'components/table/_state'
import { fetchDataHandleAuthError } from '../../../../_helpers/fetchDataHandleAuthError'
import { notification } from '../../../../_helpers/notification'
import { prop } from '../../../../_helpers/prop'
import { translate } from '../../../../_helpers/translate'
import { Button } from '@material-ui/core'
import { Send } from '@material-ui/icons'

export const FirebaseSendNotificationCell = ({
  resource,
  accessor = 'pushSendTime',
  disabled = false,
  tableStateDispatch,
}) => {
  const onSuccess = value => {
    tableStateDispatch({
      type: constants.UPDATE_ITEM_FIELD,
      payload: {
        '@id': resource['@id'],
        accessor,
        value,
      },
    })
  }

  const [isFetching, setIsFetching] = useState(false)

  const onClick = () => {
    if (disabled || isFetching) {
      return
    }

    const endpoint = `${resource['@id']}/send_firebase_notification`

    setIsFetching(true)
    fetchDataHandleAuthError(
      endpoint,
      'PUT',
      {
        body: JSON.stringify({
          [accessor]: prop(resource, accessor),
        }),
      },
      resource => {
        setIsFetching(false)
        notification('success', 'T_GENERAL_STATUS_CHANGED', 'T_FORM_SUCCESS')
        onSuccess && onSuccess(prop(resource, accessor))
      },
      error => {
        setIsFetching(false)
        notification('error', error.response.detail, error.response.title)
      }
    )
  }

  if (prop(resource, accessor)) {
    return (
      <span>
        {translate('Wysłano:')} {prop(resource, accessor)}
      </span>
    )
  } else {
    return (
      <Button
        variant="outlined"
        color="primary"
        size="small"
        startIcon={<Send />}
        onClick={onClick}
        disabled={disabled || isFetching}
      >
        {translate('Wyślij')}
      </Button>
    )
  }
}

FirebaseSendNotificationCell.propTypes = {
  resource: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
  }).isRequired,
  accessor: PropTypes.string,
  disabled: PropTypes.bool,
  tableStateDispatch: PropTypes.func.isRequired,
}
