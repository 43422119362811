import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import packageLogotype from '../packageLogotype'

export const logotypes = () => ({
  name: BLOCK_TYPES_IRI.LOGOTYPES,
  label: 'T_GENERAL_BLOCK_TYPE_LOGOTYPES',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {},
      },
    },
    logotypes: {
      type: 'resource',
      endpoint: `${packageLogotype.endpoint}?pagination=false`,
      titleAccessor: 'title',
      description: 'Select package logotype',
    },
  },
})
