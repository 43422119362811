import React, { useMemo } from 'react'
import { Paper } from 'components/Paper'
import { CollectionHeader } from 'components/CollectionHeader'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { operations } from 'components/table/_columns/operations'
import { translate } from '_helpers/translate'
import { publish } from 'components/table/_mass'
import schema from '_schema/customer'
import routes from './routes'
import { ResourceFilter } from '../../components/table/filters/ResourceFilter'

export const Collection = () => {
  const columns = useMemo(
    () => [
      {
        header: 'Imię',
        accessor: `firstName`,
        filterable: true,
        sortable: true,
        width: '10%',
      },
      {
        header: 'Nazwisko',
        accessor: `lastName`,
        filterable: true,
        sortable: true,
        width: '10%',
      },
      {
        header: translate('T_GENERAL_EMAIL'),
        accessor: `email`,
        filterable: true,
        sortable: true,
        width: '60%',
      },
      {
        header: translate('Grupy'),
        accessor: `groupsTitles`,
        filterable: true,
        filterName: 'groups',
        width: '10%',
      },
      {
        header: translate('Numer karty bibliotecznej'),
        accessor: `libraryCard`,
        filterable: true,
        filterName: 'libraryCard',
        width: '10%',
      },
      {
        ...operations(`translations`, true, true, true, true, false),
        width: '10%',
      },
    ],
    []
  )

  return (
    <Paper>
      <CollectionHeader
        buttonTitle={translate('T_MODULE_CUSTOMER_NEW_BUTTON')}
        buttonPath={routes().new.path}
      >
        {translate('T_MODULE_CUSTOMER')}
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${schema.endpoint}.get`}
        endpoint={schema.endpoint}
        definition={schema.resource.definition}
        columns={columns}
        customResourceSchema={schema.resource}
        defaultSorters={{ ord: 'desc' }}
        orderable={true}
        selectable={[publish.on(), publish.off()]}
        isRowLinkable={true}
        editPath={routes().edit.path}
        autoWidth={false}
        parameters={[
          {
            name: 'iri[groups]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'libraryCard',
            in: 'query',
            type: 'string',
          },
          {
            name: 'firstName',
            in: 'query',
            type: 'string',
          },
          {
            name: 'lastName',
            in: 'query',
            type: 'string',
          },
          {
            name: 'email',
            in: 'query',
            type: 'string',
          },
        ]}
        schemaParameters={[
          {
            name: 'order[firstName]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[lastName]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[email]',
            in: 'query',
            type: 'string',
          },
        ]}
        customFilters={[
          {
            name: 'iri[groups]',
            title: 'Grupy',
            Filter: ResourceFilter,
            endpoint: '/api/customer_groups?pagination=false',
            titleAccessor: 'title',
          },
        ]}
      />
    </Paper>
  )
}
