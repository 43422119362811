import home from 'pages/Home/routes'
import admins from 'pages/Admins/routes'
import adminGroups from 'pages/AdminGroups/routes'
import loginEntries from 'pages/LoginEntries/routes'
import registryEntries from 'pages/RegistryEntries/routes'
import settings from 'pages/Settings/routes'
import profile from 'pages/Profile/routes'
import pages from 'pages/Pages/routes'
import translatorEntries from 'pages/TranslatorEntries/routes'
import packageGalleries from 'pages/PackageGalleries/routes'
import packageGalleryItems from 'pages/PackageGalleryItems/routes'
import packageFiles from 'pages/PackageFiles/routes'
import packageFaqs from 'pages/PackageFaqs/routes'
import packageLogotypes from 'pages/PackageLogotypes/routes'
import packageLogotypeItems from 'pages/PackageLogotypeItems/routes'
import branches from 'pages/Branches/routes'
import branchCategory from 'pages/BranchCategory/routes'
import branchAmenity from 'pages/BranchAmenity/routes'
import branchAccessibility from 'pages/BranchAccessibility/routes'
import branchAdditionalOffer from 'pages/BranchAdditionalOffer/routes'
import branchAward from 'pages/BranchAward/routes'
import practicalInformation from 'pages/PracticalInformations/routes'
import news from 'pages/News/routes'
import newsCategory from 'pages/NewsCategory/routes'
import event from 'pages/Event/routes'
import eventCategory from 'pages/EventCategory/routes'
import eventRecipient from 'pages/EventRecipient/routes'
import eventLocation from 'pages/EventLocation/routes'
import eventSubmission from 'pages/EventSubmission/routes'
import catalog from 'pages/Catalogs/routes'
import offer from 'pages/Offers/routes'
import survey from 'pages/Surveys/routes'
import surveySubmission from 'pages/SurveySubmissions/routes'
import config from 'pages/Config/routes'
import contactPeople from 'pages/ContactPeople/routes'
import club from 'pages/Clubs/routes'
import project from 'pages/Project/routes'
import annual from 'pages/Annual/routes'
import contest from 'pages/Contest/routes'
import homeBanner from 'pages/HomeBanner/routes'
import customer from 'pages/Customer/routes'
import customerGroup from 'pages/CustomerGroup/routes'
import eElibraryBook from 'pages/ELibrary/Book/routes'
import eElibraryBookRequest from 'pages/ELibrary/Request/routes'
import eElibraryBookFile from 'pages/ELibrary/BookFiles/routes'
import eElibraryConfig from 'pages/ELibraryConfig/routes'
import eElibraryReport from 'pages/ELibrary/Report/routes'
import generalArticle from 'pages/GeneralArticle/routes'
import tender from 'pages/Tender/routes'
import mobileAppContactForm from 'pages/MobileAppContactForm/routes'
import mobileAppDeleteAccountRequest from 'pages/MobileAppDeleteAccountRequest/routes'
import mobileAppBranchSubscription from 'pages/MobileAppBranchSubscription/routes'
import mobileAppNotification from 'pages/MobileAppNotification/routes'

const routes = () => [
  ...getArray(home()),
  ...getArray(pages()),
  ...getArray(packageGalleries()),
  ...getArray(packageGalleryItems()),
  ...getArray(packageFiles()),
  ...getArray(packageFaqs()),
  ...getArray(packageLogotypes()),
  ...getArray(packageLogotypeItems()),
  ...getArray(branches()),
  ...getArray(branchCategory()),
  ...getArray(branchAmenity()),
  ...getArray(branchAccessibility()),
  ...getArray(branchAdditionalOffer()),
  ...getArray(branchAward()),
  ...getArray(practicalInformation()),
  ...getArray(admins()),
  ...getArray(adminGroups()),
  ...getArray(loginEntries()),
  ...getArray(registryEntries()),
  ...getArray(translatorEntries()),
  ...getArray(settings()),
  ...getArray(profile()),
  ...getArray(news()),
  ...getArray(newsCategory()),
  ...getArray(event()),
  ...getArray(eventCategory()),
  ...getArray(eventRecipient()),
  ...getArray(eventLocation()),
  ...getArray(eventSubmission()),
  ...getArray(catalog()),
  ...getArray(offer()),
  ...getArray(survey()),
  ...getArray(surveySubmission()),
  ...getArray(config()),
  ...getArray(contactPeople()),
  ...getArray(club()),
  ...getArray(project()),
  ...getArray(annual()),
  ...getArray(contest()),
  ...getArray(homeBanner()),
  ...getArray(customer()),
  ...getArray(customerGroup()),
  ...getArray(eElibraryBook()),
  ...getArray(eElibraryBookRequest()),
  ...getArray(eElibraryBookFile()),
  ...getArray(eElibraryConfig()),
  ...getArray(eElibraryReport()),
  ...getArray(generalArticle()),
  ...getArray(tender()),
  ...getArray(mobileAppContactForm()),
  ...getArray(mobileAppDeleteAccountRequest()),
  ...getArray(mobileAppBranchSubscription()),
  ...getArray(mobileAppNotification()),
]

const getArray = routes => Object.values(routes)

export default routes
