import React, { useMemo, useReducer, useEffect, useCallback } from 'react'
import clsx from 'clsx'
import _ from 'lodash'
import { Warning } from '@material-ui/icons'
import { Loader } from 'components/Loader'
import { Title } from 'components/Title'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'
import { translate } from '_helpers/translate'
import { isObjectEmpty } from '_helpers/isObjectEmpty'
import { createObjectFromString } from '_helpers/createObjectFromString'
import { SubmitButton, CancelButton, AcceptButton } from './buttons'
import { initState } from './_helpers/initState'
import { FieldError } from './_helpers/fieldError'
import { fields } from './fields'
import { constants, reducer } from './_state'
import { propTypes, useStyles } from '.'
import { useSelector } from 'react-redux'
import { Alert } from '@material-ui/lab'

export const Form = ({
  readOnly = false,
  title = null,
  url,
  method = 'PUT',
  properties,
  resource: rawResource = null,
  defaultData = {},
  handleSubmit: customHandleSubmit = null,
  handleSuccess = null,
  handleSuccessAndStay = null,
  handleCancel = null,
  showSubmitAndStayButton = true,
  showCancelButton = true,
  disabled = false,
  fieldsFullWidth = false,
  width = 300,
  classes = {},
  children = null,
  buttonContainerRef = null,
  fieldNodeRefs = {},
  buttonsFixed = false,
  fetchCompareResource = false,
  definitionSchema = null,
}) => {
  const resource = useMemo(() => {
    if (!rawResource) {
      return null
    }

    const modifiedResource = { ...rawResource }

    if (
      properties?.stat === undefined &&
      modifiedResource?.stat !== undefined
    ) {
      delete modifiedResource.stat
    }

    return modifiedResource
  }, [rawResource, properties])

  const [state, dispatch] = useReducer(
    reducer,
    {
      properties,
      resource,
      defaultData,
      url,
      method,
      fetchCompareResource:
        !readOnly &&
        fetchCompareResource &&
        method === 'PUT' &&
        rawResource?.updatedAt !== undefined,
    },
    initState
  )

  const setValue = useCallback((name, value, setRenderError = true) => {
    dispatch({
      type: constants.SET_VALUE,
      payload: { name, value, setRenderError },
    })
  }, [])

  const setError = useCallback((name, error) => {
    dispatch({ type: constants.SET_ERROR, payload: { name, error } })
  }, [])

  const setAdditional = useCallback(additional => {
    dispatch({ type: constants.SET_ADDITIONAL, payload: { additional } })
  }, [])

  useEffect(() => {
    if (!state.isSubmitted) {
      return
    }

    dispatch({ type: constants.RENDER_ERROR })
  }, [state.isSubmitted])

  useEffect(() => {
    setValue('updatedAt', rawResource?.updatedAt)
  }, [rawResource, setValue])

  const handleSubmitButton = e => {
    e.preventDefault()
    handleSubmit(handleSuccess)
  }
  const handleSubmitAndStayButton = e => {
    e.preventDefault()
    handleSubmit(handleSuccessAndStay)
  }

  const handleAcceptButton = e => {
    e.preventDefault()
    dispatch({ type: constants.REMOVE_COMPARE_RESOURCE })
  }

  const handleSubmit = handleSuccess => {
    if (readOnly) {
      return
    }

    dispatch({ type: constants.SUBMIT })

    if (state.isInvalid) {
      return
    }

    if (!state.fetchCompareResource) {
      performSubmit(handleSuccess)

      return
    }

    dispatch({ type: constants.PROCESS, payload: true })

    fetchDataHandleAuthError(
      state.url,
      'GET',
      {},
      response => {
        if (
          response.updatedAt === undefined ||
          response.updatedAt === state.values.updatedAt
        ) {
          performSubmit(handleSuccess)

          return
        }

        dispatch({
          type: constants.SET_COMPARE_RESOURCE,
          payload: response,
        })
      },
      error => {
        dispatch({ type: constants.PROCESS, payload: false })

        notification('error', error.response.detail, error.response.title)
      },
      {}
    )
  }

  const performSubmit = handleSuccess => {
    if (customHandleSubmit) {
      customHandleSubmit(state.values)

      return
    }

    dispatch({ type: constants.PROCESS, payload: true })

    fetchDataHandleAuthError(
      state.url,
      method,
      { body: JSON.stringify(state.values) },
      response => {
        if (properties?.stat === undefined && response?.stat !== undefined) {
          delete response.stat
        }

        dispatch({
          type: constants.SUCCESS,
          payload: {
            method,
            resource: response,
          },
        })
        notification(
          'success',
          method === 'PUT' ? 'T_FORM_RECORD_UPDATED' : 'T_FORM_RECORD_CREATED',
          'T_FORM_SUCCESS'
        )
        handleSuccess && handleSuccess(response)
      },
      error => {
        const errors = error.response.violations.reduce(
          (processedErrors, item) => {
            const processedError = createObjectFromString(
              item.propertyPath.replace('[', '.').replace(']', ''),
              item.message
            )

            return _.merge(processedErrors, processedError)
          },
          {}
        )

        dispatch(
          isObjectEmpty(errors)
            ? { type: constants.PROCESS, payload: false }
            : { type: constants.FAILURE, payload: { errors } }
        )

        notification(
          'error',
          error.response.violations.length
            ? 'T_FORM_INCORRECT'
            : error.response.detail,
          error.response.title
        )
      },
      {}
    )
  }

  const defaultClasses = useStyles()
  const isSidebarOpen = useSelector(state => state.common.isSidebarOpen)

  return (
    <div>
      {title && <Title>{translate(title)}</Title>}
      {state.isProcessing && <Loader />}
      {state.compareValues && (
        <div className={defaultClasses.recordChanged}>
          <Warning /> <span>{translate('T_FORM_RECORD_CHANGED')}</span>
        </div>
      )}
      <form
        className={clsx(defaultClasses.root, classes.root)}
        style={{
          width,
        }}
      >
        {children &&
          children({
            disabled: state.isProcessing,
            submitted: state.isSubmitted,
            setValue,
            setError,
            setAdditional,
          })}
        {Object.keys(properties).map(name => {
          if (
            typeof properties[name].type === 'string' &&
            !fields[properties[name].type]
          ) {
            throw new FieldError(properties[name].type)
          }

          const FieldComponent =
            typeof properties[name].type === 'string'
              ? fields[properties[name].type]
              : properties[name].type

          const {
            type,
            description,
            hint,
            validate,
            $ref,
            disabled: fieldDisabled,
            ...rest
          } = properties[name]

          if (!type) {
            console.error(
              'Type is not defined for field',
              name,
              properties[name]
            )

            return (
              <div
                key={name}
                className={clsx(defaultClasses.field, classes.field)}
              >
                <Alert variant="filled" severity="error">
                  Invalid schema for field: {name}
                </Alert>
              </div>
            )
          }

          return (
            <div
              key={name}
              className={clsx(defaultClasses.field, classes.field)}
            >
              <FieldComponent
                uuid={state.values.uuid}
                iri={state.values['@id']}
                formUrl={state.url}
                formMethod={method}
                name={name}
                type={type}
                label={description}
                hint={hint}
                initialValue={
                  resource?.[name] !== undefined
                    ? resource[name]
                    : properties[name].defaultValue !== undefined
                    ? properties[name].defaultValue
                    : null
                }
                value={state.values[name]}
                compareValue={state.compareValues?.[name]}
                compare={!!state.compareValues}
                error={state.errors[name]}
                renderError={state.renderError[name]}
                disabled={
                  readOnly || fieldDisabled || disabled || state.isProcessing
                }
                validators={validate}
                setValue={setValue}
                setError={setError}
                additional={state.additional}
                setAdditional={setAdditional}
                fullWidth={fieldsFullWidth}
                formWidth={width}
                definitionRef={$ref}
                nodeRef={fieldNodeRefs[name] || null}
                definitionSchema={definitionSchema}
                minimumDimensions={state.values['thumbsDimensions']}
                resource={state.values}
                {...rest}
              />
            </div>
          )
        })}
        {!readOnly && (
          <div
            className={
              !buttonContainerRef
                ? clsx(
                    buttonsFixed && defaultClasses.buttonsFixed,
                    isSidebarOpen && defaultClasses.buttonsFixedOpen
                  )
                : null
            }
          >
            {state.compareValues && (
              <>
                <AcceptButton
                  handleAccept={handleAcceptButton}
                  disabled={
                    disabled ||
                    state.isProcessing ||
                    (state.isSubmitted && state.isInvalid)
                  }
                  classes={{ submit: classes.submit }}
                  nodeRef={buttonContainerRef}
                />
              </>
            )}
            {!state.compareValues && (
              <>
                <SubmitButton
                  handleSubmit={handleSubmitButton}
                  disabled={
                    disabled ||
                    state.isProcessing ||
                    (state.isSubmitted && state.isInvalid)
                  }
                  classes={{ submit: classes.submit }}
                  nodeRef={buttonContainerRef}
                />
                {showSubmitAndStayButton && (
                  <SubmitButton
                    title={translate('T_FORM_SAVE_AND_STAY')}
                    handleSubmit={handleSubmitAndStayButton}
                    disabled={
                      disabled ||
                      state.isProcessing ||
                      (state.isSubmitted && state.isInvalid)
                    }
                    classes={{ submit: classes.submit }}
                    nodeRef={buttonContainerRef}
                  />
                )}
              </>
            )}
            {showCancelButton && (
              <CancelButton
                handleCancel={handleCancel}
                disabled={
                  disabled ||
                  state.isProcessing ||
                  (state.isSubmitted && state.isInvalid)
                }
                classes={{ submit: classes.submit }}
                nodeRef={buttonContainerRef}
              />
            )}
          </div>
        )}
      </form>
    </div>
  )
}

Form.propTypes = propTypes
