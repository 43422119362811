const schema = {
  endpoint: '/api/e_library_books',
  access: 'eLibraryBook',
  resource: {
    definition: '#ELibraryBook',
    properties: {
      title: {},
      sowaId: {
        description: 'Identyfikator w systemie sowa',
        validate: ['required'],
      },
      rentalLimit: {
        description: 'Limit wypożyczeń',
        validate: ['required'],
      },
    },
  },
}

export default schema
