import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Select, MenuItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { translate } from '_helpers/translate'
import { constants } from './_state'

const useStyles = makeStyles(theme => ({
  container: {
    color: theme.palette.text.secondary,
    fontSize: '0.75rem',
    fontFamily: theme.typography.fontFamily,
    margin: 8,
    fontWeight: 400,
    lineHeight: 1.66,
    letterSpacing: '0.03333em',
    textAlign: 'left',
  },
  title: {
    marginRight: 8,
  },
  select: {
    fontSize: 14,
  },
}))

export const MassSelectChoice = ({
  selectable,
  tableState,
  tableStateDispatch,
  classes = {},
}) => {
  const handleMassSelectAction = useCallback(
    e => {
      const operation = selectable.find(item => item.action === e.target.value)

      if (!operation) {
        return
      }

      tableStateDispatch({
        type: constants.MASS_SELECT_ACTION_START,
        payload: {
          action: operation.action,
        },
      })

      operation.execute(tableState, tableStateDispatch)
    },
    [selectable, tableState, tableStateDispatch]
  )

  const defaultClasses = useStyles()

  return !tableState.data.selected.reduce(
    (acc, current) =>
      acc || (typeof current === 'boolean' ? current : current.value),
    false
  ) ? null : (
    <div className={clsx(defaultClasses.container, classes.container)}>
      <span className={clsx(defaultClasses.title, classes.title)}>
        {translate('T_GENERAL_MASS_SELECT_ACTION')}
      </span>
      <Select
        id="mass-select"
        value="choose"
        classes={{
          select: defaultClasses.select,
        }}
        onChange={handleMassSelectAction}
        className={defaultClasses.separator}
      >
        <MenuItem value="choose" key="choose">
          {translate('T_GENERAL_MASS_SELECT_CHOOSE')}
        </MenuItem>
        {selectable.map(item => (
          <MenuItem value={item.action} key={item.action}>
            {item.title}
          </MenuItem>
        ))}
      </Select>
    </div>
  )
}

MassSelectChoice.propTypes = {
  selectable: PropTypes.arrayOf(
    PropTypes.shape({
      action: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      execute: PropTypes.func.isRequired,
    })
  ).isRequired,
  tableState: PropTypes.shape({
    data: PropTypes.shape({
      selected: PropTypes.arrayOf(
        PropTypes.oneOfType([
          PropTypes.bool,
          PropTypes.shape({
            value: PropTypes.bool,
          }),
        ])
      ),
      selectAll: PropTypes.bool,
    }).isRequired,
    isInit: PropTypes.bool,
    isFetching: PropTypes.bool,
  }).isRequired,
  tableStateDispatch: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    container: PropTypes.string,
    title: PropTypes.string,
  }),
}
