const schema = {
  endpoint: '/api/surveys',
  clone_endpoint: '/api/surveys/clone',
  access: 'survey',
  resource: {
    definition: '#Survey',
    properties: {
      title: {},
      dateFrom: {
        type: 'datetime',
        description: 'T_GENERAL_DATE_FROM',
        validate: ['required'],
      },
      dateTo: {
        type: 'datetime',
        description: 'T_GENERAL_DATE_TO',
        validate: ['required'],
      },
      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
  subresources: {
    item: {
      endpoint: '/api/survey_entries',
      definition: '#SurveyEntry',
      properties: {
        title: {},
        author: {}
      },
    },
  },
}

export default schema
