import { useState, useEffect } from 'react'
import buildUrl from 'build-url'
import { getCrop } from './getCrop'
import { getConfig } from './getConfig'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'
import { THUMB_TYPES_IRI } from '_lib/thumbType'

const initialState = {
  items: [],
  currentThumb: 0,
  init: true,
  isFetching: false,
  isFullScreen: false,
  isUploadDialogOpen: false,
}

export const useThumbCollectionFetch = (
  endpoint,
  parentIri,
  property = '',
  name = ''
) => {
  const [state, setState] = useState(initialState)

  useEffect(() => {
    if (!parentIri) {
      setState(state => ({
        ...state,
        init: false,
      }))

      return
    }

    const controller = new AbortController()
    const { signal } = controller

    setState(state => ({
      ...state,
      isFetching: true,
    }))

    let queryParams = {
      'iri[parent]': parentIri,
      'iri[type]': THUMB_TYPES_IRI.CROPPED,
    }

    if (property) {
      queryParams['property'] = property
    }

    if (name) {
      queryParams['name'] = name
    }

    const url = buildUrl(endpoint, {
      queryParams: queryParams,
    })

    fetchDataHandleAuthError(
      url,
      'GET',
      { signal },
      response => {
        setState(state => ({
          ...state,
          items: response['hydra:member'].map(resource => ({
            resource,
            original: {
              clientWidth: null,
              clientHeight: null,
              naturalWidth: null,
              naturalHeight: null,
            },
            crop: getCrop(resource),
            config: getConfig(resource),
          })),
          init: false,
          isFetching: false,
          currentThumb: 0,
          isFullScreen: null,
        }))
      },
      error => {
        if (error.response.title === 'AbortError') {
          return
        }

        setState(state => ({
          ...state,
          isFetching: false,
        }))

        notification('error', error.response.detail, error.response.title)
      }
    )

    return () => controller.abort()
  }, [endpoint, name, parentIri, property])

  return [state, setState]
}
