export const BLOCK_TYPES = {
  '/api/block_types/TEXT': 'text',
  '/api/block_types/TEXT_WITH_PHOTO': 'text_with_photo',
  '/api/block_types/TEXT_WITH_LINK': 'text_with_link',
  '/api/block_types/GALLERY': 'gallery',
  '/api/block_types/FILES': 'files',
  '/api/block_types/FAQ': 'faq',
  '/api/block_types/LOGOTYPES': 'logotypes',
  '/api/block_types/AUDIO': 'audio',
  '/api/block_types/BRANCHES': 'branches',
  '/api/block_types/ETR': 'etr',
  '/api/block_types/CONTACT': 'contact',
  '/api/block_types/CITATION': 'citation',
  '/api/block_types/AUTHOR': 'author',
  '/api/block_types/MULTI_SECTION': 'multi_section',
  '/api/block_types/PRACTICAL_INFORMATION': 'practical_information',
  '/api/block_types/ISSUU': 'issuu',
}

export const BLOCK_TYPES_IRI = {
  TEXT: '/api/block_types/TEXT',
  TEXT_WITH_PHOTO: '/api/block_types/TEXT_WITH_PHOTO',
  TEXT_WITH_LINK: '/api/block_types/TEXT_WITH_LINK',
  GALLERY: '/api/block_types/GALLERY',
  FILES: '/api/block_types/FILES',
  FAQ: '/api/block_types/FAQ',
  LOGOTYPES: '/api/block_types/LOGOTYPES',
  AUDIO: '/api/block_types/AUDIO',
  BRANCHES: '/api/block_types/BRANCHES',
  ETR: '/api/block_types/ETR',
  CONTACT: '/api/block_types/CONTACT',
  CITATION: '/api/block_types/CITATION',
  AUTHOR: '/api/block_types/AUTHOR',
  MULTI_SECTION: '/api/block_types/MULTI_SECTION',
  PRACTICAL_INFORMATION: '/api/block_types/PRACTICAL_INFORMATION',
  ISSUU: '/api/block_types/ISSUU',
}
