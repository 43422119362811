import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { TextCell } from './TextCell'
import { InputCell } from './InputCell'
import { constants } from 'components/table/_state'
import { prop } from '_helpers/prop'

const useStyles = makeStyles(theme => ({
  disabled: {
    color: theme.palette.disabled,
  },
}))

export const EditableCell = ({
  resource,
  accessor,
  disabled = false,
  propertySchema,
  tableStateDispatch,
  inputOnLoad = false,
}) => {
  const [isInput, setIsInput] = useState(inputOnLoad)

  const onSuccess = value => {
    tableStateDispatch({
      type: constants.UPDATE_ITEM_FIELD,
      payload: {
        '@id': resource['@id'],
        accessor,
        value,
      },
    })
  }

  const classes = useStyles()

  return (
    <>
      {disabled ? (
        <div className={classes.disabled}>{prop(resource, accessor)}</div>
      ) : isInput ? (
        <InputCell
          resource={resource}
          accessor={accessor}
          propertySchema={propertySchema}
          onSuccess={onSuccess}
        />
      ) : (
        <TextCell
          resource={resource}
          accessor={accessor}
          setIsInput={setIsInput}
        />
      )}
    </>
  )
}

EditableCell.propTypes = {
  resource: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
  }).isRequired,
  accessor: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  propertySchema: PropTypes.shape({
    validate: PropTypes.arrayOf(PropTypes.string),
  }),
  tableStateDispatch: PropTypes.func.isRequired,
  inputOnLoad: PropTypes.bool,
}
