export const hasResourceAccess = (resourceAccess, user) => {
  if (!resourceAccess) {
    return true
  }

  if (user.isPasswordResetRequired) {
    return false
  }

  if (
    user.group?.isOpenform ||
    user.group?.isSuperAdmin ||
    (user.group?.resourceAccess &&
      user.group?.resourceAccess.includes(resourceAccess))
  ) {
    return true
  }

  return false
}
