import { BLOCK_TYPES_IRI } from '_lib/blockTypes'

export const textWithLink = () => ({
  name: BLOCK_TYPES_IRI.TEXT_WITH_LINK,
  label: 'T_GENERAL_BLOCK_TYPE_TEXT_WITH_LINK',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {},
        text: {
          description: 'Tekst',
          type: 'tinymce',
        },
        link: {
          type: 'string',
          description: 'T_GENERAL_BLOCK_LINK',
        },
        linkTitle: {
          type: 'string',
          description: 'T_GENERAL_BLOCK_LINK_TITLE',
        },
      },
    },
  },
})
