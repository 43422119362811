import React from 'react'
import { Form } from 'pages'
import schema from '_schema/config'

export const Edit = ({ schema: definitions, ...rest }) => {
  const iri = `${schema.endpoint}`

  return (
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      customResourceSchema={schema.resource}
      iri={iri}
      method="PUT"
      url={iri}
      storeCollectionId={schema.endpoint}
      sidebar={false}
      width={600}
    >
      <div style={{ paddingTop: 30 }}></div>
    </Form>
  )
}
