import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {
  FullscreenOutlined,
  FullscreenExitOutlined,
  GetAppOutlined,
} from '@material-ui/icons'
import { Button } from '@material-ui/core'
import { makeStyles, alpha } from '@material-ui/core/styles'
import { Crop } from './Crop'
import { translate } from '_helpers/translate'

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: 'center',
  },
  fullScreen: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: theme.zIndex.drawer + 2,
    background: alpha(theme.elements.background, 0.8),
    padding: '30px 0',
    overflowY: 'scroll',
  },
  container: {
    display: 'inline-block',
  },
  additionalButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  fullScreenButton: {
    color: theme.palette.info.main,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  submitButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 15,
  },
  resetButton: {
    color: theme.elements.content,
    background: theme.palette.error.main,
    '&:hover': {
      background: alpha(theme.palette.error.main, 0.9),
    },
    '&:active': {
      background: theme.palette.error.main,
    },
  },
  cropFullScreen: {
    '& img': {
      maxHeight: '85vh',
    },
  },
}))

export const ThumbCropper = ({
  originalPhotoUrl,
  crop,
  minWidth,
  minHeight,
  maxWidth,
  maxHeight,
  setCrop,
  setOriginal = null,
  handleFullScreen,
  handleReset,
  handleSubmit,
  handleUploadDialogToggle = null,
  disabled = false,
  isFullScreen = false,
  classes = {},
}) => {
  const defaultClasses = useStyles()

  return (
    <div
      className={clsx(
        defaultClasses.root,
        classes.root,
        isFullScreen && defaultClasses.fullScreen,
        isFullScreen && classes.fullScreen
      )}
    >
      <div className={defaultClasses.container}>
        <Crop
          src={`${process.env.REACT_APP_BACKEND_ENTRYPOINT}/${originalPhotoUrl}`}
          crop={crop}
          minWidth={minWidth}
          minHeight={minHeight}
          maxWidth={maxWidth}
          maxHeight={maxHeight}
          setCrop={setCrop}
          setOriginal={setOriginal}
          disabled={disabled}
          classes={{
            crop: clsx(
              defaultClasses.crop,
              classes.crop,
              isFullScreen && defaultClasses.cropFullScreen,
              isFullScreen && classes.cropFullScreen
            ),
          }}
        />
        <div
          className={clsx(
            defaultClasses.additionalButtons,
            classes.additionalButtons
          )}
        >
          <div
            className={clsx(
              defaultClasses.fullScreenButton,
              classes.fullScreenButton
            )}
            onClick={handleFullScreen}
          >
            {isFullScreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
            {translate(
              isFullScreen ? 'T_GENERAL_NORMAL_VIEW' : 'T_GENERAL_FULL_SCREEN'
            )}
          </div>
        </div>
        <div
          className={clsx(defaultClasses.submitButtons, classes.submitButtons)}
        >
          <Button
            variant="contained"
            size="small"
            className={clsx(defaultClasses.resetButton, classes.resetButton)}
            disabled={disabled}
            onClick={handleReset}
          >
            {translate('T_GENERAL_PHOTO_THUMBS_RESET')}
          </Button>
          {handleUploadDialogToggle && (
            <Button
              variant="contained"
              color="default"
              size="small"
              disabled={disabled}
              onClick={handleUploadDialogToggle}
              endIcon={<GetAppOutlined />}
            >
              {translate('T_GENERAL_PHOTO_THUMBS_CHANGE_FILE')}
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            size="small"
            disabled={disabled}
            onClick={handleSubmit}
          >
            {translate('T_GENERAL_PHOTO_THUMBS_SAVE')}
          </Button>
        </div>
      </div>
    </div>
  )
}

ThumbCropper.propTypes = {
  originalPhotoUrl: PropTypes.string.isRequired,
  crop: PropTypes.object.isRequired,
  minWidth: PropTypes.number.isRequired,
  minHeight: PropTypes.number.isRequired,
  maxWidth: PropTypes.number.isRequired,
  maxHeight: PropTypes.number.isRequired,
  setCrop: PropTypes.func.isRequired,
  setOriginal: PropTypes.func,
  handleFullScreen: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleUploadDialogToggle: PropTypes.func,
  disabled: PropTypes.bool,
  isFullScreen: PropTypes.bool,
  classes: PropTypes.shape({
    root: PropTypes.string,
    fullScreen: PropTypes.string,
    additionalButtons: PropTypes.string,
    fullScreenButton: PropTypes.string,
    submitButtons: PropTypes.string,
    resetButton: PropTypes.string,
    crop: PropTypes.string,
    cropFullScreen: PropTypes.string,
  }),
}
