import React from 'react'
import { Route } from 'react-router-dom'
import { SchemableComponent } from 'components/SchemableComponent'
import { New } from './New'
import { Edit } from './Edit'
import { Collection } from './Collection'
import { addRoutesConfig } from '_helpers/addRoutesConfig'
import schema from '_schema/project'
import { ListAlt } from '@material-ui/icons'
import { Article } from './Article'
import { ArticleNew } from './ArticleNew'
import { ArticleEdit } from './ArticleEdit'

const basePath = '/projects'

const routes = () =>
  addRoutesConfig(
    {
      index: {
        subtitle: 'T_GENERAL_RECORD_LIST',
        type: Route,
        render: props => <Collection {...props} />,
        path: basePath,
        exact: true,
        icon: ListAlt,
      },
      new: {
        subtitle: 'T_GENERAL_RECORD_NEW',
        type: Route,
        render: props => (
          <SchemableComponent path="definitions" component={New} {...props} />
        ),
        path: `${basePath}/new`,
        exact: true,
      },
      edit: {
        subtitle: 'T_GENERAL_RECORD_EDIT',
        type: Route,
        render: props => (
          <SchemableComponent path="definitions" component={Edit} {...props} />
        ),
        path: `${basePath}/:id`,
        exact: true,
      },
      articles: {
        subtitle: 'T_GENERAL_ARTICLES',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={Article}
            {...props}
          />
        ),
        path: `${basePath}/:id/articles`,
        exact: true,
      },
      articleNew: {
        subtitle: 'T_GENERAL_RECORD_NEW',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={ArticleNew}
            {...props}
          />
        ),
        path: `${basePath}/articles/new`,
        exact: true,
      },
      articleEdit: {
        subtitle: 'T_GENERAL_RECORD_EDIT',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={ArticleEdit}
            {...props}
          />
        ),
        path: `${basePath}/articles/:id`,
        exact: true,
      },
    },
    {
      title: 'T_MODULE_PROJECT',
      resourceAccess: schema.access,
      indexPath: basePath,
    }
  )

export default routes
