import fileSchema from './media'

const schema = {
  endpoint: '/api/package_files',
  access: 'packageFile',
  resource: {
    definition: '#PackageFile',
    properties: {
      title: {
        validate: ['required'],
      },
    },
  },
  subresources: {
    item: {
      endpoint: '/api/package_file_items',
      definition: '#PackageFileItem',
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {},
          },
        },
        media: {
          type: 'file',
          description: 'T_GENERAL_FILE',
          endpoint: fileSchema.endpoint,
          validate: ['maxSize'],
        },
      },
    },
  },
}

export default schema
