import {LANGS_IRI} from "../_lib/langs";

const schema = {
  endpoint: '/api/event_locations',
  clone_endpoint: '/api/event_locations/clone',
  access: 'eventLocation',
  resource: {
    definition: '#EventLocation',
    properties: {
      title: {},
      address: {},
      branch: {
        type: 'resource',
        endpoint: `/api/branches?pagination=false`,
        titleAccessor: `translations.${LANGS_IRI.PL}.title`,
        label: 'T_MODULE_EVENT_LOCATION_BRANCH',
        disabled: true,
      },
      branchSubdivision: {
        type: 'resource',
        endpoint: `/api/branch_subdivisions?pagination=false`,
        titleAccessor: `titleWithParent`,
        label: 'T_MODULE_EVENT_LOCATION_BRANCH_SUBDIVISION',
      },
      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
}

export default schema
