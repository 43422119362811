import React from 'react'
import { Form } from 'pages'
import { SidebarNotStatable } from 'components/resource/sidebar'
import schema from '_schema/tender'
import routes from './routes'
import { LANGS_IRI } from '../../_lib/langs'
import { BlockCollection } from '../../components/block'

export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  return (
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      customResourceSchema={schema.resource}
      iri={iri}
      method="PUT"
      url={iri}
      collectionPath={routes().index.path}
      storeCollectionId={schema.endpoint}
      sidebar={true}
      sidebarTitleAccessor={`translations.${LANGS_IRI.PL}.title`}
      SidebarComponent={SidebarNotStatable}
      width="100%"
    >
      <BlockCollection
        endpoint={schema.subresources.block.endpoint}
        pid={match.params.id}
        parentIri={iri}
        definitionSchema={definitions[schema.subresources.block.definition]}
        types={schema.subresources.block.types}
        contextualHint={schema.subresources.block.contextualHint}
        statable={true}
        key={iri}
      />
    </Form>
  )
}
