import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import packageFile from '../packageFile'

export const files = () => ({
  name: BLOCK_TYPES_IRI.FILES,
  label: 'T_GENERAL_BLOCK_TYPE_FILES',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {},
      },
    },
    files: {
      type: 'resource',
      endpoint: `${packageFile.endpoint}?pagination=false`,
      titleAccessor: 'title',
      description: 'Select package files',
    },
  },
})
