const schema = {
  endpoint: '/api/projects',
  access: 'project',
  resource: {
    definition: '#Project',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            type: 'string',
            description: 'T_GENERAL_TITLE',
          },
          description: {
            description: 'Opis',
            type: 'tinymce',
          },
          slug: {
            description: 'Slug',
            type: 'string',
          },
        },
      },
      finished: {
        description: 'T_MODULE_PROJECT_FINISHED',
      },
      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
}

export default schema
