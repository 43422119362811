import React from 'react'
import { Form } from 'pages'
import schema from '_schema/eventSubmission'
import routes from '../EventSubmission/routes'
import { translate } from '../../_helpers/translate'
import { EmbeddedCollection } from '../../components/embedded'

export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  return (
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      customResourceSchema={schema.resource}
      iri={iri}
      method="PUT"
      url={iri}
      collectionPath={routes().index.path}
      storeCollectionId={schema.endpoint}
      sidebar={false}
      width={600}
    >
      <EmbeddedCollection
        endpoint={`${schema.subresources.participants.endpoint}?iri[parent]=${iri}`}
        pid={match.params.id}
        parentIri={iri}
        properties={schema.subresources.participants.properties}
        definitionSchema={
          definitions[schema.subresources.participants.definition]
        }
        headerTitle={translate('T_MODULE_EVENT_SUBMISSION_PARTICIPANTS')}
        panelTitle={translate('T_GENERAL_FULL_NAME')}
        titleAccessor={'fullName'}
        statable={false}
        maxItems={0}
      />
    </Form>
  )
}
