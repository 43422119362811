import adminSchema from '_schema/admin'
import adminGroupSchema from '_schema/adminGroup'
import loginEntry from '_schema/loginEntry'
import registryEntry from '_schema/registryEntry'
import page from '_schema/page'
import translatorEntry from '_schema/translatorEntry'

import annual from '_schema/annual'
import annualSection from '_schema/annualSection'
import annualSectionArticle from '_schema/annualSectionArticle'
import branch from '_schema/branch'
import branchAdditionalOffer from '_schema/branchAdditionalOffer'
import branchAmenity from '_schema/branchAmenity'
import branchAward from '_schema/branchAward'
import branchCategory from '_schema/branchCategory'
import branchOpeningHours from '_schema/branchOpeningHours'
import catalog from '_schema/catalog'
import club from '_schema/club'
import contactPeople from '_schema/contactPeople'
import contest from '_schema/contest'
import contestEdition from '_schema/contestEdition'
import contestEditionSubmission from '_schema/contestEditionSubmission'
import contestPage from '_schema/contestPage'
import contestWinner from '_schema/contestWinner'
import customer from '_schema/customer'
import customerGroup from '_schema/customerGroup'
import event from '_schema/event'
import eventCategory from '_schema/eventCategory'
import eventLocations from '_schema/eventLocations'
import eventRecipients from '_schema/eventRecipients'
import eventSubmission from '_schema/eventSubmission'
import eventSubmissionParticipant from '_schema/eventSubmissionParticipant'
import generalArticle from '_schema/generalArticle'
import homeBanner from '_schema/homeBanner'
import news from '_schema/news'
import newsCategory from '_schema/newsCategory'
import packageFaq from '_schema/packageFaq'
import packageFile from '_schema/packageFile'
import packageGallery from '_schema/packageGallery'
import packageLogotype from '_schema/packageLogotype'
import practicalInformation from '_schema/practicalInformation'
import project from '_schema/project'
import projectArticle from '_schema/projectArticle'
import survey from '_schema/survey'
import surveyEntry from '_schema/surveyEntry'
import surveySubmission from '_schema/surveySubmission'
import tender from '_schema/tender'
import offer from '_schema/offer'

import eLibraryBook from '_schema/eLibraryBook'
import eLibraryBookFile from '_schema/eLibraryBookFile'
import eLibraryBookRequest from '_schema/eLibraryBookRequest'
import mobileAppContactForm from '_schema/mobileAppContactForm'
import mobileAppDeleteAccountRequest from '_schema/mobileAppDeleteAccountRequest'
import mobileAppBranchSubscription from '_schema/mobileAppBranchSubscription'
import mobileAppNotification from '_schema/mobileAppNotification'

const resources = () => ({
  [page.access]: 'Pages',
  [adminSchema.access]: 'Administrators',
  [adminGroupSchema.access]: 'Administrator Groups',
  [loginEntry.access]: 'Login entries',
  [registryEntry.access]: 'Registry entries',
  [translatorEntry.access]: 'Translator entries',
  [annual.access]: 'Roczniki',
  [annualSection.access]: 'Roczniki - sekcje',
  [annualSectionArticle.access]: 'Roczniki - artykuły',
  [branch.access]: 'Filie',
  [branchAdditionalOffer.access]: 'Filie - dodatkowe oferty',
  [branchAmenity.access]: 'Filie - udogodnienia',
  [branchAward.access]: 'Filie - nagrody',
  [branchCategory.access]: 'Filie - kategorie',
  [branchOpeningHours.access]: 'Filie - godziny otwarcia',
  [catalog.access]: 'Katalogi i bazy',
  [club.access]: 'Kluby',
  [contactPeople.access]: 'Kontakt - ludzie',
  [contest.access]: 'Konkursy',
  [contestEdition.access]: 'Konkursy - edycje',
  [contestEditionSubmission.access]: 'Konkursy - zgłoszenia',
  [contestPage.access]: 'Konkursy - podstrony',
  [contestWinner.access]: 'Konkursy - laureaci',
  [customer.access]: 'Klienci',
  [customerGroup.access]: 'Klienci - grupy',
  [event.access]: 'Wydarzenia',
  [eventCategory.access]: 'Wydarzenia - kategorie',
  [eventLocations.access]: 'Wydarzenia - lokacje',
  [eventRecipients.access]: 'Wydarzenia - odbiorcy',
  [eventSubmission.access]: 'Wydarzenia - zgłoszenia',
  [eventSubmissionParticipant.access]: 'Wydarzenia - uczestnicy',
  [generalArticle.access]: 'Ogólne artykuły (Portal)',
  [homeBanner.access]: 'Bannery',
  [news.access]: 'Aktualności',
  [newsCategory.access]: 'Aktualności - kategorie',
  [packageFaq.access]: 'Zestawy FAQ',
  [packageFile.access]: 'Zestawy plików',
  [packageGallery.access]: 'Galerie',
  [packageLogotype.access]: 'Pakiety logotypów',
  [practicalInformation.access]: 'Informacje praktyczne',
  [project.access]: 'Projekty',
  [projectArticle.access]: 'Projekty - artykuły',
  [offer.access]: 'Oferty',
  [survey.access]: 'Ankiety',
  [surveyEntry.access]: 'Ankiety - wpisy',
  [tender.access]: 'Przetargi i zamówienia publiczne',
  [surveySubmission.access]: 'Ankiety - głosy',
  [eLibraryBook.access]: 'E-czytelnia - książki',
  [eLibraryBookFile.access]: 'E-czytelnia - pliki',
  [eLibraryBookRequest.access]: 'E-czytelnia - zgłoszenia',
  [mobileAppContactForm.access]: 'Aplikacja mobilna - formularze kontaktowe',
  [mobileAppDeleteAccountRequest.access]:
    'Aplikacja mobilna - prośby o usunięcie konta',
  [mobileAppBranchSubscription.access]: 'Aplikacja mobilna - subskrypcje filii',
  [mobileAppNotification.access]: 'Aplikacja mobilna - powiadomienia',
})

export default resources
