import React, { useState } from 'react'
import { Paper } from 'components/Paper'
import { Tab, Tabs } from '@material-ui/core'
import { Title } from '../../../components/Title'
import { translate } from '../../../_helpers/translate'
import { ClientOrders } from './ClientOrders'
import { SingleDocument } from './SingleDocument'

export const Home = () => {
  const [tabValue, setTabValue] = useState(0)

  const handleChange = (event, newValue) => {
    setTabValue(newValue)
  }

  return (
    <Paper>
      <Title>{translate('T_MODULE_ELIBRARY_REPORT')}</Title>
      <Tabs
        value={tabValue}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        aria-label="disabled tabs example"
        style={{ marginTop: '20px' }}
      >
        <Tab label="Dokumenty" />
        <Tab label="Klienci" />
      </Tabs>
      {tabValue === 0 && <SingleDocument></SingleDocument>}
      {tabValue === 1 && <ClientOrders></ClientOrders>}
    </Paper>
  )
}
