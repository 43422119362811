const schema = {
  endpoint: '/api/survey_submissions',
  clone_endpoint: '/api/survey_submissions/clone',
  access: 'surveySubmission',
  resource: {
    definition: '#SurveySubmission',
    properties: {
      author: {
        disabled: true,
      },
      title: {
        disabled: true,
      },
      year: {
        disabled: true,
      },
      notes: {
        disabled: true,
        type: 'textarea',
      },
      locationString: {
        label: 'T_MODULE_SURVEY_SUBMISSION_LOCATION',
        disabled: true,
      },
      alternativeLocationString: {
        label: 'T_MODULE_SURVEY_SUBMISSION_ALTERNATIVE_LOCATION',
        disabled: true,
      },
    },
  },
}

export default schema
