export const addOpenformProperties = (isOpenform, properties) => {
  if (!isOpenform) {
    return properties
  }

  return {
    ...properties,
    idName: {
      type: 'string',
      description: {
        text: 'T_GENERAL_OPENFORM_PAGE_IDNAME',
        color: '#cc0000',
      },
    },
  }
}
