const schema = {
  endpoint: '/api/survey_entries',
  clone_endpoint: '/api/survey_entries/clone',
  access: 'surveyEntry',
  resource: {
    definition: '#SurveyEntry',
    properties: {
      title: {},
      author: {},
      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
}

export default schema
