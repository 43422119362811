const schema = {
  endpoint: '/api/event_submission_participants',
  access: 'eventSubmissionParticipant',
  resource: {
    definition: '#EventSubmissionParticipant',
    properties: {
      fullName: {
        disabled: true,
        description: 'Name and surname',
      },
      name: {
        description: 'T_GENERAL_FIRST_NAME',
      },
      surname: {
        description: 'T_GENERAL_SURNAME',
      },
      phone: {
        description: 'T_GENERAL_PHONE_NUMBER',
      },
      age: {
        type: 'number',
        description: 'Wiek',
      },
      email: {},
    },
  },
}

export default schema
