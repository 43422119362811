import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from '@material-ui/core'
import { FileCopy } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { DialogLoader } from 'components/DialogLoader'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'
import { translate } from '_helpers/translate'

const useStyles = makeStyles(theme => ({
  withIcon: {
    textDecoration: 'none',
    cursor: 'pointer',
    color: 'inherit',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  withoutIcon: {
    width: '100%',
    textDecoration: 'none',
    cursor: 'pointer',
    color: 'inherit',
    '&:hover': {
      color: 'inherit',
    },
  },
  disabled: {
    color: theme.palette.disabled,
  },
}))

export const ApiRequest = ({
                        resource,
                        endpoint,
                        disabled = false,
                        onSuccess = null,
                        isIcon = false,
                      }) => {
  const [isFetching, setIsFetching] = useState(false)

  const classes = useStyles()
  const handleClick = () => {
    if (disabled) {
      return
    }

    setIsFetching(true)

    fetchDataHandleAuthError(
      endpoint.replace(':pid',resource['uuid']),
      'PUT',
      {
        body: JSON.stringify({
          iri: resource['@id'],
        }),
      },
      () => {
        setIsFetching(false)

        notification('success', 'T_MODULE_SURVEY_SEND_EMAIL_SUCCESS', 'T_MODULE_SURVEY_SEND_EMAIL_FORM_SUCCESS')
        onSuccess && onSuccess()
      },
      error => {
        setIsFetching(false)

        notification('error', error.response.detail, error.response.title)
      }
    )
  }

  return (
    <>
      {disabled ? (
        isIcon ? (
          <FileCopy className={classes.disabled} disabled />
        ) : (
          <span className={classes.disabled}>
            {translate('T_MODULE_SURVEY_SEND_EMAIL')}
          </span>
        )
      ) : (
        <>
          <div
            onClick={handleClick}
            className={isIcon ? classes.withIcon : classes.withoutIcon}
          >
            {isIcon ? (
              <Tooltip title={translate('T_MODULE_SURVEY_SEND_EMAIL')}>
                <FileCopy />
              </Tooltip>
            ) : (
              translate('T_MODULE_SURVEY_SEND_EMAIL')
            )}
          </div>
        </>
      )}
      <DialogLoader
        isOpen={isFetching}
        title={translate('T_MODULE_SURVEY_SENDING_EMAIL')}
      />
    </>
  )
}

ApiRequest.propTypes = {
  resource: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
  }).isRequired,
  endpoint: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onSuccess: PropTypes.func,
  isIcon: PropTypes.bool,
}
