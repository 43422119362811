import _block from './_block'
import mediaSchema from './media'
import { LANGS_IRI } from '../_lib/langs'

const schema = {
  endpoint: '/api/events',
  clone_endpoint: '/api/events/clone',
  access: 'event',
  resource: {
    definition: '#Event',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            type: 'tinymceTitle',
          },
          description: {
            description: 'Opis',
            label: 'Description',
            type: 'tinymce',
          },
          consent1: {
            type: 'tinymce',
            description: 'Treść zgody 1 (nadpisuje zgodę z konfiguracji)',
          },
          consent2: {
            type: 'tinymce',
            description: 'Treść zgody 2 (nadpisuje zgodę z konfiguracji)',
          },
          consent3: {
            type: 'tinymce',
            description: 'Treść zgody 3 (nadpisuje zgodę z konfiguracji)',
          },
          metaTitle: {},
          metaDescription: {
            type: 'textarea',
          },
          metaKeywords: {
            type: 'textarea',
          },
          alt: {
            description: 'T_GENERAL_ALT',
          },
          slug: {},
        },
      },
      /*
      category: {
        type: 'resource',
        endpoint: `/api/event_categories?pagination=false`,
        titleAccessor: `translations.${LANGS_IRI.PL}.title`,
        label: 'T_MODULE_EVENT_CATEGORY_SELECT',
      },
       */
      categories: {
        type: 'multipleResource',
        endpoint: `/api/event_categories?pagination=false`,
        titleAccessor: `translations.${LANGS_IRI.PL}.title`,
        label: 'T_GENERAL_CATEGORIES',
      },
      recipients: {
        type: 'multipleResource',
        endpoint: `/api/event_recipients?pagination=false`,
        titleAccessor: `translations.${LANGS_IRI.PL}.title`,
        label: 'T_MODULE_EVENT_RECIPIENTS_SELECT',
      },
      location: {
        type: 'resource',
        endpoint: `/api/event_locations?pagination=false`,
        titleAccessor: `title`,
        label: 'T_MODULE_EVENT_LOCATION_SELECT',
        validate: ['required'],
      },
      news: {
        type: 'multipleResource',
        endpoint: `/api/news?pagination=false`,
        titleAccessor: `translations.${LANGS_IRI.PL}.title`,
        label: 'T_GENERAL_LINKED_NEWS',
      },
      events: {
        type: 'multipleResource',
        endpoint: `/api/events_select?pagination=false`,
        titleAccessor: `nativeTitle`,
        label: 'T_GENERAL_LINKED_EVENTS',
      },
      /*
      media: {
        type: 'image',
        description: 'T_GENERAL_PHOTO',
        endpoint: photoSchema.endpoint.single,
        validate: ['maxSize'],
        thumbs: {
          endpoint: '/api/event_thumbs',
          property: 'media',
        },
      },
      mediaThumbType: {
        type: 'resource',
        endpoint: `/api/thumb_types?pagination=false`,
        titleAccessor: `trans`,
        transTitle: true,
        label: 'T_GENERAL_MEDIA_PHOTO_TYPE',
        initialValue: { value: '/api/thumb_types/CROPPED', title: 'Kadr' },
        validate: ['required'],
      },
      hideMediaInDetail: {
        description: 'T_GENERAL_MEDIA_HIDE_IN_DETAIL',
      },
      mediaDetail: {
        type: 'image',
        description: 'T_GENERAL_PHOTO_DETAIL',
        endpoint: photoSchema.endpoint.single,
        validate: ['maxSize'],
        thumbs: {
          endpoint: '/api/event_thumbs',
          property: 'mediaDetail',
        },
      },*/
      dateFrom: {
        type: 'datetime',
        description: 'T_GENERAL_DATE_FROM',
        validate: ['required'],
      },
      dateTo: {
        type: 'datetime',
        description: 'T_GENERAL_DATE_TO',
      },
      maxParticipants: {
        description: 'T_EVENT_MAX_PARTICIPANTS',
        defaultValue: 0,
      },
      submissionNotifyEmail: {
        description: 'Email powiadomień',
        hint:
          'Email do powiadomienia o nowym zgłoszeniu z formularza zapisu na wydarzenie',
        validate: ['email'],
      },
      showHomepage: {},
      formEnabled: {
        description: 'T_EVENT_FORM_ENABLED',
      },
      formAgeEnabled: {
        description: 'T_EVENT_FORM_AGE_ENABLED',
      },
      withAmenities: {
        description: 'T_EVENT_WITH_AMENITIES',
      },
      literarySalon: {
        description: 'T_MODULE_EVENT_CATEGORY_LITERARY_SALON',
      },
      inductionLoop: {
        description: 'Pętla indukcyjna',
      },
      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
  subresources: {
    block: {
      endpoint: '/api/event_blocks',
      definition: '#EventBlock',
      types: _block(
        mediaSchema.endpoint,
        '/api/event_block_thumbs',
        '/api/event_block_multi_sections',
        '/api/event_block_multi_section_thumbs',
        '/api/event_block_branches',
        '/api/event_block_didascalias',
        '/api/event_block_contacts'
      ).types,
    },
    information: {
      endpoint: '/api/event_informations',
      definition: '#EventInformation',
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {
              description: 'T_GENERAL_DESCRIPTION',
            },
          },
        },
      },
    },
  },
}

export default schema
