import packageLogotype from './packageLogotype'
import packageFile from './packageFile'
import photoSchema from './photo'
import pageSchema from './page'
import { LANGS_IRI } from '../_lib/langs'

const schema = {
  endpoint: '/api/config',
  resource: {
    definition: '#Config',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            type: 'string',
            description: 'T_CONFIG_PAGE_TITLE',
          },
          contactAddress: {
            description: 'T_CONFIG_CONTACT_ADDRESS',
            type: 'tinymce',
          },
          suggestPurchaseMedia: {
            type: 'string',
            description: 'T_SECTION_SUGGEST_PURCHASE_MEDIA_ALT',
          },
          eventConsent1: {
            type: 'tinymce',
            description: '[WYDARZENIE] treść zgody 1',
          },
          eventConsent2: {
            type: 'tinymce',
            description: '[WYDARZENIE] treść zgody 2',
          },
          eventConsent3: {
            type: 'tinymce',
            description: '[WYDARZENIE] treść zgody 3',
          },
        },
      },
      additionalHeadCode: {
        type: 'textarea',
      },
      additionalBodyCode: {
        type: 'textarea',
      },
      linkYoutube: {
        description: 'T_LINKS_YOUTUBE',
      },
      linkFacebook: {
        description: 'T_LINKS_FACEBOOK',
      },
      linkInstagram: {
        description: 'T_LINKS_INSTAGRAM',
      },
      emailFrom: {
        description: 'T_CONFIG_EMAIL_SEND_ADDRESS',
      },
      bookCatalog: {
        description: 'T_CONFIG_BOOK_CATALOG_URL',
      },
      headerAccessibilityPage: {
        type: 'resource',
        endpoint: `${pageSchema.endpoint}?pagination=false`,
        titleAccessor: `translations.${LANGS_IRI.PL}.title`,
        description: 'Strona dostępności w nagłówku',
      },
      logotypes: {
        type: 'resource',
        endpoint: `${packageLogotype.endpoint}?pagination=false`,
        titleAccessor: 'title',
        description: 'T_FOOTER_LOGOTYPES_SELECT',
        label: 'T_FOOTER_LOGOTYPES_SELECT',
      },
      suggestPurchaseMedia: {
        type: 'image',
        description: 'T_SECTION_SUGGEST_PURCHASE_MEDIA',
        endpoint: photoSchema.endpoint.single,
        endpointMultiple: photoSchema.endpoint.multiple,
        thumbs: {
          endpoint: '/api/config_thumbs',
        },
        validate: ['maxSize'],
      },
      mobileAppDeleteAccountNotifyEmail: {
        description: '[APLIKACJA] Email powiadomień',
        hint: 'Email do powiadomienia o usunięciu konta w aplikacji mobilnej',
        validate: ['email'],
      },
      mobileAppContactFormNotifyEmail: {
        description: '[APLIKACJA] Email powiadomień',
        hint:
          'Email do powiadomienia o nowym zgłoszeniu z formularza kontaktowego w aplikacji mobilnej',
        validate: ['email'],
      },
      eventSubmissionNotifyEmail: {
        description: '[WYDARZENIA] Email powiadomień',
        hint:
          'Email do powiadomienia o nowym zgłoszeniu z formularza zapisu na wydarzenie',
        validate: ['email'],
      },
      etrFile: {
        type: 'resource',
        endpoint: `${packageFile.endpoint}?pagination=false`,
        titleAccessor: 'title',
        description: 'Zestaw plików ETR',
        label: 'Zestaw plików ETR',
      },
      signLanguagePage: {
        type: 'resource',
        endpoint: `${pageSchema.endpoint}?pagination=false`,
        titleAccessor: `translations.${LANGS_IRI.PL}.title`,
        description: 'Strona migania',
        label: 'Strona migania',
      },
      personIconUrl: {
        description: 'URL ikony ludzika',
      },
    },
  },
}

export default schema
