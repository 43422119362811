import React, { useMemo } from 'react'
import { Paper } from 'components/Paper'
import { CollectionHeader } from 'components/CollectionHeader'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { operations } from 'components/table/_columns/operations'
import { translate } from '_helpers/translate'
import schema from '_schema/eLibraryBook'
import routes from './routes'
import { files } from './_columns/files'
import { requests } from './_columns/requests'

export const Collection = () => {
  const columns = useMemo(
    () => [
      {
        header: 'Identyfikator',
        accessor: `sowaId`,
        filterable: true,
        sortable: true,
        width: '5%',
      },
      {
        header: translate('T_GENERAL_TITLE'),
        accessor: `title`,
        filterable: true,
        sortable: true,
        width: '60%',
      },
      {
        header: 'Limit wypożyczeń',
        accessor: `rentalLimit`,
        width: '5%',
      },
      {
        ...requests(`requestsCount`, 'Zgłoszenia', routes().requests.path),
        width: '5%',
      },
      {
        ...files(
          `filesCount`,
          'T_MODULE_ELIBRARY_BOOK_TABLE_FILES',
          routes().files.path
        ),
        width: '5%',
      },
      {
        ...operations(`title`, true, true, false, true, false),
        width: '10%',
      },
    ],
    []
  )

  return (
    <Paper>
      <CollectionHeader
        buttonTitle={translate('T_MODULE_ELIBRARY_BOOK_NEW_BUTTON')}
        buttonPath={routes().new.path}
      >
        {translate('T_MODULE_ELIBRARY_BOOK')}
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${schema.endpoint}.get`}
        endpoint={schema.endpoint}
        definition={schema.resource.definition}
        columns={columns}
        customResourceSchema={schema.resource}
        defaultSorters={{ createdAt: 'desc' }}
        parameters={[
          {
            name: `title`,
            in: 'query',
            type: 'string',
          },
        ]}
        schemaParameters={[
          {
            name: 'order[createdAt]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[title]',
            in: 'query',
            type: 'string',
          },
        ]}
        orderable={false}
        isManualOrderSorterVisible={false}
        numerable={false}
        paginable={true}
        isRowLinkable={true}
        editPath={routes().edit.path}
        autoWidth={false}
      />
    </Paper>
  )
}
