import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import packageFaq from '../packageFaq'

export const faq = () => ({
  name: BLOCK_TYPES_IRI.FAQ,
  label: 'T_GENERAL_BLOCK_TYPE_FAQ',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {},
        text: {
          description: 'Tekst',
          type: 'tinymce',
        },
      },
    },
    faq: {
      type: 'resource',
      endpoint: `${packageFaq.endpoint}?pagination=false`,
      titleAccessor: 'title',
      description: 'Select package FAQ',
    },
  },
})
