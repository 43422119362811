import React from 'react'
import { Route } from 'react-router-dom'
import { SchemableComponent } from 'components/SchemableComponent'
import { New } from './New'
import { Edit } from './Edit'
import { Collection } from './Collection'
import { addRoutesConfig } from '_helpers/addRoutesConfig'
import schema from '_schema/contest'
import { EmojiEvents } from '@material-ui/icons'
import { PageCollection } from './PageCollection'
import { PageNew } from './PageNew'
import { PageEdit } from './PageEdit'
import { EditionCollection } from './EditionCollection'
import { EditionNew } from './EditionNew'
import { EditionEdit } from './EditionEdit'
import { EditionSubmissionCollection } from './EditionSubmissionCollection'
import { EditionSubmissionEdit } from './EditionSubmissionEdit'
import { EditionSubmissionNew } from './EditionSubmissionNew'
import {EditionWinnerCollection} from "./EditionWinnerCollection";
import {EditionWinnerNew} from "./EditionWinnerNew";
import {EditionWinnerEdit} from "./EditionWinnerEdit";

const basePath = '/contests'

const routes = () =>
  addRoutesConfig(
    {
      index: {
        subtitle: 'T_GENERAL_RECORD_LIST',
        type: Route,
        render: props => <Collection {...props} />,
        path: basePath,
        exact: true,
        icon: EmojiEvents,
      },
      new: {
        subtitle: 'T_GENERAL_RECORD_NEW',
        type: Route,
        render: props => (
          <SchemableComponent path="definitions" component={New} {...props} />
        ),
        path: `${basePath}/new`,
        exact: true,
      },
      edit: {
        subtitle: 'T_GENERAL_RECORD_EDIT',
        type: Route,
        render: props => (
          <SchemableComponent path="definitions" component={Edit} {...props} />
        ),
        path: `${basePath}/:id`,
        exact: true,
      },
      pages: {
        subtitle: 'T_MODULE_CONTEST_PAGE',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={PageCollection}
            {...props}
          />
        ),
        path: `${basePath}/:id/pages`,
        exact: true,
      },
      pageNew: {
        subtitle: 'T_GENERAL_RECORD_NEW',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={PageNew}
            {...props}
          />
        ),
        path: `${basePath}/pages/new`,
        exact: true,
      },
      pageEdit: {
        subtitle: 'T_GENERAL_RECORD_EDIT',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={PageEdit}
            {...props}
          />
        ),
        path: `${basePath}/pages/:id`,
        exact: true,
      },
      editions: {
        subtitle: 'T_MODULE_CONTEST_EDITION',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={EditionCollection}
            {...props}
          />
        ),
        path: `${basePath}/:id/editions`,
        exact: true,
      },
      editionNew: {
        subtitle: 'T_GENERAL_RECORD_NEW',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={EditionNew}
            {...props}
          />
        ),
        path: `${basePath}/editions/new`,
        exact: true,
      },
      editionEdit: {
        subtitle: 'T_GENERAL_RECORD_EDIT',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={EditionEdit}
            {...props}
          />
        ),
        path: `${basePath}/editions/:id`,
        exact: true,
      },
      editionSubmissions: {
        subtitle: 'T_GENERAL_ARTICLES',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={EditionSubmissionCollection}
            {...props}
          />
        ),
        path: `${basePath}/editions/:id/submissions`,
        exact: true,
      },
      editionSubmissionNew: {
        subtitle: 'T_GENERAL_RECORD_NEW',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={EditionSubmissionNew}
            {...props}
          />
        ),
        path: `${basePath}/editions/submissions/new`,
        exact: true,
      },
      editionSubmissionEdit: {
        subtitle: 'T_GENERAL_RECORD_EDIT',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={EditionSubmissionEdit}
            {...props}
          />
        ),
        path: `${basePath}/editions/submissions/:id`,
        exact: true,
      },
      // WINNERS
      editionWinners: {
        subtitle: 'T_MODULE_CONTEST_EDITION_WINNERS',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={EditionWinnerCollection}
            {...props}
          />
        ),
        path: `${basePath}/editions/:id/winners`,
        exact: true,
      },
      editionWinnerNew: {
        subtitle: 'T_GENERAL_RECORD_NEW',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={EditionWinnerNew}
            {...props}
          />
        ),
        path: `${basePath}/editions/winners/new`,
        exact: true,
      },
      editionWinnerEdit: {
        subtitle: 'T_GENERAL_RECORD_EDIT',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={EditionWinnerEdit}
            {...props}
          />
        ),
        path: `${basePath}/editions/winners/:id`,
        exact: true,
      },
    },
    {
      title: 'T_MODULE_CONTEST',
      resourceAccess: schema.access,
      indexPath: basePath,
    }
  )

export default routes
