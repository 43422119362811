import React, { useMemo } from 'react'
import { NotFound } from 'pages'
import { Paper } from 'components/Paper'
import { Loader } from 'components/Loader'
import { EditHeader } from 'components/EditHeader'
import { CollectionHeader } from 'components/CollectionHeader'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { operations } from 'components/table/_columns/operations'
import { useResourceState } from '_helpers/useResourceState'
import { useResourceFetch } from '_helpers/useResourceFetch'
import { translate } from '_helpers/translate'
import { publish } from 'components/table/_mass'
import { MainCell } from './table/cells/GalleryMainCell'
import schema from '_schema/project'
import routes from './routes'
import itemSchema from '_schema/projectArticle'
import itemRoutes from 'pages/Project/routes'
import { LANGS_IRI } from '../../_lib/langs'

export const Article = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const parentIri = useMemo(() => `${schema.endpoint}/${match.params.id}`, [
    match.params.id,
  ])

  const [state, setState] = useResourceState()

  const { resource, isFetching, fetchError } = state

  useResourceFetch(
    parentIri,
    resource,
    setState.isFetching,
    setState.resource,
    setState.fetchError,
    false
  )

  const itemColumns = useMemo(
    () => [
      {
        header: translate('T_GENERAL_TITLE'),
        accessor: `translations.${LANGS_IRI.PL}.title`,
        filterName: 'translations.title',
        filterable: true,
        Cell: MainCell,
        width: '70%',
      },
      {
        ...operations(`translations.${LANGS_IRI.PL}.title`),
        width: '10%',
      },
    ],
    []
  )

  return isFetching ? (
    <Paper>
      <Loader />
    </Paper>
  ) : fetchError ? (
    <NotFound />
  ) : (
    <Paper>
      <CollectionHeader
        buttonTitle={translate('T_GENERAL_ADD_ARTICLE')}
        buttonPath={`${itemRoutes().articleNew.path}?pid=${match.params.id}`}
      >
        <EditHeader resource={resource} editPath={routes().edit.path} />
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${itemSchema.endpoint}.get`}
        endpoint={itemSchema.endpoint}
        definition={itemSchema.resource.definition}
        storeCollectionId={`${itemSchema.endpoint}?iri[parent]=${parentIri}`}
        defaultFilters={{
          'iri[parent]': parentIri,
        }}
        parameters={[
          {
            name: 'iri[parent]',
            in: 'query',
            type: 'string',
            value: false,
          },
        ]}
        columns={itemColumns}
        customResourceSchema={itemSchema.resource}
        defaultSorters={{ ord: 'desc' }}
        orderable={true}
        selectable={[publish.on(), publish.off()]}
        isRowLinkable={true}
        editPath={itemRoutes().articleEdit.path}
        autoWidth={false}
      />
    </Paper>
  )
}
