import React, { useMemo } from 'react'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { translate } from '_helpers/translate'
import schema from '_schema/eLibraryBookRequest'
import { StringFilter } from '../../../components/table/filters/StringFilter'
import { Button } from '@material-ui/core'
import { GetAppOutlined } from '@material-ui/icons'
import { authHeader } from '../../../_helpers/authHeader'
import { notification } from '../../../_helpers/notification'

export const ClientOrders = () => {
  const columns = useMemo(
    () => [
      {
        header: translate('Nazwa klienta'),
        accessor: `fullName`,
        filterable: true,
        sortable: true,
        width: '20%',
      },
      {
        header: translate('Email'),
        accessor: `email`,
        filterable: true,
        sortable: true,
        width: '20%',
      },
      {
        header: translate('Liczba wypożyczeń'),
        accessor: `count`,
        width: '10%',
      },
    ],
    []
  )

  const [isFetching, setIsFetching] = React.useState(false)

  const downloadClick = () => {
    setIsFetching(true)
    let url = `${process.env.REACT_APP_BACKEND_ENTRYPOINT}/api/e_library_reports/download/clients`
    let xtitle = null
    fetch(url, {
      method: 'GET',
      headers: {
        ...authHeader(),
      },
    })
      .then(response => {
        if (response.status !== 200) {
          throw new Error('Error')
        }
        xtitle = response.headers.get('X-Title')
        return response.blob()
      })
      .then(blob => {
        setIsFetching(false)
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', xtitle ?? 'raport_klientow.xlsx')

        // Append to html link element page
        document.body.appendChild(link)

        // Start download
        link.click()

        // Clean up and remove the link
        link.parentNode.removeChild(link)
      })
      .catch(e => {
        setIsFetching(false)
        notification('error', 'Error, nie mozna pobrać', e)
      })
  }

  const today = new Date().toISOString().slice(0, 10)

  return (
    <>
      <div
        style={{ display: 'flex', justifyContent: 'end', marginBottom: '20px' }}
      >
        <Button
          color="primary"
          variant="contained"
          disabled={isFetching}
          onClick={downloadClick}
        >
          Pobierz obecne wyniki{' '}
          <GetAppOutlined style={{ marginLeft: '5px' }}></GetAppOutlined>
        </Button>
      </div>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${schema.endpoint}.get`}
        endpoint={'/api/customer_reports'}
        definition={schema.resource.definition}
        columns={columns}
        customResourceSchema={schema.resource}
        defaultSorters={{ ord: 'desc' }}
        orderable={false}
        isRowLinkable={false}
        numerable={false}
        autoWidth={false}
        horizontalFilterBar={true}
        paginable={true}
        customFilters={[
          {
            name: 'startDate',
            title: 'Data rozpoczęcia',
            Filter: StringFilter,
            value: today,
          },
          {
            name: 'endDate',
            title: 'Data zakończenia',
            Filter: StringFilter,
            value: today,
          },
        ]}
        parameters={[
          {
            name: 'startDate',
            in: 'query',
            type: 'string',
          },
          {
            name: 'endDate',
            in: 'query',
            type: 'string',
          },
        ]}
      />
    </>
  )
}
