import { makeStyles, alpha } from '@material-ui/core/styles'

export const useCollectionTableStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  row: {
    '&:nth-child(odd)': {
      backgroundColor: theme.elements.table.odd,
    },
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
    },
  },
  rowAsLink: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
    },
  },
  cell: {},
  emptyResults: {
    textAlign: 'center',
  },
  manualOrderContainer: {
    color: theme.palette.text.secondary,
    fontSize: '0.75rem',
    fontFamily: theme.typography.fontFamily,
    margin: 8,
    fontWeight: 400,
    lineHeight: 1.66,
    letterSpacing: '0.03333em',
    textAlign: 'left',
  },
  manualOrderContainerTitle: {
    marginRight: 8,
  },
  draggedRow: {
    display: 'block',
    background: theme.elements.table.odd,
    '& td': {
      borderBottom: 'none',
    },
  },
  massHeadColumn: {
    display: 'flex',
  },
  massHeadColumnIsOrderable: {
    marginLeft: 24,
  },
  massColumn: {
    display: 'flex',
    alignItems: 'center',
  },
  tHead: {
    backgroundColor: theme.elements.table.tHead,
    '& .MuiTableCell-head': {
      color: theme.elements.table.tHeadC,
    },
  },
  tHeadTransparent: {
    MozAnimationTimingFunction: {
      fontWeight: 700,
    },
  },
}))
