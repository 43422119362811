import fileSchema from './protectedMedia'

const schema = {
  endpoint: '/api/e_library_book_files',
  access: 'eLibraryBookFile',
  resource: {
    definition: '#ELibraryBookFile',
    properties: {
      title: {},
      type: {
        type: 'resource',
        endpoint: `/api/e_library_book_file_types?pagination=false`,
        titleAccessor: `value`,
        label: 'T_MODULE_ELIBRARY_REQUEST_FILE_TYPE',
        transTitle: true,
        validate: ['required'],
      },
      licence: {
        type: 'textarea',
      },
      media: {
        type: 'file',
        description: 'T_GENERAL_FILE',
        endpoint: fileSchema.endpoint,
        validate: ['maxSize'],
      },
      networkLimitAccess: {
        description: 'T_MODULE_ELIBRARY_BOOK_FILE_LIMITED_ACCESS',
      },
      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
}

export default schema
