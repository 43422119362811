const schema = {
  endpoint: '/api/branch_subdivisions',
  resource: {
    definition: '#BranchSubdivision',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
        },
      },
      appId: {
        description: 'Identyfikator',
      },
      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
}

export default schema
