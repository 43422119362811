import React, { useState, useEffect, useCallback, useReducer } from 'react'
import { v4 as uuid } from 'uuid'
import { AddCircleOutline, DeleteForeverOutlined } from '@material-ui/icons'
import {
  FormControl,
  InputLabel,
  Button,
  FormHelperText,
  Select,
  MenuItem,
} from '@material-ui/core'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'

import { makeStyles } from '@material-ui/styles'
import { TextField } from '@material-ui/core'
import { translate } from '_helpers/translate'
import { validate } from '_helpers/validate'
import { LANGS_IRI } from '../../../_lib/langs'

const useStyles = makeStyles({
  formControl: {
    minWidth: 200,
  },
  formControllFullWidth: {
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    columnGap: 20,
    marginBottom: 30,
  },
  btnAdd: {
    justifySelf: 'flex-end',
  },
  list: {
    display: 'grid',
  },
  textFieldsGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3,1fr)',
    gap: 10,
  },
})

const listReducer = (state, action) => {
  switch (action.type) {
    case 'REMOVE_ITEM':
      return {
        ...state,
        list: state.list.filter(item => item !== action.id),
      }
    case 'ADD_ITEM':
      return {
        ...state,
        list: [...state.list, { hash: uuid() }],
      }
    default:
      throw new Error()
  }
}

export const CustomBlockBranches = ({
  name,
  initialValue,
  value,
  btnText = null,
  endpoint,
  disabled = false,
  readOnly = false,
  error = false,
  renderError = false,
  validators,
  setValue,
  setError,
  hint = null,
}) => {
  const classes = useStyles()

  const [choices, setChoices] = useState([])

  useEffect(() => {
    if (!endpoint) {
      return
    }
    const controller = new AbortController()
    const { signal } = controller

    fetchDataHandleAuthError(
      endpoint,
      'GET',
      { signal },
      response => {
        setChoices(response['hydra:member'])
      },
      error => {
        if (error.response.title === 'AbortError') {
          return
        }
        notification('error', error.response.detail, error.response.title)
      }
    )
    return () => controller.abort()
  }, [endpoint, setChoices])

  const [listData, dispatchListData] = useReducer(listReducer, {
    list: initialValue || [],
  })

  const handleChange = (itemValue, index, itemName) => {
    setValue(
      name,
      Object.assign([], value, {
        [index]: {
          ...value[index],
          [itemName]: itemValue,
        },
      })
    )
    validateField(value)
  }

  const handleRemove = (id, index) => {
    setValue(
      name,
      value.filter((item, vindex) => vindex !== index)
    )
    dispatchListData({ type: 'REMOVE_ITEM', id })
  }

  const handleAdd = () => {
    dispatchListData({ type: 'ADD_ITEM' })
  }

  const validateField = useCallback(
    value => {
      if (!validators) {
        setError(name, false)

        return
      }

      const valid = validate(validators, value)

      setError(name, !valid.result && valid.message)
    },
    [validators, setError, name]
  )

  useEffect(() => {
    validateField(initialValue)
  }, [validateField, initialValue])

  useEffect(() => {
    if (value === null && !initialValue) {
      setValue(name, [], false)
    }
  }, [setValue, name, value, initialValue])

  return (
    <div className={classes.list}>
      <FormControl error={renderError && !!error}>
        <FormHelperText>
          {translate(renderError && error ? error : hint)}
        </FormHelperText>
      </FormControl>
      {listData.list.map((item, index) => (
        <div key={item.hash} className={classes.wrapper}>
          <div className={classes.textFieldsGrid}>
            <TextField
              name={'description'}
              onChange={event =>
                handleChange(event.target.value, index, 'description')
              }
              value={value?.[index]?.description}
              variant="outlined"
              disabled={disabled}
              size="medium"
              label={translate('T_GENERAL_DESCRIPTION')}
            />
            {choices.length > 0 && (
              <FormControl size="small">
                <InputLabel variant="outlined">
                  {translate('T_BRANCH')}
                </InputLabel>
                <Select
                  name={'branch'}
                  onChange={event =>
                    handleChange(event.target.value, index, 'branch')
                  }
                  value={value?.[index]?.branch?.['@id']}
                  variant="outlined"
                  disabled={disabled}
                  label="branch"
                  defaultValue=""
                >
                  {choices.map(item => {
                    return (
                      <MenuItem
                        key={item}
                        value={item['@id']}
                        disabled={disabled}
                      >
                        {item.translations[LANGS_IRI.PL].title}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            )}
          </div>

          <Button
            variant="contained"
            color="secondary"
            startIcon={<DeleteForeverOutlined />}
            onClick={() => handleRemove(item, index)}
            size="small"
            disabled={disabled}
          >
            {translate('T_GENERAL_DELETE')}
          </Button>
        </div>
      ))}
      {!readOnly && (
        <Button
          variant="outlined"
          color="primary"
          startIcon={<AddCircleOutline />}
          onClick={() => handleAdd()}
          size="small"
          className={classes.btnAdd}
          disabled={disabled}
        >
          {translate(btnText)}
        </Button>
      )}
    </div>
  )
}
