import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Chip } from '@material-ui/core'
import { translate } from '_helpers/translate'

export const ModuleCell = ({ resource }) => {
  const schema = useSelector(state => state.schema)

  const description =
    schema.definitions[`#${resource.resourceClass}`]?.['hydra:description']

  return description ? (
    <Chip label={translate(description)} color="primary" />
  ) : null
}

ModuleCell.propTypes = {
  resource: PropTypes.shape({
    requestMethod: PropTypes.string.isRequired,
  }).isRequired,
  accessor: PropTypes.string,
}
