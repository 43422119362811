import { StatusCell } from 'components/table/cells/StatusCell'

export const status = (
  accessor = 'stat',
  sortable = true,
  filterable = true
) => ({
  accessor,
  sortable,
  filterable,
  Cell: StatusCell,
})
