import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import {
  Dialog,
  Tooltip,
  DialogContent,
  IconButton,
  Typography,
} from '@material-ui/core'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles, withStyles } from '@material-ui/styles'
import { Help, Close } from '@material-ui/icons'
import { translate } from '_helpers/translate'
import { contextualHints } from 'contextualHints'

const useStyles = makeStyles({
  icon: {
    cursor: 'pointer',
  },
})
const styles = () => ({
  root: {
    minHeight: 56,
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 4,
  },
})

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h4">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <Close />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

export const ContextualHint = ({ title = null, name }) => {
  if (!contextualHints[name]) {
    throw new Error(`Contextual hint does not exist, name: ${name}`)
  }

  const [isOpen, setIsOpen] = useState(false)

  const handleIsOpen = () => {
    setIsOpen(state => !state)
  }

  const render = useSelector(state => state.profile?.isContextualHintsVisible)

  const classes = useStyles()

  return render ? (
    <>
      <Tooltip
        title={translate('T_GENERAL_CONTEXTUAL_HINT')}
        className={classes.icon}
      >
        <Help onClick={handleIsOpen} />
      </Tooltip>
      <Dialog open={isOpen} onClose={handleIsOpen} maxWidth="md">
        <DialogTitle onClose={handleIsOpen}>
          {translate('T_GENERAL_CONTEXTUAL_HINT')} {title && title}
        </DialogTitle>
        <DialogContent dividers>
          <iframe
            title={translate('T_GENERAL_CONTEXTUAL_HINT')}
            src={`${window.location.origin}/${process.env.REACT_APP_CONTEXTUAL_HINTS_PATH}${contextualHints[name]}.html`}
            width={800}
            height={600}
            frameBorder={0}
          ></iframe>
        </DialogContent>
      </Dialog>
    </>
  ) : null
}

ContextualHint.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string.isRequired,
}
