import photoSchema from './photo'

const schema = {
  endpoint: '/api/annuals',
  access: 'annual',
  resource: {
    definition: '#Annual',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            type: 'string',
            description: 'T_GENERAL_TITLE',
          },
          alt: {
            type: 'string',
            description: 'T_GENERAL_ALT',
          },
          slug: {
            description: 'Slug',
            type: 'string',
          },
        },
      },
      year: {
        validate: ['required'],
        label: 'T_MODULE_ANNUAL_YEAR_LABEL',
      },
      file: {
        type: 'file',
        description: 'Plik pdf',
        endpoint: photoSchema.endpoint.single,
        validate: ['maxSize'],
      },
      media: {
        type: 'image',
        description: 'T_GENERAL_PHOTO',
        endpoint: photoSchema.endpoint.single,
        validate: ['maxSize'],
        thumbs: {
          endpoint: '/api/annual_thumbs',
        },
      },
      mediaThumbType: {
        type: 'resource',
        endpoint: `/api/thumb_types?pagination=false`,
        titleAccessor: `trans`,
        transTitle: true,
        label: 'T_GENERAL_MEDIA_PHOTO_TYPE',
        initialValue: { value: '/api/thumb_types/CROPPED', title: 'Kadr' },
        validate: ['required'],
      },
      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
}

export default schema
