import { LANGS_IRI } from '../_lib/langs'
import newsSchema from './news'
import branchAccessibilitySchema from './branchAccessibility'
import branchAmenitySchema from './branchAmenity'
import branchCategorySchema from './branchCategory'
import branchAwardSchema from './branchAward'
import branchAdditionalOfferSchema from './branchAdditionalOffer'

const schema = {
  endpoint: '/api/branches',
  clone_endpoint: '/api/branches/clone',
  access: 'branch',
  resource: {
    definition: '#Branch',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
          description: {
            type: 'tinymce',
          },
          directions: {
            type: 'tinymce',
          },
          additionalInformation: {
            type: 'tinymce',
          },
          alertTitle: {
            description: 'Tytuł komunikatu',
          },
          alertDescription: {
            type: 'textarea',
            description: 'Treść komunikatu',
          },
          metaTitle: {},
          metaDescription: {
            type: 'textarea',
          },
          metaKeywords: {
            type: 'textarea',
          },
          slug: {},
        },
      },
      categories: {
        type: 'multipleResource',
        endpoint: `${branchCategorySchema.endpoint}?pagination=false`,
        titleAccessor: `translations.${LANGS_IRI.PL}.title`,
        label: 'T_BRANCH_CATEGORIES',
      },
      additionalOffers: {
        type: 'multipleResource',
        endpoint: `${branchAdditionalOfferSchema.endpoint}?pagination=false`,
        titleAccessor: `translations.${LANGS_IRI.PL}.title`,
        label: 'Additional offers',
      },
      amenities: {
        type: 'multipleResource',
        endpoint: `${branchAmenitySchema.endpoint}?pagination=false`,
        titleAccessor: `translations.${LANGS_IRI.PL}.title`,
        label: 'Amenities',
      },
      accessibilities: {
        type: 'multipleResource',
        endpoint: `${branchAccessibilitySchema.endpoint}?pagination=false`,
        titleAccessor: `translations.${LANGS_IRI.PL}.title`,
        label: 'Accessibilities',
      },
      awards: {
        type: 'multipleResource',
        endpoint: `${branchAwardSchema.endpoint}?pagination=false`,
        titleAccessor: `translations.${LANGS_IRI.PL}.title`,
        label: 'Branch awards',
      },
      /*
      openingHoursNews: {
        type: 'resource',
        endpoint: `${newsCategorySchema.endpoint}?pagination=false`,
        titleAccessor: `translations.${LANGS_IRI.PL}.title`,
      },
       */
      openingHoursNews: {
        type: 'autoComplete',
        titleAccessor: `nativeTitle`,
        endpoint: newsSchema.endpoint,
        placeholder: 'T_GENERAL_AUTOCOMPLETE_PLACEHOLDER',
        label: 'Opening hours news',
      },
      address: {},
      contactManager: {},
      markerColor: {},
      map: {
        type: 'map',
        label: 'Mapa',
      },
      eLibrary: {
        description: 'T_MODULE_BRANCH_ELIBRARY',
      },
      stat: {},
    },
  },
  subresources: {
    openingHours: {
      endpoint: '/api/branch_opening_hours',
      definition: '#BranchOpeningHours',
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {},
            description: {
              type: 'tinymce',
            },
          },
        },
      },
    },
    contacts: {
      endpoint: '/api/branch_contacts',
      definition: '#BranchContact',
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {
              type: 'string',
              description: 'T_GENERAL_TITLE',
            },
          },
        },
        phone: {},
        email: {},
      },
    },
  },
}

export default schema
