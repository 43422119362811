import React from 'react'
import { Form } from 'pages'
import schema from '_schema/branchAccessibility'
import routes from './routes'
import { addOpenformProperties } from '../Offers/_helpers/addOpenformProperties'
import { useSelector } from 'react-redux'

export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`
  const profile = useSelector(state => state.profile)

  return (
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      customResourceSchema={schema.resource}
      iri={iri}
      method="PUT"
      url={iri}
      collectionPath={routes().index.path}
      storeCollectionId={schema.endpoint}
      sidebar={true}
      width={600}
      modifyProperties={properties =>
        addOpenformProperties(profile.group?.isOpenform, properties)
      }
    ></Form>
  )
}
