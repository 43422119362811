import React from 'react'
import { Route } from 'react-router-dom'
import { Home } from './Home'
import { addRoutesConfig } from '_helpers/addRoutesConfig'
import schema from '_schema/eLibraryBookRequest'
import { AssessmentOutlined } from '@material-ui/icons'

const basePath = '/elibrary_reports'

const routes = () =>
  addRoutesConfig(
    {
      index: {
        subtitle: 'T_GENERAL_RECORD_LIST',
        type: Route,
        render: props => <Home {...props} />,
        path: basePath,
        exact: true,
        icon: AssessmentOutlined,
      },
    },
    {
      title: 'T_MODULE_ELIBRARY_REPORT',
      resourceAccess: schema.access,
      indexPath: basePath,
    }
  )

export default routes
