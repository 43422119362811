import React, { useEffect, useState } from 'react'
import schema from '_schema/contestEdition'
import routes from './routes'
import { Form } from '../Form'
import { LANGS_IRI } from '../../_lib/langs'
import { SidebarNotStatable } from '../../components/resource/sidebar'
import { BlockCollection } from '../../components/block'
import { createBreadcrumbs } from '_helpers/createBreadcrumbs'
import { useDispatch } from 'react-redux'
import { prop } from '../../_helpers/prop'

export const EditionEdit = ({ schema: definitions, location, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  const collectionPath = function(resource) {
    return routes().editions.path.replace(':id', resource.parent?.uuid)
  }

  const [parentResource, setParentResource] = useState(null)

  const dispatch = useDispatch()

  useEffect(() => {
    createBreadcrumbs(
      dispatch,
      location,
      parentResource
        ? prop(parentResource, `translations.${LANGS_IRI.PL}.title`)
        : '',
      [
        {
          path: routes().index.path,
          title: `Konkursy(${
            parentResource
              ? prop(
                  parentResource.parent,
                  `translations.${LANGS_IRI.PL}.title`
                )
              : ''
          })`,
        },
        {
          path: routes().editions.path.replace(
            ':id',
            parentResource?.parent?.uuid
          ),
          title: `Edycje`,
        },
        /*
      {
        path: routes().editions.path.replace(
          ':id',
          parentResource?.parent?.uuid
        ),
        title: `Edycja(${
          parentResource
            ? prop(parentResource, `translations.${LANGS_IRI.PL}.title`)
            : ''
        })`,
      },*/
      ]
    )
  }, [dispatch, parentResource, location])

  return (
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      customResourceSchema={schema.resource}
      iri={iri}
      method="PUT"
      url={iri}
      collectionPath={collectionPath}
      storeCollectionId={schema.endpoint}
      sidebar={true}
      sidebarTitleAccessor={`translations.${LANGS_IRI.PL}.title`}
      SidebarComponent={SidebarNotStatable}
      width="100%"
      setParentResource={setParentResource}
    >
      <BlockCollection
        endpoint={schema.subresources.block.endpoint}
        pid={match.params.id}
        parentIri={iri}
        definitionSchema={definitions[schema.subresources.block.definition]}
        types={schema.subresources.block.types}
        contextualHint={schema.subresources.block.contextualHint}
        statable={true}
        key={iri}
      />
    </Form>
  )
}
