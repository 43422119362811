import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import randomHash from 'random-hash'
import clsx from 'clsx'
import { TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { validate } from '_helpers/validate'
import { translate } from '_helpers/translate'

const useStyles = makeStyles(theme => ({
  compare: {
    marginTop: 5,
  },
  compareNeq: {
    color: theme.palette.error.main,
  },
}))

export const DateTimeType = ({
  name,
  label,
  hint = null,
  initialValue,
  value,
  compareValue = null,
  compare = false,
  error = false,
  renderError = false,
  disabled = false,
  validators,
  setValue,
  setError,
  fullWidth = false,
}) => {
  const [id] = useState(randomHash())

  const handleChange = e => {
    const value = e.target.value
    setValue(name, value)
    validateField(value)
  }

  const validateField = useCallback(
    value => {
      if (!validators) {
        setError(name, false)

        return
      }

      const valid = validate(validators, value)

      setError(name, !valid.result && valid.message)
    },
    [validators, setError, name]
  )

  useEffect(() => {
    validateField(initialValue)
  }, [validateField, initialValue])

  const classes = useStyles()

  const adjustDateFormat = inputDate => {
    if (!inputDate) {
      return ''
    }

    inputDate = inputDate.replace(' ', 'T')

    const parts = inputDate.split('T') // Split the date and time parts
    const datePart = parts[0]
    const timePart = parts[1].split(':')[0] + ':' + parts[1].split(':')[1] // Keep only hours and minutes

    return datePart + 'T' + timePart
  }

  return (
    <>
      <TextField
        id={id}
        name={name}
        type="datetime-local"
        label={
          translate(label.text || label) +
          (validators && validators.includes('required') ? ' *' : '')
        }
        value={adjustDateFormat(value)}
        disabled={disabled}
        onChange={handleChange}
        error={renderError && !!error}
        helperText={translate(renderError && error ? error : hint)}
        InputLabelProps={
          label.color
            ? {
                style: {
                  color: label.color,
                },
                shrink: true,
              }
            : {
                shrink: true,
              }
        }
        fullWidth={fullWidth}
        variant="standard"
      />
      {compare && (
        <div
          className={clsx(
            classes.compare,
            value !== compareValue && classes.compareNeq
          )}
        >
          {compareValue}
        </div>
      )}
    </>
  )
}

DateTimeType.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ]).isRequired,
  hint: PropTypes.string,
  initialValue: PropTypes.string,
  value: PropTypes.string,
  compareValue: PropTypes.string,
  compare: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  renderError: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  validators: PropTypes.arrayOf(PropTypes.string),
  setValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
}
