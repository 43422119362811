import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { prop } from '_helpers/prop'
import { Chip } from '@material-ui/core'

const FORMAT = 'YYYY-MM-DD HH:mm:ss'

export const RentDatetimeCell = ({
  resource,
  accessor,
  isRowLinkable = false,
  editPath = null,
}) => {
  const history = useHistory()

  const onClick = () => {
    if (isRowLinkable) {
      editPath && history.push(editPath.replace(':id', resource.uuid))
    }
  }

  const value = prop(resource, accessor)

  if (!value) {
    return <Chip label="brak" />
  }

  return (
    <div onClick={onClick}>
      {value && moment(new Date(value)).format(FORMAT)}
    </div>
  )
}

RentDatetimeCell.propTypes = {
  resource: PropTypes.shape({
    uuid: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    '@id': PropTypes.string.isRequired,
  }).isRequired,
  accessor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  isRowLinkable: PropTypes.bool,
  editPath: PropTypes.string,
}
