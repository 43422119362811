import React from 'react'
import { Form } from 'pages'
import { SidebarNotStatable } from 'components/resource/sidebar'
import schema from '_schema/branch'
import routes from './routes'
import { LANGS_IRI } from '../../_lib/langs'
import { translate } from '../../_helpers/translate'
import { EmbeddedCollection } from '../../components/embedded'

export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  return (
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      customResourceSchema={schema.resource}
      iri={iri}
      method="PUT"
      url={iri}
      collectionPath={routes().index.path}
      storeCollectionId={schema.endpoint}
      sidebar={true}
      sidebarTitleAccessor={`translations.${LANGS_IRI.PL}.title`}
      SidebarComponent={SidebarNotStatable}
      width="100%"
    >
      <EmbeddedCollection
        endpoint={`${schema.subresources.openingHours.endpoint}?iri[parent]=${iri}`}
        pid={match.params.id}
        parentIri={iri}
        properties={schema.subresources.openingHours.properties}
        definitionSchema={
          definitions[schema.subresources.openingHours.definition]
        }
        headerTitle={translate('T_MODULE_BRANCH_OPENING_HOURS_TITLE')}
        panelTitle={translate('T_GENERAL_TITLE')}
        titleAccessor={'translations./api/languages/PL.title'}
        statable={true}
        maxItems={4}
      />
      <div style={{ marginTop: '20px' }}>
        <EmbeddedCollection
          endpoint={`${schema.subresources.contacts.endpoint}?iri[parent]=${iri}`}
          pid={match.params.id}
          parentIri={iri}
          properties={schema.subresources.contacts.properties}
          definitionSchema={
            definitions[schema.subresources.contacts.definition]
          }
          headerTitle={translate('T_MODULE_BRANCH_CONTACTS_TITLE')}
          panelTitle={translate('T_GENERAL_TITLE')}
          titleAccessor={'translations./api/languages/PL.title'}
          statable={true}
          maxItems={5}
        />
      </div>
    </Form>
  )
}
