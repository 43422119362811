import React, { useMemo } from 'react'
import { NotFound } from 'pages'
import { Paper } from 'components/Paper'
import { Loader } from 'components/Loader'
import { EditHeader } from 'components/EditHeader'
import { CollectionHeader } from 'components/CollectionHeader'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { ResourceMediaAddMultipleDialog } from 'components/ResourceMediaAddMultipleDialog'
import { operations } from 'components/table/_columns/operations'
import { applyProperties } from '_helpers/applyProperties'
import { useResourceState } from '_helpers/useResourceState'
import { useResourceFetch } from '_helpers/useResourceFetch'
import { translate } from '_helpers/translate'
import { publish } from 'components/table/_mass'
import { MainCell } from './table/cells/MainCell'
import schema from '_schema/packageLogotype'
import routes from './routes'
import itemSchema from '_schema/packageLogotypeItem'
import itemRoutes from 'pages/PackageLogotypeItems/routes'
import { LANGS_IRI } from '../../_lib/langs'

export const Show = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const parentIri = useMemo(() => `${schema.endpoint}/${match.params.id}`, [
    match.params.id,
  ])

  const [state, setState] = useResourceState()

  const { resource, isFetching, fetchError } = state

  useResourceFetch(
    parentIri,
    resource,
    setState.isFetching,
    setState.resource,
    setState.fetchError,
    false
  )

  const itemColumns = useMemo(
    () => [
      {
        header: translate('T_GENERAL_TITLE'),
        accessor: `translations.${LANGS_IRI.PL}.title`,
        filterName: 'translations.title',
        filterable: true,
        Cell: MainCell,
        width: '65%',
      },
      {
        ...operations(`translations.${LANGS_IRI.PL}.title`),
        width: '15%',
      },
    ],
    []
  )

  const itemMassProperties = useMemo(
    () =>
      applyProperties(
        {
          translations: itemSchema.resource.properties.translations,
          stat: itemSchema.resource.properties.stat,
        },
        definitions[itemSchema.resource.definition].properties,
        'missing'
      ),
    [definitions]
  )

  return isFetching ? (
    <Paper>
      <Loader />
    </Paper>
  ) : fetchError ? (
    <NotFound />
  ) : (
    <Paper>
      <CollectionHeader
        buttonTitle={translate('T_MODULE_PACKAGE_GALLERY_ITEMS_NEW_BUTTON')}
        buttonPath={`${itemRoutes().new.path}?pid=${match.params.id}`}
        additionalButtons={
          <ResourceMediaAddMultipleDialog
            title={translate(
              'T_MODULE_PACKAGE_GALLERY_ITEMS_NEW_MULTIPLE_BUTTON'
            )}
            parentIri={parentIri}
            fileEndpoint={itemSchema.resource.properties.media.endpointMultiple}
            itemEndpoint={itemSchema.endpoint}
            itemProperties={itemMassProperties}
          />
        }
      >
        <EditHeader resource={resource} editPath={routes().edit.path} />
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${itemSchema.endpoint}.get`}
        endpoint={itemSchema.endpoint}
        definition={itemSchema.resource.definition}
        storeCollectionId={`${itemSchema.endpoint}?iri[parent]=${parentIri}`}
        defaultFilters={{
          'iri[parent]': parentIri,
        }}
        parameters={[
          {
            name: 'iri[parent]',
            in: 'query',
            type: 'string',
            value: false,
          },
        ]}
        columns={itemColumns}
        customResourceSchema={itemSchema.resource}
        defaultSorters={{ ord: 'desc' }}
        orderable={true}
        selectable={[publish.on(), publish.off()]}
        isRowLinkable={true}
        editPath={itemRoutes().edit.path}
        autoWidth={false}
      />
    </Paper>
  )
}
