const schema = {
  endpoint: '/api/customers',
  access: 'customer',
  resource: {
    definition: '#Customer',
    properties: {
      firstName: {
        type: 'string',
        description: 'Imię',
      },
      lastName: {
        type: 'string',
        description: 'Nazwisko',
      },
      email: {},
      rawPassword: {},
      groups: {
        type: 'multipleResource',
        endpoint: `/api/customer_groups?pagination=false`,
        titleAccessor: `title`,
        label: 'Grupy odbiorców',
      },
      libraryCard: {
        type: 'string',
        description: 'Numer karty bibliotecznej',
      },
      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
}

export default schema
