import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { SystemUpdateAlt } from '@material-ui/icons'
import { Button, Dialog, DialogContent, DialogActions } from '@material-ui/core'
import { DialogTitleWithClose } from 'components/DialogTitleWithClose'
import { authHeader } from '../../../_helpers/authHeader'
import { notification } from '../../../_helpers/notification'

const useStyles = makeStyles({
  content: {
    textAlign: 'left',
  },
})

export const ExportModal = ({ title, classes = {} }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const handleDialogToggle = useCallback(() => {
    setIsDialogOpen(state => !state)
  }, [])

  const [isFetching, setIsFetching] = useState(false)

  const defaultClasses = useStyles()

  let xTitle = null
  let responseError = null

  const handleExportClick = () => {
    setIsFetching(true)
    fetch(
      `${process.env.REACT_APP_BACKEND_ENTRYPOINT}/api/survey_submission_export`,
      {
        method: 'POST',
        headers: {
          ...authHeader(),
        },
      }
    )
      .then(response => {
        if (response.status !== 200) {
          responseError = response.json()
          throw new Error('Error')
        }
        xTitle = response.headers.get('x-title')
        setIsFetching(false)
        return response.blob()
      })
      .then(blob => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', xTitle ?? 'zgloszenia.xls')

        // Append to html link element page
        document.body.appendChild(link)

        // Start download
        link.click()

        // Clean up and remove the link
        link.parentNode.removeChild(link)
        setIsFetching(false)

        notification('success', 'Pomyślnie dokonano exportu', 'T_FORM_SUCCESS')
      })
      .catch(e => {
        notification('error', 'Wystąpił błąd podczas eksportu')
        setIsFetching(false)

        responseError.then(data => {
          notification('error', data.error)
        })
      })
  }

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        startIcon={<SystemUpdateAlt />}
        onClick={handleExportClick}
        disabled={isFetching}
      >
        Eksportuj
      </Button>
      <Dialog maxWidth="md" onClose={handleDialogToggle} open={isDialogOpen}>
        <DialogTitleWithClose onClose={handleDialogToggle}>
          {title}
        </DialogTitleWithClose>
        <DialogContent
          className={clsx(defaultClasses.content, classes.content)}
        >
          <Button
            variant="contained"
            color="primary"
            startIcon={<SystemUpdateAlt />}
            onClick={handleExportClick}
            style={{ marginTop: '20px', width: '100%' }}
            disabled={isFetching}
          >
            Eksportuj
          </Button>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </>
  )
}

ExportModal.propTypes = {
  title: PropTypes.string,
  classes: PropTypes.shape({
    content: PropTypes.string,
  }),
}
