import { commonConstants } from '_constants'

export const createBreadcrumbs = (dispatch, location, title, breadcrumbs) => {
  dispatch({
    type: commonConstants.ADD_CUSTOM_BREADCRUMBS,
    payload: {
      path: location.pathname,
      breadcrumbs: [
        ...breadcrumbs,
        {
          path: location.pathname,
          title,
        },
      ],
    },
  })
}
