import React, { useMemo } from 'react'
import { Paper } from 'components/Paper'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { operations } from 'components/table/_columns/operations'
import { translate } from '_helpers/translate'
import schema from '_schema/mobileAppDeleteAccountRequest'
import routes from './routes'
import { DatetimeCell } from '../../components/table/cells/DatetimeCell'
import { Title } from '../../components/Title'

export const Collection = () => {
  const columns = useMemo(
    () => [
      {
        header: translate('T_GENERAL_EMAIL'),
        accessor: `userEmail`,
        filterable: true,
        sortable: true,
        width: '60%',
      },
      {
        header: translate('T_GENERAL_CREATED_AT'),
        accessor: `createdAt`,
        filterable: true,
        sortable: true,
        width: '20%',
        Cell: DatetimeCell,
      },
      {
        ...operations(`email`, true, true, false, true, false),
        width: '10%',
      },
    ],
    []
  )

  return (
    <Paper>
      <Title>{translate('Usuń konto')}</Title>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${schema.endpoint}.get`}
        endpoint={schema.endpoint}
        definition={schema.resource.definition}
        columns={columns}
        customResourceSchema={schema.resource}
        orderable={false}
        isRowLinkable={true}
        editPath={routes().edit.path}
        autoWidth={false}
        numerable={false}
        defaultSorters={{ createdAt: 'desc' }}
        parameters={[
          {
            name: 'userEmail',
            in: 'query',
            type: 'string',
          },
        ]}
        schemaParameters={[
          {
            name: 'order[createdAt]',
            in: 'query',
            type: 'string',
          },
        ]}
      />
    </Paper>
  )
}
