const schema = {
  endpoint: '/api/e_library_book_requests',
  endpointBook: '/api/e_library_book_requests/parent',
  access: 'eLibraryBookRequest',
  resource: {
    definition: '#ELibraryBookRequest',
    properties: {
      customer: {
        type: 'resource',
        endpoint: `/api/customers?pagination=false`,
        titleAccessor: `email`,
        label: 'T_MODULE_ELIBRARY_REQUEST_CUSTOMER',
        disabled: true,
      },
      customerGroup: {
        type: 'resource',
        endpoint: `/api/customer_groups?pagination=false`,
        titleAccessor: `title`,
        label: 'T_MODULE_ELIBRARY_REQUEST_CUSTOMER_GROUP',
      },
      parent: {
        type: 'resource',
        endpoint: `/api/e_library_books?pagination=false`,
        titleAccessor: `title`,
        label: 'T_MODULE_ELIBRARY_REQUEST_BOOK_TITLE',
        validate: ['required'],
        disabled: true,
      },
      fileType: {
        type: 'resource',
        endpoint: `/api/e_library_book_file_types?pagination=false`,
        titleAccessor: `value`,
        label: 'T_MODULE_ELIBRARY_REQUEST_FILE_TYPE',
        transTitle: true,
        validate: ['required'],
      },
      /*
      branch: {
        type: 'resource',
        endpoint: `/api/branches?pagination=false&eLibrary=true`,
        titleAccessor: `translations.${LANGS_IRI.PL}.title`,
        label: 'T_MODULE_ELIBRARY_REQUEST_BRANCH',
        validate: ['required'],
      },*/
      branch: {
        type: 'resource',
        endpoint: `/api/e_library_locations?pagination=false`,
        titleAccessor: `trans`,
        label: 'T_MODULE_ELIBRARY_REQUEST_BRANCH',
        validate: ['required'],
        disabled: true,
      },
      files: {
        type: 'multipleResource',
        titleAccessor: `selectTitle`,
        label: 'T_MODULE_ELIBRARY_REQUEST_FILE_TITLE',
        endpointFunction: resource => {
          if (!resource.parent) {
            return null
          }
          return `/api/e_library_book_files?pagination=false&iri[parent]=${resource.parent}`
        },
      },
      note: {
        type: 'textarea',
        description: 'T_MODULE_ELIBRARY_REQUEST_NOTE',
      },
      timeFrom: {
        type: 'datetime',
        description: 'T_MODULE_ELIBRARY_REQUEST_TIME_FROM',
      },
      timeToPrimary: {
        type: 'datetime',
        description: 'T_MODULE_ELIBRARY_REQUEST_TIME_TO_PRIMARY',
      },
      timeTo: {
        type: 'datetime',
        description: 'T_MODULE_ELIBRARY_REQUEST_TIME_TO',
        hint: 'To pole należy modyfikować tylko w przypadku prolongaty',
      },
      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
  subresources: {
    prolongation: {
      endpoint: '/api/e_library_book_request_prolongations',
      definition: '#ELibraryBookRequestProlongation',
      properties: {
        timeTo: {
          type: 'datetime',
          description: 'T_MODULE_ELIBRARY_REQUEST_TIME_TO',
          validate: ['required'],
          hint:
            'W przypadku modyfikacji daty, należy również zmodyfikować datę w głównym zgłoszeniu',
        },
      },
    },
  },
}

export default schema
