import _block from './_block'
import photoSchema from './photo'
import mediaSchema from './media'

const schema = {
  endpoint: '/api/annual_section_articles',
  access: 'annualSectionArticle',
  resource: {
    definition: '#AnnualSectionArticle',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
          description: {
            description: 'Opis',
            label: 'Description',
            type: 'tinymce',
          },
          author: {
            description: 'T_GENERAL_AUTHOR',
          },
          metaTitle: {},
          metaDescription: {
            type: 'textarea',
          },
          metaKeywords: {
            type: 'textarea',
          },
          alt: {
            description: 'T_GENERAL_ALT',
          },
          slug: {},
        },
      },
      media: {
        type: 'image',
        description: 'T_GENERAL_PHOTO',
        endpoint: photoSchema.endpoint.single,
        validate: ['maxSize'],
        thumbs: {
          endpoint: '/api/annual_section_article_thumbs',
        },
      },
      file: {
        type: 'file',
        description: 'Plik pdf',
        endpoint: photoSchema.endpoint.single,
        validate: ['maxSize'],
      },
      linkFacebook: {
        description: 'T_LINKS_FACEBOOK',
      },
      linkYoutube: {
        description: 'T_LINKS_YOUTUBE',
      },
      linkInstagram: {
        description: 'T_LINKS_INSTAGRAM',
      },
      onlyPdf: {
        description: 'Tylko plik pdf',
        hint:
          'Przekierowuje bezpośrednio do pliku PDF, zamiast do strony artykułu',
      },
      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
  subresources: {
    block: {
      endpoint: '/api/annual_section_article_blocks',
      definition: '#AnnualSectionArticleBlock',
      types: _block(
        mediaSchema.endpoint,
        '/api/annual_section_article_block_thumbs',
        '/api/annual_section_article_block_multi_sections',
        '/api/annual_section_article_block_multi_section_thumbs',
        '/api/annual_section_article_block_branches',
        '/api/annual_section_article_block_didascalias',
        '/api/annual_section_article_block_contacts'
      ).types,
    },
  },
}

export default schema
