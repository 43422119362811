import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import { LANGS_IRI } from '../../_lib/langs'

export const multiSection = (
  photoEndpoint,
  blockMultiSectionEndpoint,
  blockMultiSectionThumbEndpoint
) => ({
  name: BLOCK_TYPES_IRI.MULTI_SECTION,
  label: 'T_GENERAL_BLOCK_TYPE_MULTI_SECTION',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {},
        /*
        multiSections: {
          type: 'customBlockMultiSection',
          iriKey: 'media',
          btnText: 'T_GENERAL_BLOCK_ADD_MULTI_SECTION',
          endpoint: '/api/media',
          endpointThumbs: '/api/page_block_translation_multi_section_thumbs',
        },
         */
      },
    },
    multiSections: {
      type: 'collection',
      label: 'T_GENERAL_BLOCK_TYPE_MULTI_SECTION_SECTIONS',
      titleAccessor: `translations.${LANGS_IRI.PL}.title`,
      definitionRef: '#NewsBlockMultiSection',
      endpoint: blockMultiSectionEndpoint,
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {
              description: 'T_GENERAL_TITLE',
              label: 'T_GENERAL_TITLE',
              type: 'tinymce',
            },
            description: {
              description: 'Opis',
              label: 'Description',
              type: 'tinymce',
            },
            alt: {
              description: 'T_GENERAL_ALT',
              type: 'string',
            },
          },
        },
        media: {
          type: 'media[image]',
          description: 'T_GENERAL_MEDIA_PHOTO',
          endpoint: photoEndpoint,
          thumbs: blockMultiSectionThumbEndpoint && {
            endpoint: blockMultiSectionThumbEndpoint,
          },
          validate: ['maxSize'],
        },
      },
    },
  },
})
