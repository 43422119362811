import photoSchema from './photo'

const schema = {
  endpoint: '/api/e-library/config',
  access: 'eLibraryConfig',
  resource: {
    definition: '#ELibraryConfig',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          description: {
            type: 'tinymce',
            description: 'Opis',
          },
          rules: {
            type: 'tinymce',
            description: 'Regulamin - checkbox',
            hint: 'Tekst wyświetlany w dialogu wypożyczania książki',
          },
        },
      },
      notificationEmail: {
        description: 'Email do powiadomień o nowych zgłoszeniach i prolongacie',
      },
      file: {
        type: 'file',
        description: 'Regulamin',
        endpoint: photoSchema.endpoint.single,
        validate: ['maxSize'],
      },
      /*
      locations: {
        type: 'multipleResource',
        endpoint: `/api/e_library_locations?pagination=false`,
        titleAccessor: 'trans',
        label: 'Lokacje',
        transTitle: true,
      },*/
    },
  },
  subresources: {
    branches: {
      endpoint: '/api/e_library_config_branches',
      definition: '#ELibraryConfigBranch',
      properties: {
        notificationEmail: {
          type: 'string',
          description:
            'Email do powiadomień o nowych zgłoszeniach i prolongacie',
          fullWidth: true,
        },
        locations: {
          type: 'multipleResource',
          endpoint: `/api/e_library_locations?pagination=false`,
          titleAccessor: 'trans',
          label: 'Lokacje',
          transTitle: true,
        },
      },
    },
    ips: {
      endpoint: '/api/e_library_config_ips',
      definition: '#ELibraryConfigIp',
      properties: {
        ipAddress: {
          type: 'string',
          description: 'Adres IP lub CIDR',
          validate: ['required'],
        },
      },
    },
  },
}

export default schema
