import React, { useState } from 'react'
import schema from '_schema/branchSubdivision'
import routes from './routes'
import { Form } from '../Form'

export const SubdivisionEdit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  const [parentResource, setParentResource] = useState(null)

  const collectionPath = routes().subdivision.path.replace(
    ':id',
    parentResource?.parent.uuid
  )

  return (
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      customResourceSchema={schema.resource}
      iri={iri}
      method="PUT"
      url={iri}
      collectionPath={collectionPath}
      storeCollectionId={schema.endpoint}
      sidebar={true}
      width={600}
      setParentResource={setParentResource}
    ></Form>
  )
}
