import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import { LANGS_IRI } from '../../_lib/langs'

export const practicalInformation = () => ({
  name: BLOCK_TYPES_IRI.PRACTICAL_INFORMATION,
  label: 'T_GENERAL_BLOCK_TYPE_PRACTICAL_INFORMATION',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {},
      },
    },
    practicalInformation: {
      type: 'multipleResource',
      titleAccessor: `translations.${LANGS_IRI.PL}.title`,
      endpoint: '/api/practical_informations',
    },
  },
})
