import { BLOCK_TYPES_IRI } from '_lib/blockTypes'

export const textWithPhoto = (photoEndpoint, thumbEndpoint) => ({
  name: BLOCK_TYPES_IRI.TEXT_WITH_PHOTO,
  label: 'T_GENERAL_BLOCK_TYPE_TEXT_WITH_PHOTO',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {},
        text: {
          description: 'Tekst',
          type: 'tinymce',
        },
        link: {
          type: 'string',
          description: 'T_GENERAL_BLOCK_LINK',
        },
        alt: {
          description: 'Tag alt',
          type: 'string',
        },
      },
    },
    photoAuthor: {
      description: 'T_GENERAL_AUTHOR',
    },
    media: {
      type: 'image',
      description: 'T_GENERAL_PHOTO',
      endpoint: photoEndpoint,
      thumbs: thumbEndpoint && {
        endpoint: thumbEndpoint,
      },
      validate: ['maxSize'],
    },
    mediaThumbType: {
      type: 'resource',
      endpoint: `/api/thumb_types?pagination=false`,
      titleAccessor: `trans`,
      transTitle: true,
      label: 'T_GENERAL_MEDIA_PHOTO_TYPE',
      initialValue: { value: '/api/thumb_types/CROPPED', title: 'Kadr' },
      validate: ['required'],
    },
    file: {
      type: 'file',
      description: 'T_GENERAL_VIDEO',
      endpoint: photoEndpoint,
      validate: ['maxSize'],
    },
    codeYt: {
      description: 'T_GENERAL_CODE_YT',
    },
  },
})
