import React, { useMemo } from 'react'
import { Paper } from 'components/Paper'
import { CollectionHeader } from './components/CollectionHeader'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { operations } from 'components/table/_columns/operations'
import { translate } from '_helpers/translate'
import schema from '_schema/surveySubmission'
import routes from './routes'
import { DateCell } from '../../components/table/cells/DateCell'

export const Collection = () => {
  const columns = useMemo(
    () => [
      {
        accessor: 'title',
        width: '20%',
        filterable: true,
      },
      {
        accessor: 'author',
        width: '20%',
        filterable: true,
      },
      {
        accessor: 'year',
        width: '10%',
      },
      {
        accessor: 'locationString',
        width: '15%',
        filterName: 'location.translations.title',
        filterable: true,
      },
      {
        accessor: 'alternativeLocationString',
        width: '15%',
        filterName: 'alternativeLocation.translations.title',
        filterable: true,
      },
      {
        accessor: 'createdAt',
        width: '10%',
        Cell: DateCell,
        sortable: true,
      },
      { ...operations('title', true, true, false, true, false), width: '5%' },
    ],
    []
  )

  return (
    <Paper>
      <CollectionHeader
        buttonTitle={translate('T_MODULE_SURVEY_SUBMISSION_NEW_BUTTON')}
        hideButton={true}
        buttonPath={routes().new.path}
      >
        {translate('T_MODULE_SURVEY_SUBMISSION')}
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${schema.endpoint}.get`}
        endpoint={schema.endpoint}
        definition={schema.resource.definition}
        columns={columns}
        customResourceSchema={schema.resource}
        orderable={false}
        isRowLinkable={true}
        editPath={routes().edit.path}
        autoWidth={false}
        parameters={[
          {
            name: 'alternativeLocation.translations.title',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'location.translations.title',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'title',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'author',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'order[createdAt]',
            in: 'query',
            required: false,
            type: 'string',
          },
        ]}
        schemaParameters={[
          {
            name: 'order[createdAt]',
            in: 'query',
            type: 'string',
          },
        ]}
      />
    </Paper>
  )
}
