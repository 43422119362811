export const modifyPropertiesByPage = (properties, resource, schema) => {
  const modifiedProperties = properties

  switch (resource.idName) {
    // Modify by page here
    case 'home':
      delete modifiedProperties.events
      delete modifiedProperties.news
      delete modifiedProperties.stat
      delete modifiedProperties.canOpen
      delete modifiedProperties.mainMenu
      delete modifiedProperties.translations.properties.description
      delete modifiedProperties.translations.properties.link
      delete modifiedProperties.translations.properties.slug
      return modifiedProperties
    default:
      return properties
  }
}
