const schema = {
  endpoint: '/api/event_submissions',
  clone_endpoint: '/api/event_submissions/clone',
  access: 'eventSubmission',
  resource: {
    definition: '#EventSubmission',
    properties: {
      fullName: {
        disabled: true,
        description: 'Name and surname',
      },
      name: {
        description: 'T_GENERAL_FIRST_NAME',
      },
      surname: {
        description: 'T_GENERAL_SURNAME',
      },
      phone: {
        description: 'T_GENERAL_PHONE_NUMBER',
      },
      email: {},
      notes: {
        type: 'textarea',
        description: 'Dodatkowe informacje',
      },
      consent1: {
        description: 'Zgoda 1',
      },
      consent2: {
        description: 'Zgoda 2 (opcjonalna)',
      },
      consent3: {
        description: 'Zgoda 3',
      },
    },
  },
  subresources: {
    participants: {
      endpoint: '/api/event_submission_participants',
      definition: '#EventSubmissionParticipant',
      properties: {
        name: {
          description: 'T_GENERAL_FIRST_NAME',
        },
        surname: {
          description: 'T_GENERAL_SURNAME',
        },
        phone: {
          description: 'T_GENERAL_PHONE_NUMBER',
        },
        email: {},
      },
    },
  },
}

export default schema
