import React from 'react'
import { Form } from 'pages'
import schema from '_schema/eLibraryConfig'
import { translate } from '../../_helpers/translate'
import { EmbeddedCollection } from '../../components/embedded'

export const Edit = ({ schema: definitions, ...rest }) => {
  const iri = `${schema.endpoint}`

  const { match } = rest

  return (
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      customResourceSchema={schema.resource}
      iri={iri}
      method="PUT"
      url={iri}
      storeCollectionId={schema.endpoint}
      sidebar={false}
      width={600}
    >
      <div style={{ marginTop: 30, marginBottom: 30 }}>
        <EmbeddedCollection
          endpoint={`${schema.subresources.branches.endpoint}?pagination=false`}
          pid={match.params.id}
          parentIri={iri}
          properties={schema.subresources.branches.properties}
          definitionSchema={
            definitions[schema.subresources.branches.definition]
          }
          headerTitle={translate('Lokacje')}
          panelTitle={translate('Lokacje')}
          titleAccessor={`titles`}
          statable={false}
          key={iri}
          paginable={false}
          orderable={false}
        />
      </div>
      <div style={{ marginTop: 30, marginBottom: 30 }}>
        <EmbeddedCollection
          endpoint={`${schema.subresources.ips.endpoint}?pagination=false`}
          pid={match.params.id}
          parentIri={iri}
          properties={schema.subresources.ips.properties}
          definitionSchema={definitions[schema.subresources.ips.definition]}
          headerTitle={translate('Adresy ip czytelni')}
          panelTitle={translate('Adres ip')}
          titleAccessor={`ipAddress`}
          statable={true}
          key={iri}
          paginable={false}
          orderable={false}
        />
      </div>
      <div style={{ paddingTop: 30 }}></div>
    </Form>
  )
}
