import React, { useMemo } from 'react'
import { Paper } from 'components/Paper'
import { Title } from 'components/Title'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { standard } from 'components/table/_columns/standard'
import { DatetimeCell } from 'components/table/cells/DatetimeCell'
import { ModuleCell } from './table/cells/ModuleCell'
import { MethodCell } from './table/cells/MethodCell'
import { translate } from '_helpers/translate'
import schema from '_schema/registryEntry'
import routes from './routes'

export const Collection = () => {
  const columns = useMemo(
    () => [
      { ...standard('username'), width: '17%' },
      {
        accessor: 'requestUri',
        header: translate('T_GENERAL_MODULE'),
        filterable: false,
        sortable: false,
        width: '20%',
        Cell: ModuleCell,
      },
      {
        accessor: 'requestMethod',
        header: translate('T_GENERAL_OPERATION'),
        filterable: false,
        sortable: false,
        width: '11%',
        Cell: MethodCell,
      },
      {
        accessor: 'resourceUuid',
        header: translate('T_GENERAL_RECORD'),
        filterable: true,
        sortable: false,
        width: '21%',
      },
      { ...standard('ip'), width: '12%' },
      {
        ...standard('createdAt'),
        header: translate('T_GENERAL_OPERATION_DATE'),
        width: '12%',
        Cell: DatetimeCell,
      },
    ],
    []
  )

  return (
    <Paper>
      <Title>{translate(routes().index.title)}</Title>
      <SchemableCollection
        component={CollectionTable}
        definition={schema.resource.definition}
        endpoint={schema.endpoint}
        columns={columns}
        autoWidth={false}
        defaultSorters={{ createdAt: 'desc' }}
        parameters={[
          {
            name: 'username',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'resourceUuid',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'ip',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'order[username]',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'order[ip]',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'order[createdAt]',
            in: 'query',
            required: false,
            type: 'string',
          },
        ]}
      />
    </Paper>
  )
}
