import React from 'react'
import { Route } from 'react-router-dom'
import { SchemableComponent } from 'components/SchemableComponent'
import { New } from './New'
import { Edit } from './Edit'
import { Collection } from './Collection'
import { addRoutesConfig } from '_helpers/addRoutesConfig'
import schema from '_schema/branch'
import { ListAlt } from '@material-ui/icons'
import { Gallery } from './Gallery'
import { GalleryNew } from './GalleryNew'
import { GalleryEdit } from './GalleryEdit'
import {SubdivisionCollection} from "./SubdivisionCollection";
import {SubdivisionNew} from "./SubdivisionNew";
import {SubdivisionEdit} from "./SubdivisionEdit";

const basePath = '/branches'

const routes = () =>
  addRoutesConfig(
    {
      index: {
        subtitle: 'T_GENERAL_RECORD_LIST',
        type: Route,
        render: props => <Collection {...props} />,
        path: basePath,
        exact: true,
        icon: ListAlt,
      },
      new: {
        subtitle: 'T_GENERAL_RECORD_NEW',
        type: Route,
        render: props => (
          <SchemableComponent path="definitions" component={New} {...props} />
        ),
        path: `${basePath}/new`,
        exact: true,
      },
      edit: {
        subtitle: 'T_GENERAL_RECORD_EDIT',
        type: Route,
        render: props => (
          <SchemableComponent path="definitions" component={Edit} {...props} />
        ),
        path: `${basePath}/:id`,
        exact: true,
      },
      gallery: {
        subtitle: 'T_GENERAL_GALLERY',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={Gallery}
            {...props}
          />
        ),
        path: `${basePath}/:id/gallery`,
        exact: true,
      },
      galleryNew: {
        subtitle: 'T_GENERAL_RECORD_NEW',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={GalleryNew}
            {...props}
          />
        ),
        path: `${basePath}/gallery_items/new`,
        exact: true,
      },
      galleryEdit: {
        subtitle: 'T_GENERAL_RECORD_EDIT',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={GalleryEdit}
            {...props}
          />
        ),
        path: `${basePath}/gallery_items/:id`,
        exact: true,
      },
      subdivision: {
        subtitle: 'Agendy',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={SubdivisionCollection}
            {...props}
          />
        ),
        path: `${basePath}/:id/subdivision`,
        exact: true,
      },
      subdivisionNew: {
        subtitle: 'T_GENERAL_RECORD_NEW',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={SubdivisionNew}
            {...props}
          />
        ),
        path: `${basePath}/subdivision/new`,
        exact: true,
      },
      subdivisionEdit: {
        subtitle: 'T_GENERAL_RECORD_EDIT',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={SubdivisionEdit}
            {...props}
          />
        ),
        path: `${basePath}/subdivision/:id`,
        exact: true,
      },
    },
    {
      title: 'Branches list',
      resourceAccess: schema.access,
      indexPath: basePath,
    }
  )

export default routes
