import _block from './_block'
import photoSchema from './photo'
import mediaSchema from './media'
import {LANGS_IRI} from "../_lib/langs";

const schema = {
  endpoint: '/api/offers',
  clone_endpoint: '/api/offers/clone',
  access: 'offer',
  resource: {
    definition: '#Offer',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
          description: {
            description: 'Opis',
            label: 'Description',
            type: 'tinymce',
          },
          metaTitle: {},
          metaDescription: {
            type: 'textarea',
          },
          metaKeywords: {
            type: 'textarea',
          },
          alt: {
            description: 'T_GENERAL_ALT',
          },
          slug: {},
        },
      },
      catalogs: {
        type: 'multipleResource',
        titleAccessor: `translations.${LANGS_IRI.PL}.title`,
        endpoint: '/api/catalogs',
      },
      media: {
        type: 'image',
        description: 'T_GENERAL_PHOTO',
        endpoint: photoSchema.endpoint.single,
        validate: ['maxSize'],
        thumbs: {
          endpoint: '/api/offer_thumbs',
          property: 'media',
        },
      },
      mediaThumbType: {
        type: 'resource',
        endpoint: `/api/thumb_types?pagination=false`,
        titleAccessor: `trans`,
        transTitle: true,
        label: 'T_GENERAL_MEDIA_PHOTO_TYPE',
        initialValue: { value: '/api/thumb_types/CROPPED', title: 'Kadr' },
        validate: ['required'],
      },
      hideMediaInDetail: {
        description: 'T_GENERAL_MEDIA_HIDE_IN_DETAIL',
      },
      mediaDetail: {
        type: 'image',
        description: 'T_GENERAL_PHOTO_DETAIL',
        endpoint: photoSchema.endpoint.single,
        validate: ['maxSize'],
        thumbs: {
          endpoint: '/api/offer_thumbs',
          property: 'mediaDetail',
        },
      },
      showHomepage: {},
      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
  subresources: {
    block: {
      endpoint: '/api/offer_blocks',
      definition: '#OfferBlock',
      types: _block(
        mediaSchema.endpoint,
        '/api/offer_block_thumbs',
        '/api/offer_block_multi_sections',
        '/api/offer_block_multi_section_thumbs',
        '/api/offer_block_branches',
        '/api/offer_block_didascalias',
        '/api/offer_block_contacts'
      ).types,
    },
  },
}

export default schema
