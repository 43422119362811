import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import { LANGS_IRI } from '../../_lib/langs'

export const text = blockDidascaliaEndpoint => ({
  name: BLOCK_TYPES_IRI.TEXT,
  label: 'T_GENERAL_BLOCK_TYPE_TEXT',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {},
        text: {
          description: 'Tekst, przykład didaskaliów "[1]"',
          type: 'tinymce',
        },
      },
    },
    didascalias: {
      type: 'collection',
      label: 'T_GENERAL_BLOCK_TYPE_TEXT_DIDASCALIAS',
      titleAccessor: `translations.${LANGS_IRI.PL}.title`,
      definitionRef: '#NewsBlockDidascalia',
      endpoint: blockDidascaliaEndpoint,
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {},
            description: {
              type: 'textarea',
            },
          },
        },
      },
    },
  },
})
