import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
} from 'react'
import { v4 as uuid } from 'uuid'
import {
  AddCircleOutline,
  AttachFile,
  DeleteForeverOutlined,
  PhotoSizeSelectLargeOutlined,
} from '@material-ui/icons'
import {
  Button,
  FormHelperText,
  TextField,
  Tooltip,
  IconButton,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'
import { translate } from '_helpers/translate'
import { validate } from '_helpers/validate'
import clsx from 'clsx'

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    columnGap: 20,
  },
  btn: {
    marginLeft: 'auto',
  },
  list: {
    display: 'grid',
    rowGap: 30,
    paddingTop: '1.5em',
  },
  listReadOnly: {
    display: 'grid',
    rowGap: 10,
    paddingTop: '1.5em',
  },
  textFields: {
    display: 'grid',
    rowGap: 10,
  },

  image_preview: {
    maxWidth: 150,
    maxHeight: 300,
  },
})

const listReducer = (state, action) => {
  switch (action.type) {
    case 'REMOVE_ITEM':
      return {
        ...state,
        list: state.list.filter(item => item !== action.id),
      }
    case 'ADD_ITEM':
      return {
        ...state,
        list: [...state.list, { hash: uuid() }],
      }
    default:
      throw new Error()
  }
}

export const CustomBlockMultiSection = ({
  name,
  initialValue,
  value,
  endpoint = null,
  endpointThumbs,
  setValue,
  iriKey = null,
  btnText = null,
  validators = null,
  disabled = false,
  hint = null,
  accept = null,
  type = null,
  readOnly = false,
}) => {
  const [id] = useState(uuid())

  useEffect(() => {
    if (value === null && !initialValue) {
      setValue(name, [])
    }
  }, [setValue, name, value, initialValue])

  const TooltipComponent = disabled ? 'span' : Tooltip

  const classes = useStyles()
  const defaultClasses = useStyles()

  const [listData, dispatchListData] = useReducer(listReducer, {
    list: initialValue || [],
  })

  const handleRemove = (id, index) => {
    value &&
      setValue(
        name,
        value.filter((item, vindex) => vindex !== index)
      )
    dispatchListData({ type: 'REMOVE_ITEM', id })
  }

  const handleAdd = () => {
    dispatchListData({ type: 'ADD_ITEM' })
  }

  const handleToggle = type => () => {
    const fieldName = `is${type.charAt(0).toUpperCase()}${type.slice(
      1
    )}DialogOpen`

    setState(state => ({
      ...state,
      [fieldName]: !state[fieldName],
    }))
  }

  const [state, setState] = useState({
    value: [],
    error: false,
    isFetching: false,
  })

  const fileInputRef = useRef([])

  const handleInputClick = index => {
    fileInputRef.current[index].click()
  }

  const handleInputChange = (e, index) => {
    const value = e.target.files[0]
    setState(state => ({
      ...state,
      value: {
        ...state.value,
        [index]: value,
      },
    }))
    validateField(value)
  }

  const handleSend = index => {
    if (!state.value) {
      return
    }

    const formData = new FormData()
    formData.append('file', state.value[index])

    setState(state => ({
      ...state,
      isFetching: true,
    }))

    fetchDataHandleAuthError(
      endpoint,
      'POST',
      { body: formData },
      response => {
        const resource = response

        uploadSuccess(resource, index)
      },
      error => {
        uploadFailure(error)
      },
      { 'Content-Type': 'multipart/form-data' }
    )
  }

  const uploadSuccess = (resource, index) => {
    setState({
      value: {
        [index]: null,
      },
      error: false,
      isFetching: false,
    })

    setValue(
      name,
      Object.assign([], value, {
        [index]: {
          ...value[index],
          [iriKey]: resource['@id'],
          url: resource.url,
          originalName: resource.originalName,
        },
      })
    )

    notification('success', 'T_FORM_RECORD_CREATED', 'T_FORM_SUCCESS')
  }

  const uploadFailure = error => {
    if (error.response.title === 'AbortError') {
      return
    }

    setState(state => ({
      ...state,
      isFetching: false,
    }))

    notification('error', error.response.detail, error.response.title)
  }

  const validateField = useCallback(
    value => {
      if (!validators) {
        setState(state => ({
          ...state,
          error: false,
        }))

        return
      }

      const valid = validate(validators, value)

      setState(state => ({
        ...state,
        error: !valid.result && valid.message,
      }))
    },
    [validators]
  )

  const handleChange = (itemValue, index, itemName) => {
    setValue(
      name,
      Object.assign([], value, {
        [index]: {
          ...value[index],
          [itemName]: itemValue,
        },
      })
    )
    validateField(value)
  }
  return (
    <div className={clsx(classes.list, readOnly && classes.listReadOnly)}>
      {listData.list.map((item, index) => (
        <div key={item.hash} className={classes.wrapper}>
          {!readOnly && (
            <>
              {value && (
                <div className={classes.textFields}>
                  <TextField
                    name={'title'}
                    onChange={event =>
                      handleChange(event.target.value, index, 'title')
                    }
                    value={value[index]?.title}
                    variant="outlined"
                    disabled={disabled}
                    size="small"
                    label={'title'}
                  />
                  {// TODO TINYMCE
                   }
                  <TextField
                    name={'description'}
                    onChange={event =>
                      handleChange(event.target.value, index, 'description')
                    }
                    value={value[index]?.description}
                    variant="outlined"
                    disabled={disabled}
                    size="small"
                    label={'description'}
                  />
                  <TextField
                    name={'alt'}
                    onChange={event =>
                      handleChange(event.target.value, index, 'alt')
                    }
                    value={value[index]?.alt}
                    variant="outlined"
                    disabled={disabled}
                    size="small"
                    label={'alt'}
                  />
                </div>
              )}

              <Button
                startIcon={<AttachFile />}
                onClick={() => handleInputClick(index)}
                disabled={state.isFetching || disabled}
                size="small"
                variant="outlined"
                color="secondary"
              >
                {translate('T_GENERAL_BLOCK_SECTION_ATTACH_FILE')}
              </Button>
              <FormHelperText error={!!state.error} disabled={disabled}>
                {translate(state.error || hint)}
              </FormHelperText>
              <input
                id={`${id}-${item.hash}`}
                type="file"
                name="file"
                ref={element => {
                  fileInputRef.current[index] = element
                }}
                accept={
                  accept ||
                  (type === 'image'
                    ? 'image/jpg,image/jpeg,image/png,image/webp'
                    : '*')
                }
                style={{ display: 'none' }}
                onChange={e => handleInputChange(e, index)}
              />
              <Button
                color="primary"
                disabled={
                  !state.value?.[index] ||
                  !!state.error ||
                  state.isFetching ||
                  disabled
                }
                onClick={() => handleSend(index)}
                variant="contained"
                type="button"
                size="small"
              >
                {translate('T_GENERAL_SEND')}
              </Button>
            </>
          )}
          {(value?.[index]?.url || value?.[index]?.media?.url) && (
            <img
              className={classes.image_preview}
              src={`${process.env.REACT_APP_BACKEND_ENTRYPOINT}${
                value[index]?.url ? value[index]?.url : value[index]?.media?.url
              }`}
              alt={
                value[index]?.originalName
                  ? value[index]?.originalName
                  : value[index]?.media?.originalName
              }
            />
          )}
          {!readOnly && (
            <Button
              variant="contained"
              color="secondary"
              startIcon={<DeleteForeverOutlined />}
              onClick={() => handleRemove(item, index)}
              size="small"
              className={classes.btn}
              disabled={disabled}
            >
              {translate('T_GENERAL_DELETE')}
            </Button>
          )}
          <TooltipComponent title={translate('T_GENERAL_PHOTO_THUMBS')}>
            <IconButton
              color="primary"
              onClick={handleToggle('thumbs')}
              disabled={disabled}
            >
              <PhotoSizeSelectLargeOutlined
                className={clsx(
                  defaultClasses.labelButton,
                  classes.labelButton
                )}
              />
            </IconButton>
          </TooltipComponent>

          {value[index] !== undefined &&
            value[index]['@id'] &&
            console.log('TODO')
          /*
            <ThumbCollection
              endpoint={endpointThumbs}
              parentIri={value[index]['@id']}
              pid={uuid}
              originalPhotoUrl={value && value.url}
              disabled={disabled}
              twoColLayout={false}
              //cropperNodeRef={value}
              align="left"
              classes={{ root: clsx(defaultClasses.thumbs, classes.thumbs) }}
              key={JSON.stringify(value)}
            />
          <ThumbsDialog
            endpoint={endpointThumbs}
            pid={uuid}
            originalPhotoUrl={value && value.url}
            disabled={disabled}
            isOpen={state.isThumbsDialogOpen}
            handleToggle={handleToggle('thumbs')}
          />
             */
          }
        </div>
      ))}
      {!readOnly && (
        <Button
          variant="outlined"
          color="primary"
          startIcon={<AddCircleOutline />}
          onClick={() => handleAdd()}
          size="small"
          className={classes.btn}
          disabled={disabled}
        >
          {translate(btnText)}
        </Button>
      )}
    </div>
  )
}
