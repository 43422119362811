import { authConstants, schemaConstants } from '_constants'

export const schemaReducer = (state = null, action) => {
  switch (action.type) {
    case schemaConstants.SCHEMA_SUCCESS:
      return {
        ...action.payload.schema,
        definitions: Object.assign(
          {},
          ...action.payload.schema['hydra:supportedClass'].map(item => ({
            [item['@id']]: {
              ...item,
              properties: Object.assign(
                {},
                ...item['hydra:supportedProperty'].map(property => {
                  /*
                  const type = property['hydra:property']?.range?.replace(
                    'xmls:',
                    ''
                  )
                   */

                  const range = property['hydra:property']?.range
                  let type
                  if (Array.isArray(range)) {
                    // todo array handling
                    type = range[0]?.replace('xmls:', '')
                  } else {
                    type = range?.replace('xmls:', '')
                  }

                  return {
                    [property['hydra:title']]: {
                      ...property,
                      type,
                      description: property['hydra:description'],
                      ...(type?.startsWith('#') ? { $ref: type } : {}),
                    },
                  }
                })
              ),
            },
          }))
        ),
      }
    case schemaConstants.SCHEMA_FAILURE:
    case authConstants.REFRESH_FAILURE:
    case authConstants.LOGOUT:
      return null
    default:
      return state
  }
}
