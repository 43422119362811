import photoSchema from './photo'

const schema = {
  endpoint: '/api/home_banners',
  access: 'homeBanner',
  resource: {
    definition: '#HomeBanner',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
          alt: {
            description: 'T_GENERAL_ALT',
          },
        },
      },
      media: {
        type: 'image',
        description: 'T_GENERAL_MEDIA_PHOTO',
        endpoint: photoSchema.endpoint.single,
        endpointMultiple: photoSchema.endpoint.multiple,
        thumbs: {
          endpoint: '/api/home_banner_thumbs',
        },
        validate: ['maxSize'],
      },
      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
}

export default schema
