import { LANGS_IRI } from '../_lib/langs'
import branchSchema from './branch'

const schema = {
  endpoint: '/api/mobile_app_branch_subscriptions',
  access: 'mobileAppBranchSubscription',
  resource: {
    definition: '#MobileAppBranchSubscription',
    properties: {
      branches: {
        type: 'multipleResource',
        endpoint: `${branchSchema.endpoint}?pagination=false`,
        titleAccessor: `translations.${LANGS_IRI.PL}.title`,
        label: 'Filie',
      },
      userEmail: {
        description: 'T_GENERAL_EMAIL',
        hint: 'Email użytkownika (SOWA)',
        disabled: true,
      },
      userUuid: {
        description: 'Identyfikator użytkownika (SOWA)',
        disabled: true,
      },
    },
  },
}

export default schema
