import { BLOCK_TYPES_IRI } from '_lib/blockTypes'

export const issuu = () => ({
  name: BLOCK_TYPES_IRI.ISSUU,
  label: 'T_GENERAL_BLOCK_TYPE_ISSUU',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {},
        embedCode: {
          type: 'textarea',
          description: 'T_GENERAL_BLOCK_ISSUU_EMBED_CODE',
        },
      },
    },
    // TODO WHAT THIS BLOCK IS SUPPOSED TO DO
  },
})
