import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import { LANGS_IRI } from '../../_lib/langs'

export const branches = blockBranchesEndpoint => ({
  name: BLOCK_TYPES_IRI.BRANCHES,
  label: 'T_GENERAL_BLOCK_TYPE_BRANCHES',
  properties: {
    translations: {
      type: 'translation',
      properties: {
        title: {},
        text: {
          description: 'Tekst',
          type: 'tinymce',
        },
        /*
        branches: {
          type: 'customBlockBranches',
          btnText: 'T_GENERAL_BLOCK_ADD_BRANCH',
          name: 'branches',
          endpoint: '/api/branches?pagination=false',
        },
         */
      },
    },
    branches: {
      //type: 'embeddedCollection',
      type: 'collection',
      label: 'T_GENERAL_BLOCK_TYPE_MULTI_BRANCHES_BRANCHES',
      titleAccessor: `translations.${LANGS_IRI.PL}.description`,
      definitionRef: '#NewsBlockBranch',
      endpoint: blockBranchesEndpoint,
      properties: {
        translations: {
          type: 'translation',
          properties: {
            description: {},
          },
        },
        branch: {
          type: 'resource',
          endpoint: `/api/branches?pagination=false`,
          titleAccessor: `translations.${LANGS_IRI.PL}.title`,
          label: 'T_MODULE_EVENT_BRANCH_SELECT',
        },
      },
    },
    /*
    subresources: {
      item: {
        endpoint: '/api/page_block_translation_branches',
        definition: '#PageBlockTranslationBranch',
        properties: {

        },
      },
    },

     */
  },
})
