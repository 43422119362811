import {
  PeopleOutlined,
  DescriptionOutlined,
  SettingsOutlined,
  AssignmentTurnedInSharp,
  ViewListSharp,
  HomeWork,
  Event,
  Ballot,
  Dashboard,
  LocalLibraryTwoTone,
  PhoneAndroid,
} from '@material-ui/icons'
import home from 'pages/Home/routes'
import admins from 'pages/Admins/routes'
import adminGroups from 'pages/AdminGroups/routes'
import loginEntries from 'pages/LoginEntries/routes'
import registryEntries from 'pages/RegistryEntries/routes'
import settings from 'pages/Settings/routes'
import profile from 'pages/Profile/routes'
import pages from 'pages/Pages/routes'
import translatorEntries from 'pages/TranslatorEntries/routes'
import packageGalleries from 'pages/PackageGalleries/routes'
import packageFiles from 'pages/PackageFiles/routes'
import packageFaqs from 'pages/PackageFaqs/routes'
import packageLogotypes from 'pages/PackageLogotypes/routes'
import branches from 'pages/Branches/routes'
import branchCategory from 'pages/BranchCategory/routes'
import branchAmenity from 'pages/BranchAmenity/routes'
import branchAccessibility from 'pages/BranchAccessibility/routes'
import branchAdditionalOffer from 'pages/BranchAdditionalOffer/routes'
import branchAward from 'pages/BranchAward/routes'
import practicalInformation from 'pages/PracticalInformations/routes'
import news from 'pages/News/routes'
import newsCategory from 'pages/NewsCategory/routes'
import event from 'pages/Event/routes'
import eventCategory from 'pages/EventCategory/routes'
import eventRecipient from 'pages/EventRecipient/routes'
import eventLocation from 'pages/EventLocation/routes'
import catalog from 'pages/Catalogs/routes'
import offer from 'pages/Offers/routes'
import survey from 'pages/Surveys/routes'
import surveySubmission from 'pages/SurveySubmissions/routes'
import config from 'pages/Config/routes'
import contactPeople from 'pages/ContactPeople/routes'
import club from 'pages/Clubs/routes'
import project from 'pages/Project/routes'
import annual from 'pages/Annual/routes'
import contest from 'pages/Contest/routes'
import homeBanner from 'pages/HomeBanner/routes'
import customer from 'pages/Customer/routes'
import customerGroup from 'pages/CustomerGroup/routes'
import eLibraryBook from 'pages/ELibrary/Book/routes'
import eLibraryBookRequest from 'pages/ELibrary/Request/routes'
import eLibraryReport from 'pages/ELibrary/Report/routes'
import eLibraryConfig from 'pages/ELibraryConfig/routes'
import generalArticle from 'pages/GeneralArticle/routes'
import tender from 'pages/Tender/routes'
import mobileAppContactForm from 'pages/MobileAppContactForm/routes'
import mobileAppDeleteAccountRequest from 'pages/MobileAppDeleteAccountRequest/routes'
import mobileAppBranchSubscription from 'pages/MobileAppBranchSubscription/routes'
import mobileAppNotification from 'pages/MobileAppNotification/routes'

const menu = () => [
  home().index,
  pages().index,
  {
    title: 'T_MENU_PACKAGES',
    icon: ViewListSharp,
    items: [
      packageGalleries().index,
      packageFiles().index,
      packageFaqs().index,
      packageLogotypes().index,
    ],
  },
  {
    title: 'T_MENU_PORTAL',
    icon: Dashboard,
    items: [
      homeBanner().index,
      practicalInformation().index,
      catalog().index,
      offer().index,
      contactPeople().index,
      club().index,
      project().index,
      annual().index,
      contest().index,
      generalArticle().index,
      tender().index,
    ],
  },
  {
    title: 'T_MENU_BRANCHES',
    icon: HomeWork,
    items: [
      branches().index,
      branchCategory().index,
      branchAmenity().index,
      branchAccessibility().index,
      branchAdditionalOffer().index,
      branchAward().index,
    ],
  },
  {
    title: 'T_MENU_EVENTS',
    icon: Event,
    items: [
      event().index,
      eventCategory().index,
      eventRecipient().index,
      eventLocation().index,
    ],
  },
  {
    title: 'T_MENU_SUGGEST_PURCHASE',
    icon: Ballot,
    items: [survey().index, surveySubmission().index],
  },
  {
    title: 'T_MENU_NEWS',
    icon: AssignmentTurnedInSharp,
    items: [news().index, newsCategory().index],
  },
  {
    title: 'T_MENU_ELIBRARY',
    icon: LocalLibraryTwoTone,
    items: [
      customer().index,
      customerGroup().index,
      eLibraryBook().index,
      eLibraryBookRequest().index,
      eLibraryReport().index,
      eLibraryConfig().edit,
    ],
  },
  {
    title: 'T_MENU_MOBILE_APP',
    icon: PhoneAndroid,
    items: [
      mobileAppContactForm().index,
      mobileAppDeleteAccountRequest().index,
      mobileAppBranchSubscription().index,
      mobileAppNotification().index,
    ],
  },
  {
    title: 'Administrators',
    icon: PeopleOutlined,
    items: [admins().index, adminGroups().index],
  },
  {
    title: 'T_MENU_REGISTRIES',
    icon: DescriptionOutlined,
    items: [registryEntries().index, loginEntries().index],
  },
  {
    title: 'T_MODULE_SETTINGS',
    icon: SettingsOutlined,
    items: [config().edit, settings().index, profile().index],
  },
  translatorEntries().index,
]

export default menu
