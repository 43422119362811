import photoSchema from './photo'

const schema = {
  endpoint: '/api/branch_gallery_items',
  resource: {
    definition: '#BranchGalleryItem',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
          author: {},
          description: {
            type: 'textarea',
          },
          alt: {
            description: 'T_GENERAL_ALT',
          },
        },
      },
      media: {
        type: 'image',
        description: 'T_GENERAL_MEDIA_PHOTO',
        endpoint: photoSchema.endpoint.single,
        endpointMultiple: photoSchema.endpoint.multiple,
        thumbs: {
          endpoint: '/api/branch_gallery_item_thumbs',
        },
        validate: ['maxSize'],
      },
      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
}

export default schema
