import React, { useMemo, useState } from 'react'
import { NotFound } from 'pages'
import { Paper } from 'components/Paper'
import { Loader } from 'components/Loader'
import { CollectionHeader } from 'components/CollectionHeader'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { useResourceState } from '_helpers/useResourceState'
import { useResourceFetch } from '_helpers/useResourceFetch'
import { translate } from '_helpers/translate'
import { publish } from 'components/table/_mass'
import { AnnualMainCell } from './table/cells/AnnualMainCell'
import schema from '_schema/contest'
import routes from './routes'
import itemSchema from '_schema/contestPage'
import itemRoutes from 'pages/Contest/routes'
import { LANGS_IRI } from '../../_lib/langs'
import { EditHeaderWithCustomTitle } from '../../components/EditHeaderWithCustomTitle'
import { operations } from '../../components/table/_columns/operations'

export const PageCollection = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const parentIri = useMemo(() => `${schema.endpoint}/${match.params.id}`, [
    match.params.id,
  ])

  const [state, setState] = useResourceState()

  const { resource, isFetching, fetchError } = state

  const [itemCount, setItemCount] = useState(0)

  const [hideButton, setHideButton] = useState(false)

  const updateItemCount = newValue => {
    setItemCount(newValue)
    if (itemCount < 8) {
      setHideButton(false)
    } else {
      setHideButton(true)
    }
  }

  useResourceFetch(
    parentIri,
    resource,
    setState.isFetching,
    setState.resource,
    setState.fetchError,
    false
  )

  const itemColumns = useMemo(
    () => [
      {
        header: translate('T_GENERAL_TITLE'),
        accessor: `translations.${LANGS_IRI.PL}.title`,
        filterName: 'translations.title',
        filterable: true,
        Cell: AnnualMainCell,
        width: '70%',
      },
      {
        ...operations(`translations.${LANGS_IRI.PL}.title`),
        width: '10%',
      },
    ],
    []
  )

  return isFetching ? (
    <Paper>
      <Loader />
    </Paper>
  ) : fetchError ? (
    <NotFound />
  ) : (
    <Paper>
      <CollectionHeader
        buttonTitle={translate('T_MODULE_CONTEST_PAGE_NEW_BUTTON')}
        buttonPath={`${itemRoutes().pageNew.path}?pid=${match.params.id}`}
        hideButton={hideButton}
      >
        <EditHeaderWithCustomTitle
          resource={resource}
          editPath={routes().edit.path}
          textBeforeTitle={`Strony konkursu: ${
            resource.translations?.[
              LANGS_IRI[process.env.REACT_APP_LOCALE.toUpperCase()]
            ]?.title
          }`}
        />
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${itemSchema.endpoint}.get`}
        endpoint={itemSchema.endpoint}
        definition={itemSchema.resource.definition}
        storeCollectionId={`${itemSchema.endpoint}?iri[parent]=${parentIri}`}
        defaultFilters={{
          'iri[parent]': parentIri,
        }}
        parameters={[
          {
            name: 'iri[parent]',
            in: 'query',
            type: 'string',
            value: false,
          },
        ]}
        columns={itemColumns}
        customResourceSchema={itemSchema.resource}
        defaultSorters={{ ord: 'desc' }}
        orderable={true}
        selectable={[publish.on(), publish.off()]}
        isRowLinkable={true}
        editPath={itemRoutes().pageEdit.path}
        autoWidth={false}
        setItemCount={updateItemCount}
      />
    </Paper>
  )
}
