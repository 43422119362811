import _block from './_block'
import photoSchema from './photo'
import mediaSchema from './media'
import newsCategorySchema from './newsCategory'
import eventRecipientSchema from './eventRecipients'
import { LANGS_IRI } from '../_lib/langs'

const schema = {
  endpoint: '/api/contests',
  clone_endpoint: '/api/contests/clone',
  access: 'contest',
  resource: {
    definition: '#Contest',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
          shortDescription: {
            type: 'textarea',
            description: 'T_GENERAL_DESCRIPTION_TILE',
          },
          description: {
            description: 'Opis',
            label: 'Description',
            type: 'tinymce',
          },
          prizeTitle: {
            description: 'T_MODULE_CONTEST_PRIZE_TITLE',
          },
          prizeDescription: {
            type: 'tinymce',
            description: 'T_MODULE_CONTEST_PRIZE_DESCRIPTION',
          },
          link: {
            description: 'T_GENERAL_EXTERNAL_LINK',
          },
          metaTitle: {},
          metaDescription: {
            type: 'textarea',
          },
          metaKeywords: {
            type: 'textarea',
          },
          alt: {
            description: 'T_GENERAL_ALT',
          },
          slug: {},
        },
      },
      media: {
        type: 'image',
        description: 'T_GENERAL_PHOTO',
        endpoint: photoSchema.endpoint.single,
        validate: ['maxSize'],
        thumbs: {
          endpoint: '/api/contest_thumbs',
          property: 'media',
        },
      },
      mediaThumbType: {
        type: 'resource',
        endpoint: `/api/thumb_types?pagination=false`,
        titleAccessor: `trans`,
        transTitle: true,
        label: 'T_GENERAL_MEDIA_PHOTO_TYPE',
        initialValue: { value: '/api/thumb_types/CROPPED', title: 'Kadr' },
        validate: ['required'],
      },
      hideMediaInDetail: {
        description: 'T_GENERAL_MEDIA_HIDE_IN_DETAIL',
      },
      mediaDetail: {
        type: 'image',
        description: 'T_GENERAL_PHOTO_DETAIL',
        endpoint: photoSchema.endpoint.single,
        validate: ['maxSize'],
        thumbs: {
          endpoint: '/api/contest_thumbs',
          property: 'mediaDetail',
        },
      },
      linkFacebook: {
        description: 'T_LINKS_FACEBOOK',
      },
      linkYoutube: {
        description: 'T_LINKS_YOUTUBE',
      },
      linkInstagram: {
        description: 'T_LINKS_INSTAGRAM',
      },
      events: {
        type: 'multipleResource',
        endpoint: `/api/events_select?pagination=false`,
        titleAccessor: `nativeTitle`,
        label: 'T_GENERAL_LINKED_EVENTS',
      },
      linkedNewsCategory: {
        type: 'resource',
        endpoint: `${newsCategorySchema.endpoint}?pagination=false`,
        titleAccessor: `translations.${LANGS_IRI.PL}.title`,
      },
      recipients: {
        type: 'multipleResource',
        titleAccessor: `translations.${LANGS_IRI.PL}.title`,
        endpoint: `${eventRecipientSchema.endpoint}?pagination=false`,
      },
      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
  subresources: {
    block: {
      endpoint: '/api/contest_blocks',
      definition: '#ContestBlock',
      types: _block(
        mediaSchema.endpoint,
        '/api/contest_block_thumbs',
        '/api/contest_block_multi_sections',
        '/api/contest_block_multi_section_thumbs',
        '/api/contest_block_branches',
        '/api/contest_block_didascalias',
        '/api/contest_block_contacts'
      ).types,
    },
  },
}

export default schema
