import branchSchema from './branch'
import { LANGS_IRI } from '../_lib/langs'

const schema = {
  endpoint: '/api/mobile_app_notifications',
  access: 'mobileAppNotification',
  resource: {
    definition: '#MobileAppNotification',
    properties: {
      content: {
        type: 'tinymce',
        label: 'Treść powiadomienia',
        description: 'Treść powiadomienia',
        validate: ['required'],
      },
      branches: {
        type: 'multipleResource',
        endpoint: `${branchSchema.endpoint}?pagination=false`,
        titleAccessor: `translations.${LANGS_IRI.PL}.title`,
        label: 'Filie',
      },
      global: {
        description: 'Globalne powiadomienie',
        hint: 'Powiadomienie dla wszystkich filii',
      },
      /*
      userEmail: {
        description: 'T_GENERAL_EMAIL',
        hint: 'Email użytkownika (SOWA)',
        disabled: true,
        validate: ['email'],
      },
       */
    },
  },
}

export default schema
