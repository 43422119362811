import React from 'react'
import { ResourceMediaEdit } from 'pages/ResourceMediaEdit'
import schema from '_schema/branchGalleryItem'
import routes from './routes'
import parentRoutes from 'pages/Branches/routes'

export const GalleryEdit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  return (
    <ResourceMediaEdit
      uuid={match.params.id}
      definitions={definitions}
      schema={schema}
      editPath={routes().galleryEdit.path}
      parentEditPath={parentRoutes().edit.path}
      parentShowPath={parentRoutes().gallery.path}
      formWidth={750}
      key={match.params.id}
      showCropperContainer={false}
    />
  )
}
